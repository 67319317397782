<template>
    <v-card height="100%">
        <v-card-title>
        {{$t(title)}}
        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
        ></v-progress-linear>
        </v-card-title>
        <donut-chart-component :data="results" :svgclass="svgClass" v-if="chartType === 'donut'"/>     
        <column-chart-component :data="results" :svgclass="svgClass" :labelY="labelY" v-if="chartType === 'column'"/>
        <table-component :result-set="resultset" v-if="chartType==='table'" :maxRows="maxRows" :sumOthers="sumOthers" />
    </v-card>
</template>

<script>
    import Vue from 'vue';
    import cubejs from "@cubejs-client/core";
    import { QueryRenderer } from "@cubejs-client/vue";
    import * as Sentry from "@sentry/browser";
import TableComponent from './Table.component.vue';
    //import moment from 'moment';
    var moment = require('moment');
    export default {
        props: ['config', 'parameters'],
        data () {
            return {
                svgClass: "usage-chart-container",
                title: null,
                cubejsApi: null,
                query: null,
                /* {
                    "measures": [
                        "EMSU.count"
                    ],
                    "timeDimensions": [
                        {
                        "dimension": "EMSU.timestamp",
                        "granularity": "month",
                        "dateRange": "Last year"
                        }
                    ],
                    "order": {},
                    "dimensions": []
                },
                */
                chartType: null,
                labelY: "",
                maxRows: null,
                sumOthers: null,
                percentages: null,
                results: [],
                resultset: null,
                loading: false                     
            }
        },
        watch: { 
            config: function(newVal, oldVal) { 
                this.setConfig(newVal);
                this.onRefreshed(newVal);
            },
            parameters: function(newVal, oldVal) { 
                this.onRefreshed(this.config);
            },
        },
         methods: {                 
            onRefreshed(config) {
                var self = this;
                if (config && config.query) {
                    self.loadCubeToken(config.appId)
                    .then(function(data) {
                        if (data) {
                            var token = data.cube_token;
                            self.cubejsApi = cubejs(token, {
                                apiUrl: self.$configuration.CubeApiUrl
                            });    
                            var query = self.config.query;
                            if (self.parameters) {
                                var queryStr = JSON.stringify(query);                                
                                Object.keys(self.parameters).forEach((key) => {
                                    queryStr = queryStr.replace('~' + key, self.parameters[key]);
                                });
                                query = JSON.parse(queryStr);
                            }
                            self.cubejsApi.load(query)
                            .then(function(results) {
                                self.resultset = results;
                                self.results = self.getDataSet(results)
                            })
                            .catch(function(e) {
                                console.log(e);
                                Sentry.captureException(err);
                            });               
                        }
                        

                    })
                    .catch(function(e) {
                        console.log(e);
                        Sentry.captureException(err);
                    })
                }

                

            },
            loadCubeToken(appId) {
                var self = this;
                self.loading = true;
                return new Promise((resolve, reject) => {
                    var url=discovererApiRoot	 + 'CUBE/token?appId=' + appId ;              
                    axios.get(url, {headers: {
                        "Content-Type":'application/json',
                        'Accept':'application/json'                        
                    }})
                    .then(function(graph) {
                        self.loading = false;
                        resolve(graph.data);                       
                    } , function(err) {
                        self.loading = false;
                        reject(err);
                    });    
                
                });               
            } ,   
            loadCubeData(token ) {
                var self = this;
                self.loading = true;
                return new Promise((resolve, reject) => {
                    const cubejsApi = cubejs(token, {
                        apiUrl: this.$configuration.CubeApiUrl
                    });
                    var url=discovererApiRoot	 + 'CUBE/token' ;              
                    axios.get(url, {headers: {
                        "Content-Type":'application/json',
                        'Accept':'application/json'                        
                    }})
                    .then(function(graph) {
                        self.loading = false;
                        resolve(graph.data);                       
                    } , function(err) {
                        self.loading = false;
                        reject(err);
                    });    
                
                })                   
            },
            getDataSet(resultSet) {
                var self = this;
                if (['line', 'area'].includes(this.chartType)) {
                    return this.series(resultSet);
                }

                if (this.chartType === 'donut') {
                    //return this.pairs(resultSet);
                    var seriesPairs = this.seriesPairs(resultSet);
                    if (seriesPairs.length > 0) {
                        var retItems = [];
                        var totalSum = 0;
                        if (self.percentages) {
                            for (var i=0; i < seriesPairs[0].data.length; i++) {
                                totalSum += seriesPairs[0].data[i].value
                            }
                        }
                        if (self.maxRows && seriesPairs[0].data.length > self.maxRows) {
                            var topItems = seriesPairs[0].data.slice(0,self.maxRows);
                            if (self.sumOthers) {
                                var restItems = seriesPairs[0].data.slice(self.maxRows);
                                var restSum = 0;
                                for (var i=0; i < restItems.length; i++) {
                                    restSum += restItems[i].value;
                                }
                                topItems.push({
                                    name : Vue.i18n.translate("OTHERS"),
                                    value: restSum
                                });
                            }
                            retItems = topItems;

                        } else {
                            retItems = seriesPairs[0].data;
                        }              
                        if (self.percentages && totalSum) {
                            for (var i=0; i < retItems.length; i++) {
                                retItems[i].percent =  retItems[i].value / totalSum * 100;
                                retItems[i].label = `${retItems[i].name} (${Math.round(retItems[i].percent * 100) / 100}%)`;
                            }
                        }
                        return retItems
                    } else  {
                        return [];
                    }
                }

                if (this.chartType === 'column') {
                    var seriesPairs = this.seriesPairs(resultSet);
                    if (seriesPairs.length > 0) {
                        return seriesPairs[0].data;
                    } else  {
                        return [];
                    }

                }
                if (this.chartType === 'table') {
                    return resultSet;
                }
            },
            series(resultSet) {
                if (!resultSet) {
                    return [];
                }
                const seriesNames = resultSet.seriesNames();
                const pivot = resultSet.chartPivot();
                const series = [];
                seriesNames.forEach(e => {
                    const data = pivot.map(p => [p.x, p[e.key]]);
                    series.push({
                    name: e.key,
                    data
                    });
                });
                return series;
            },

            pairs(resultSet) {
                return resultSet.series()[0].series.map(item => [item.x, item.value]);
            },

            seriesPairs(resultSet) {
                return resultSet.series().map(seriesItem => ({
                    name: seriesItem.title,
                    data: seriesItem.series.map(item => 
                        { return {
                            "name": (moment(item.x).isValid()) ? moment(item.x).format('M. Y') : item.x, 
                            "value" : item.value
                        }})
                }));
            },
            setConfig (config) {
                var self = this;
                if (self.config ) {
                    if (self.config.svgClass) {
                        this.svgClass = self.config.svgClass;
                    }
                    if (self.config.title) {
                        self.title = self.config.title;
                    }
                    if (self.config.labelY) {
                        self.labelY = self.config.labelY;
                    }
                    if (self.config.chartType) {
                        self.chartType = self.config.chartType;
                    }
                    if (self.config.maxRows) {
                        self.maxRows = self.config.maxRows;
                    }
                    if (self.config.sumOthers) {
                        self.sumOthers = self.config.sumOthers;
                    }
                    if (self.config.percentages) {
                        self.percentages = self.config.percentages;
                    }

                }
            }
         },
        mounted() {
            //
            var self = this;
            
           
            Vue.nextTick()
            .then(function () {
                // DOM updated
                self.setConfig(self.config);
                self.onRefreshed(self.config);
            })
            
            
           
            
        }
    }
</script>
