<template>
    <div>
    <v-toolbar>
      <v-toolbar-title>
        {{$t('objectDetails')}}       

      </v-toolbar-title>
       <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
        ></v-progress-linear>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text @click="copyNameToClipboard">{{$t('copyNameToClipboard')}}</v-btn>
        <v-btn text @click="copyLinkToClipboard">{{$t('copyLinkToClipboard')}}</v-btn>
        <v-btn text @click="openPreview" v-if="PREVIEW">{{$t('openPreview')}}</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-form v-model="valid" ref="form" lazy-validation v-if="!loading">        
    
  
    <v-text-field
      :label="$t('DISCOBJECT.NAME')"
      v-model="NAME"
      :readonly="true"
    ></v-text-field>
    <v-text-field
      :label="$t('DISCOBJECT.TYPE')"
      v-model="TYPE"
      :readonly="true"
    ></v-text-field>
    <v-text-field
      :label="$t('DISCOBJECT.DESCRIPTION')"
      v-model="DESCRIPTION"
      :readonly="true"
    ></v-text-field>    
    <source-component :resourceId="UUID" v-if="SOURCE"  :linkToSource="LINK_TO_SOURCE" ></source-component>
    <relations-chart-component :resourceId="UUID" ></relations-chart-component>
    <v-toolbar>
      <v-toolbar-title>{{$t('links')}}</v-toolbar-title>
      <v-progress-linear
          :active="linkloading"
          :indeterminate="linkloading"
          bottom
          absolute
        ></v-progress-linear>
      <v-spacer></v-spacer>
       
    </v-toolbar>
    <v-data-table
        :headers="linkheaders"
        :items="linkitems"    
        class="elevation-1"
        :loading="linkloading"
        :options.sync="linkpagination"
        :server-items-length="linktotalItems"
      >
      <v-data-footer :items-per-page-options="linkrowsPerPageItems"  />
      <template v-slot:body="{ items }">
        <tbody>          
          <tr v-for="item in items" :key="item.UUID + item.LINK_NAME" >
            <td  ><router-link :to="`${projectUrl}/DISCOBJECT/${item.UUID}`">{{ item.NAME }}</router-link></td>
            <td >{{ item.TYPE }}</td>
            <td >{{ item.DESCRIPTION }}</td>
            <td >{{ item.LINK_NAME }}</td>
          </tr>
        </tbody>
      </template>
      
    </v-data-table>
    <v-toolbar>

      <v-toolbar-title>{{$t('attributes')}}</v-toolbar-title>
      <v-progress-linear
          :active="attrloading"
          :indeterminate="attrloading"
          bottom
          absolute
        ></v-progress-linear>
      <v-spacer></v-spacer>
    </v-toolbar>      
      <v-data-table
        :headers="attrheaders"
        :items="attritems"    
        class="elevation-1"
        :loading="attrloading"
        :options.sync="attrpagination"
        :server-items-length="attrtotalItems"
      >
        <v-data-footer :items-per-page-options="attrrowsPerPageItems" />
        <!--
        <template slot="items" slot-scope="props">
          <tr @click="openAttrDetail(props.item)">
          <td>{{ props.item.NAME }}</td>
          <td >{{ props.item.TYPE }}</td>
          <td >{{ props.item.DESCRIPTION }}</td>
          <td >{{ props.item.VALUE }}</td>
          </tr>
        </template>
        -->
      </v-data-table>
      <usage-component :resourceId="UUID" :resourceName="NAME" :resourceType="TYPE"  ></usage-component>  <!--v-if="TYPE=='JOB'"-->
      <!--
      <invocations-chart-component :resourceId="UUID" :resourceName="NAME" :resourceType="TYPE" v-if="(TYPE=='OPC')||(TYPE=='IMSTRAN')" ></invocations-chart-component>
      -->
    <v-btn @click="cancel" color="info">{{$t('cancelitem')}}</v-btn>
    <!--
    <v-btn @click="deleteItem" color="error">{{$t('deleteitem')}}</v-btn>
    -->
  </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue';

  export default {
    data: () => ({
      loading: false,
      saving: false,
      valid: true,
      NAME: null,
      TYPE:null,
      DESCRIPTION: null,
      UUID: null,
      SOURCE: null,
      PREVIEW: null,
      LINK_TO_SOURCE: null,
      projectUrl: null,
      //link
      linkheaders: [
          {
            text: Vue.i18n.translate('DISCOBJECT.NAME'),
            align: 'left',
            value: 'NAME'
          },
          {
            text: Vue.i18n.translate('DISCOBJECT.TYPE'),
            align: 'left',
            value: 'TYPE'
          },
          {
            text: Vue.i18n.translate('DISCOBJECT.DESCRIPTION'),
            align: 'left',
            value: 'DESCRIPTION'
          },
          {
            text: Vue.i18n.translate('DISCOBJECT.LINK_TYPE'),
            align: 'left',
            value: 'LINK_NAME'
          } 
      ],
      linkitems: [],
      linktotalItems: 0,
      linkloading: true,
      linkpagination: {
          sortBy: ["NAME"],
          page: 1,
          itemsPerPage: 10,
          sortDesc: [false],
          multiSort: false
      },
      linkrowsPerPageItems:[10,25,50],
      //attributes
      attrheaders: [
          {
            text: Vue.i18n.translate('ATTRIBUTE.NAME'),
            align: 'left',
            value: 'NAME'
          },
          {
            text: Vue.i18n.translate('ATTRIBUTE.TYPE'),
            align: 'left',
            value: 'TYPE'
          },
          {
            text: Vue.i18n.translate('ATTRIBUTE.DESCRIPTION'),
            align: 'left',
            value: 'DESCRIPTION'
          },
          {
            text: Vue.i18n.translate('ATTRIBUTE.VALUE'),
            align: 'left',
            value: 'VALUE'
          } 
        ],
        attritems: [],
        attrtotalItems: 0,
        attrloading: true,
        attrpagination: {
          sortBy: ["NAME"],
          page: 1,
          itemsPerPage: 10,
          sortDesc: [false],
          multiSort: false
        },
        attrrowsPerPageItems:[10,25,50]        


    }),
    mounted () {
      this.UUID = this.$route.params.UUID;
      this.projectUrl = "/" + this.$route.params.project;
      if (this.UUID) {        
        this.getDataFromApi()
          .then(data => {
            if (data) {
              this.NAME = data.NAME;
              this.TYPE = data.TYPE;
              this.DESCRIPTION = data.DESCRIPTION;   
              this.SOURCE=data.SOURCE;         
              this.PREVIEW=data.PREVIEW;   
              this.LINK_TO_SOURCE = data.LINK_TO_SOURCE
              document.title = this.NAME + " - " +  Vue.i18n.translate('APP.TITLE');
            }
          });
        this.getlinkDataFromApi()
            .then(data => {
              this.linkitems = data.items
              this.linktotalItems = data.totalItems
            })
        this.getattrDataFromApi()
            .then(data => {
              this.attritems = data.items
              this.attrtotalItems = data.totalItems
            })

      } else {
        
      }
    },
    watch: {
      linkpagination: {
        handler () {
          this.getlinkDataFromApi()
            .then(data => {
              this.linkitems = data.items
              this.linktotalItems = data.totalItems
            })
        },
        deep: true
      },
      attrpagination: {
        handler () {
          this.getattrDataFromApi()
            .then(data => {
              this.attritems = data.items
              this.attrtotalItems = data.totalItems
            })
        },
        deep: true
      }      
    },
    methods: {
      cancel () {
        var self = this;
        self.$router.push({ path: `${self.projectUrl}/DISCOBJECT/`}); 
      },
      getDataFromApi () {
        var self = this;
        this.loading = true;
        

        return new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECT/' + this.UUID,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                if (response.data && response.data.length) {
                  resolve(response.data[0]);
                } else {
                  resolve(null);
                }
                
            });
        
        });
      },
      getlinkDataFromApi () {
        var self = this;
        this.linkloading = true;        
        return new Promise((resolve, reject) => {
            const { sortBy, sortDesc, page, itemsPerPage }  = self.linkpagination
            var targetSortBy = null;
            if (sortBy && sortBy[0]) {
              targetSortBy = sortBy[0];
              if (targetSortBy.indexOf("pivot.") >=0) {
                targetSortBy = targetSortBy.replace("pivot.", "pivot_");
              }
            }
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECT/' + this.UUID + '/links?paged=1&page=' + page + "&orderby=" + targetSortBy + "&descending=" + sortDesc[0] + "&rowsperpage=" + itemsPerPage,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.linkloading = false;
                resolve({
                    items: response.data.data,
                    totalItems: response.data.total
                });
            });
        
        });        
      },
       getattrDataFromApi () {
        var self = this;
        this.attrloading = true;        
        return new Promise((resolve, reject) => {
            const { sortBy, sortDesc, page, itemsPerPage }  = self.attrpagination
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECT/' + this.UUID + '/attributes?paged=1&page=' + page + "&orderby=" + sortBy[0] + "&descending=" + sortDesc[0] + "&rowsperpage=" + itemsPerPage,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.attrloading = false;
                var itemsWithValue = response.data.data;
                if (itemsWithValue) {
                  for (var i=0; i< itemsWithValue.length; i++) {
                    var curItem = itemsWithValue[i];
                    var value = null ;
                    if (curItem) {
                      switch (curItem.TYPE) {
                         case "STRING" :
                            value = curItem.STRING_VALUE;
                            break;
                          case "INT":
                            value = curItem.INT_VALUE;
                            break;
                          case "DATE":
                            value = curItem.DATE_VALUE;
                            break;
                          default:
                            value = curItem.STRING_VALUE;
                            break;
                      }                      
                      curItem.VALUE = value;
                    }

                  }
                }

                resolve({
                    items: response.data.data,
                    totalItems: response.data.total
                });
            });
        
        });        
      },
      openLinkDetail ($item) {
        var self = this;
        this.$router.push({ path: `${self.projectUrl}/DISCOBJECT/${$item.UUID}`});
      },  
      openLinkDetailNew ($item) {
        var self = this;
        let routeData = this.$router.resolve({path: `${self.projectUrl}/DISCOBJECT/${$item.UUID}`});
        window.open(routeData.href, '_blank');
      },    
      openAttrDetail ($item) {
        //this.$router.push({ path: `/DISCOBJECT/${$item.UUID}`});
        return;
      },   
      copyLinkToClipboard() {
        var el = document.createElement('textarea');
        el.value='http://tpca.exconma.com/api/OBJECT/' + encodeURIComponent(this.TYPE) + '/' + encodeURIComponent(this.NAME);       
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

      },   
      copyNameToClipboard() {
        var el = document.createElement('textarea');
        el.value = this.NAME;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      },   
      openPreview ($item) {
        var self = this;
        window.open(discovererApiRoot	+ 'PREVIEW/' + this.UUID, '_blank' );
      }, 

    }
  }
</script>
