<template>
    <div>
    <v-toolbar>
      <v-toolbar-title>{{$t('userDetails')}}</v-toolbar-title>
      <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
        ></v-progress-linear>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
      </v-toolbar-items>
    </v-toolbar>
    <v-form v-model="valid" ref="form" v-if="!loading">            
    <v-text-field
      :label="$t('User.email')"
      :rules="emailRules"
      v-model="email"
      required
      :readonly="!editing"
    ></v-text-field>
    <v-text-field
      :label="$t('User.name')"
      v-model="name"
      :readonly="!editing"
    ></v-text-field>
    <v-text-field
      :label="$t('User.password')"
      v-model="password"
      v-if="reseting"
      type="password"
    ></v-text-field>    
    <v-text-field
      :label="$t('User.passwordRepeat')"
      v-model="passwordRepeat"
      v-if="reseting"
      :error-messages="passwordRepeatErrors"
      type="password"
    ></v-text-field>  
    <v-btn @click="edit" color="primary" v-if="!editing && !reseting">{{$t('edititem')}}</v-btn>
    <v-btn @click="resetPassword" color="primary"  v-if="!onlyEmailLookup && !editing && !reseting">{{$t('resetPassword')}}</v-btn>
    <v-btn @click="deleteItem" color="error" v-if="!editing && !reseting">{{$t('deleteitem')}}</v-btn>
    <v-btn @click="save" color="primary"  v-if="!saving && (editing || reseting)">{{$t('submititem')}}</v-btn>
    <v-btn @click="cancel" color="info" v-if="!saving && (editing || reseting)">{{$t('cancelitem')}}</v-btn>    
    <v-toolbar style="margin-top: 20px;">
      <v-toolbar-title>{{$t('User.roles')}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
      </v-toolbar-items>
    </v-toolbar>
    <v-chip-group
        v-model="selectedRoles"
        column
        multiple
      >
        <v-chip filter outlined v-for="roleItem in roleItems" :key="roleItem.ID" :value="roleItem.ID">
          {{ roleItem.NAME }}
        </v-chip>     
      </v-chip-group>    
    <v-btn @click="back" color="info" v-if="!editing && !reseting && !saving">{{$t('back')}}</v-btn>    
    
    
  </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue';
  export default {
    data: () => ({
      initializing: false,
      loading: false,
      saving: false,
      editing: false,
      reseting: false,
      valid: true,
      id: null,
      name: null,
      email:null,      
      password: null,
      passwordRepeat: null,
      origname: null,
      origemail: null,
      onlyEmailLookup: false,         
      passwordRepeatErrors: [],
      emailRules: [
        v => !!v || Vue.i18n.translate('required'),
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || Vue.i18n.translate('validemail')
      ],
      emailErrors: [],
      emailValidating: false,
      roleItems: [],
      selectedRoles: [],
      prevSelectedRoles: []

    }),
    mounted () {
      var self = this;
      self.initializing = true;
      if (this.$configuration.authProvider == 'users') {
        self.onlyEmailLookup = false;
      } else {
        self.onlyEmailLookup = true;
      }
      if (!this.$route.params.id || this.$route.params.id == 'new') {
        this.id = null;
      } else {
        this.id = this.$route.params.id;
      }
      this.getRolesDataFromApi()
      .then(data=> {
        self.roleItems = data.items;
        if (this.id) {        
                this.getDataFromApi()
                  .then(data => {
                    if (data) {
                      this.name = data.name;
                      this.email =data.email;
                      this.origname = data.name;
                      this.origemail =data.email;
                      this.password = null;
                      this.passwordRepeat = null;
                      document.title = this.name + " - " +  Vue.i18n.translate('APP.TITLE');
                      if (data.roles) {
                        self.selectedRoles = [];
                        self.prevSelectedRoles = [];

                        for(var i=0; i<data.roles.length; i++) {
                          self.selectedRoles.push(data.roles[i].ID);     
                          self.prevSelectedRoles.push(data.roles[i].ID);                       
                        }
                      }                      
                    }
                    setTimeout(() => {
                      self.initializing = false;
                      },100);
                    
                    
                  });       
        } else {
          self.initializing = false;
          self.editing = true;
          if (!self.onlyEmailLookup) {
            self.reseting = true;
          } else {
            self.reseting = false;
          }
        }
      });
     
    },
    watch: {
      selectedRoles (val) {
        var self = this;
        if (!self.initializing) {
          self.setRoles().then(data => {});
        }
      }
    },
    methods: {
      back () {
        var self = this;
        self.$router.push({ path: `/User/`}); 
      },
      cancel () {
        this.editing = false;
        this.reseting = false;
        this.name = this.origname;
        this.email = this.origemail;
        this.password = null;
        this.passwordRepeat = null;
        if (!this.id) {
          this.back();
        }
      },
      edit () {
        
        this.origname = this.name;
        this.origemail = this.email;
        this.editing = true;
      },
      resetPassword () {
        this.password = null;
        this.passwordRepeat = null;
        this.reseting = true;

      },
      getDataFromApi () {
        var self = this;
        this.loading = true;
        

        return new Promise((resolve, reject) => {
            axios.get(discovererApiRoot	 + 'User/' + this.id,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                if (response.data && response.data) {
                  resolve(response.data);
                } else {
                  resolve(null);
                }
                
            });
        
        });
      },   
      getRolesDataFromApi () {
        var self = this;
        this.loading = true;        
        return new Promise((resolve, reject) => {
            axios.get(discovererApiRoot + 'Role?orderby=NAME',{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                resolve({
                    items: response.data
                });
            });
        
        });        
      },
      save () {
        this.saving = true;
        var self = this;
        if (this.password != this.passwordRepeat) {
          this.passwordRepeatErrors = [Vue.i18n.translate('passwordsNotTheSame')];
          return;
        } else {
          this.passwordRepeatErrors = [];
        }

        if (!this.$refs.form.validate()) {
          return;
        }
        var data = {
          name: this.name,
          email: this.email
        }
        if (this.password) {
          data.password = this.password;
        }

        if (!this.id) {
          // create
          axios.post(discovererApiRoot + 'User', data,{
                          headers: {
                                      'Content-Type': 'application/json',
                                      'Accept':'application/json',
                                  }

                      }).then(function(response) {
                          self.saving = false;  
                          if (response && response.data && response.data.id) {
                           self.id = response.data.id; 
                            self.setRoles()
                            .then(data => {
                              self.$router.push({ path: `../User/`}); 
                            }) 
                          } 
                     
                                              
                      });
        } else {
          //update
           axios.put(discovererApiRoot + 'User/' + this.id ,data,{
                          headers: {
                                      'Content-Type': 'application/json',
                                      'Accept':'application/json',
                                  }

                      }).then(function(response) {
                          self.saving = false;
                          self.$router.push({ path: `../User/`});                     
                      });
        }
        
      },   
      deleteItem () {
        this.saving = true;
        var self = this;       
        if (this.id) {       
          //delete
            axios.delete(discovererApiRoot + 'User/' + this.id,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.saving = false;
                self.$router.push({ path: `../User/`});                     
            });
        }
      },
      setRoles () {
        
        var self = this;
        var data = self.selectedRoles;
        return new Promise((resolve, reject) => {
          if (this.id) {     
            //update roles
            this.saving = true;
            axios.put(discovererApiRoot + 'UserRoles/' + this.id ,data,{
                            headers: {
                                        'Content-Type': 'application/json',
                                        'Accept':'application/json',
                                    }

                        }).then(function(response) {
                            self.saving = false;
                            resolve();
                        });
          }
        });
        
      },     
    }
  }
</script>
