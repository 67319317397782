<template>
    <div>

    <v-toolbar>
      <v-toolbar-title>{{TYPE}} {{$t('browser')}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form v-model="valid" ref="form" lazy-validation >        
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              :label="$t('DISCOBJECT.NAME')"
              v-model="NAME1" readonly
              clearable
              @click:clear="clear"
              :append-outer-icon="'folder_open'"
              @click:append-outer.stop="dialogSource=true"
            ></v-text-field>
            <v-dialog v-model="dialogSource" max-width="600px" scrollable>             
              <discobject-picker-component v-on:select-item="selectSource($event)" :type="TYPE" :isShown="dialogSource" />
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              :label="$t('nameFilter')"
              v-model="nameFilterTemp" 
              :append-outer-icon="(nameFilterApplied != nameFilterTemp) ? 'send' : ''"
              clearable
              type="text"
              @click:append-outer="applyNameFilter"
              @click:clear="clearNameFilter"
              @keydown.enter="applyNameFilter"
              :rules="[rules.regex]"
            ></v-text-field>
          </v-col>        
        </v-row>               
        <v-row>
          <v-col
            cols="12"
          >
            <div class="relations-container-inner">
              <v-toolbar style="flex-grow: 0;flex-shrink: 0;">
                <v-toolbar-title>{{$t('tangledChart')}}</v-toolbar-title>
                <v-progress-linear
                  :active="loading"
                  :indeterminate="loading"
                  absolute
                  bottom
                ></v-progress-linear>
                <v-spacer></v-spacer>
                <v-toolbar-items class="hidden-sm-and-down">
                    <v-tooltip bottom v-if="!loading" >
                        <template v-slot:activator="{ on }">
                            <v-btn text @click="exportGraph" v-on="on"  >
                                <v-icon>save_alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span>{{$t('export')}}</span>
                    </v-tooltip>  
                </v-toolbar-items>
              </v-toolbar>
              <v-container fluid class="relations-container">            
                <v-row >
                  <v-col cols="12" >
                    
                    <tangled-chart-component :expanded="true" :levels="filteredLevels" :resourceId="UUID1" :availableNodesDict="availableNodesDict" 
                      ref="tangledChart" v-on:node-click="openDetailDialog" v-on:node-ctrl-click="onCtrlClick" :style="{ display: (loading) ? 'none' : ''}"
                      />
                    <v-dialog
                      v-model="detailDialog"
                      max-width="900"
                      >
                      <v-card>
                          <v-card-title class="headline">{{detailTitle}}</v-card-title>

                          <v-card-text>
                              <div v-if="detailLoading">{{$t('loading')}}</div>
                              <tangled-chart-component :expanded="true" :levels="detailLevels" ref="detailTangledChart" 
                                 v-on:node-click="changeDetail" v-on:node-ctrl-click="onCtrlClick" :style="{ display: (detailLoading) ? 'none' : ''}" />

                              <!--
                              <svg id="detailgraphsvg"  class="relations-chart-container" :style="{ display: (detailLoading) ? 'none' : ''}"></svg>
                          -->
                          </v-card-text>

                          <v-card-actions>
                              <v-btn text @click="exportGraphDetail" v-if="!detailLoading" >
                                  <v-icon>save_alt</v-icon>
                              </v-btn>
                              <v-spacer></v-spacer>

                              <v-btn
                                  color="green darken-1"
                                  text
                                  @click="detailDialog = false"
                              >
                                  {{$t('close')}}
                              </v-btn>

                          
                          </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-container>  
            </div>     
          </v-col>
        </v-row>

      </v-container>    
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import * as levelsHelper from '../../partials/levels.js'
import Vue from 'vue';

  export default {
    data: () => ({
      loading: false,
      saving: false,
      dialogSource: false,
      dialogDestination: false,
      valid: true,
      NAME1: null,
      TYPE1:null,
      NAME2: null,
      TYPE2:null,
      UUID1: null,
      UUID2: null,
      TYPE: null,
      OPTYPE: null,
      chartResourceId: null,
      nameFilterTemp: null,
      nameFilterApplied: null,
      availableNodesDict: null,
      rules: {
          regex: value => {
            //const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            //return pattern.test(value) || 'Invalid e-mail.'
            try {
              var re = new RegExp(value);
              return true;
            }
            catch (ex) {
              return false;
            }
          },
        },
      detailDialog: false,
      detailTitle: null,
      detailLoading: false, 
      levels: null, 
      filteredLevels: null,
      detailLevels: null,
    }),
    mounted () {
      this.TYPE = this.$route.params.TYPE;
      this.OPTYPE = this.$route.params.OPTYPE;
      document.title = this.TYPE + ' ' + Vue.i18n.translate('browser') + " - " +  Vue.i18n.translate('APP.TITLE');
      this.showChart();
    },
    watch: {      
    },
    methods: {
      selectSource (item) {
        this.dialogSource = false;
        if(item) {
          this.NAME1 = item.NAME;
          this.TYPE1 = item.TYPE;
          this.UUID1 = item.UUID;
          //this.findInterfaces();
          this.showChart();
        }
      },
      selectDestination (item) {
        this.dialogDestination = false;
        if(item) {
          this.NAME2 = item.NAME;
          this.TYPE2 = item.TYPE;
          this.UUID2 = item.UUID;
        }
      },      
      cancel () {
        var self = this;
        self.$router.push({ path: `../../DISCOBJECT/`}); 
      },
      clear () {
        var self = this;
        self.UUID1 = null;
        self.NAME1 = null;
        self.TYPE1 = null; 
        this.showChart();
      },      
      applyNameFilter () {
        var self = this;
        self.nameFilterApplied = self.nameFilterTemp;
        self.showChart();
      },
      clearNameFilter () {
        var self = this;
        self.nameFilterTemp = null;
        self.nameFilterApplied = self.nameFilterTemp;
        self.showChart();
      },
      exportGraph() {
          var self = this;
          self.$refs.tangledChart.exportGraph();
      },
      exportGraphDetail() {
          var self = this;
          self.$refs.detailTangledChart.exportGraph();
      },
      showChart () {                
          var self = this;
          if (!self.TYPE) {
              return;
          }
          this.display  = 'flex';
          if (this.isLoaded) {
              // just show;
              self.onRefreshed();
          } else {
              self.loadData(this.TYPE, this.OPTYPE).then(function(graph) {
                  self.onRefreshed();
              });
              return;
          }
      },
      loadData() {
        var self = this;
        self.loading = true;
        
        return new Promise((resolve, reject) => {
          axios.get(self.$store.getters.currentProjectApiRoot + 'NEOFLOW2?type='  + self.TYPE + "&optype=" + self.OPTYPE ,{
            headers: {
                        'Content-Type': 'application/json',
                        'Accept':'application/json',
                    }
          })
          .then(function(graph) {
              
              self.isLoaded = true;
              self.levels = graph.data.levels;
              self.loading = false;
              resolve(graph);      
                              
          } , function(err) {
              self.loading = false;
              reject(err);
            });    
            
          });               
      },
      loadDetailData(id) {
          var self = this;
          self.detailLoading = true;
          return new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'NEOFLOW2/' + id + "?type=" + self.TYPE + "&optype=" + self.OPTYPE , {
              headers: {
                          'Content-Type': 'application/json',
                          'Accept':'application/json',
                      }
            })
            .then(function(graph) {
                self.detailLoading = false;
                self.detailLevels = graph.data.levels;
                resolve(graph);      
                                
            } , function(err) {
                self.detailLoading = false;
                reject(err);
            });    
              
          });               
      },
      onRefreshed() {
        var self = this;
        levelsHelper.filterLevels(self.levels, self.UUID1, self.nameFilterApplied, 
            (filteredLevels, availableNodesDict, availableNodesExtendedDict) => {
                self.availableNodesDict = availableNodesDict;
                self.filteredLevels = filteredLevels;
            });
      },
      onCtrlClick (node) {
          var self = this;
          if (node) {
              var targetId = node.id;
              if (node.subid) {
                  targetId = node.subid;
              }
              let routeData = self.$router.resolve({path: `../../DISCOBJECT/${targetId}`});
              window.open(routeData.href, '_blank');
          }
          
      },

      openDetailDialog(node) {
          var self = this;
          if (node) {
              self.detailDialog = true;
              self.detailTitle = node.title;
              self.loadDetailData(node.id).then(function(graph) {
                      
                  });
          }
      },
      changeDetail(node) {
          var self = this;
          if (node && node.documentation == self.TYPE) {
              self.detailDialog = true;
              self.detailTitle = node.title;
              self.loadDetailData(node.id).then(function(graph) {
                      
                  });
          }
      },      
    }
  }
</script>
