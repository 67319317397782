<template>
    <svg :id="id"  class="relations-chart-container"></svg>
</template>

<script>
import * as d3 from 'd3';
import * as zoom from 'd3-zoom';
import * as multi from 'd3-selection-multi';
import { Promise } from 'q';
import XLSX from 'xlsx';
import * as levelsHelper from '../../partials/levels.js'
import Vue from 'vue';

export default {
        props: [ 'resourceId', 'expanded', 'levels','availableNodesDict'],
        data () {
            return {
                id: null,
                maxX: 0,
                maxY: 0,
                detailMaxX: 0,
                detailMaxY: 0, 
                projectUrl: "",
                objectTypes:{}               
            }
        },
        watch: { 
            levels: function(newVal, oldVal) { 
                if (newVal) {
                    this.showChart();
                } else {
                    this.hideChart();
                }
                
            },
            expanded: function(newVal, oldVal) { 
                if (newVal) {
                    this.showChart();
                } else {
                    hideChart();
                }
            },    
                  
        },
        methods: {      
            showChart () {                
                var self = this;            
                self.hideChart();
                this.isShown = true; 
                this.display  = 'flex';
                self.color = d3.scaleOrdinal(d3.schemeCategory10 );
                var chartParams = self.renderChart(self.levels, self.id, self.availableNodesDict)
                if (chartParams) {
                    self.maxX = chartParams.maxX;
                    self.maxY = chartParams.maxY;
                }
            },
            hideChart () {
                this.isShown = false;
                this.display = 'none';
            },            
            getColor(type, colorScale) {
                var x = 9;
                if (this.objectTypes && this.objectTypes[type] && this.objectTypes[type].color) {
                    x=this.objectTypes[type].color;
                } 
                //console.log(d.documentation + x);
                return colorScale(x);
            },                               

            renderChart(levels, svgElementId, availableNodesDict) { //onClick, onCtrlClick,
                var svg = d3.select("#" + svgElementId);//,
                    //width = +svg.attr("width"),
                    //height = +svg.attr("height");
                svg.selectAll("*").remove();
                if (!levels) {
                    return ;
                }            
                var self = this;
                var filteredNodes = [];                
                var filteredLinks = [];
                var tempLinks = [];
                var selectedTypeDict = [];
                var filteredNodesDict = [];
                var filteredLevels = [];
                levels.forEach((l, i) => {
                    var isLevelSelected = false;
                    var levelNodes = [];
                    l.forEach(n => {
                        var reducedNode = {
                            id: n.id,
                            title: n.title,
                            label: n.label,
                            documentation: n.documentation,  
                            subid: n.subid, 
                            subtitle: n.subtitle, 
                            subdocumentation: n.subdocumentation, 
                            parents: [],
                            linkTypes: n.linkTypes                                          
                        }
                        if (n.parents) {
                                n.parents.forEach(p => {
                                reducedNode.parents.push(p);
                            });
                        }
                        levelNodes.push(reducedNode);
                    });
                    filteredLevels.push(levelNodes);
                });




                // precompute level depth
                filteredLevels.forEach((l,i) => l.forEach(n => n.level = i));
                
                var nodes = filteredLevels.reduce( ((a,x) => a.concat(x)), [] );
                var nodes_index = {};
                nodes.forEach(d => nodes_index[d.id] = d);
                
                // objectification
                nodes.forEach(d => {
                    d.parents = (d.parents === undefined ? [] : d.parents).map(p => nodes_index[p]);
                })
                
                // precompute bundles
                filteredLevels.forEach((l, i) => {
                    var index = {};
                    l.forEach(n => {
                        if(n.parents.length == 0) {
                            return;
                        }
                        
                        var id = 's' + i + '--' + n.parents.map(d => d.id).sort().join('--');
                        if (id in index) {
                            var tmpParents = index[id].parents;
                            index[id].parents = tmpParents.concat(n.parents.filter((item) => tmpParents.indexOf(item) < 0 )); // index[id].parents.concat(n.parents);
                        }
                        else {
                            index[id] = {id: id, parents: n.parents.slice(), level: i};
                        }
                        n.bundle = index[id];
                    })
                    l.bundles = Object.keys(index).map(k => index[k]);
                    l.bundles.forEach((b, i) => b.i = i);
                })
                
                var links = []
                nodes.forEach(d => {
                    d.parents.forEach(p => links.push({source: d, bundle: d.bundle, target: p}));
                })
                
                var bundles = filteredLevels.reduce( ((a,x) => a.concat(x.bundles)), [] );
                
                // reverse pointer from parent to bundles
                bundles.forEach(b => b.parents.forEach(p => {
                    if(p.bundles_index === undefined) {
                        p.bundles_index = {};
                    }
                    if(!(b.id in p.bundles_index)) {
                        p.bundles_index[b.id] = [] ;
                    }
                    p.bundles_index[b.id].push(b);
                }))
                
                nodes.forEach(n => {
                    if(n.bundles_index !== undefined) {
                        n.bundles = Object.keys(n.bundles_index).map(k => n.bundles_index[k]);
                    }
                    else {
                        n.bundles_index = {};
                        n.bundles = [];
                    }
                    n.bundles.forEach((b, i) => b.i = i);
                })
                
                links.forEach(l => {
                    if(l.bundle.links === undefined) {
                        l.bundle.links = [];
                    }
                    l.bundle.links.push(l);
                })                
               
                filteredLinks = links;
                filteredNodes = nodes;               
                var filteredBundles = bundles;

                // layout
                const padding = 8;
                const node_height = 22;
                const node_width = 70;
                const bundle_width = 14;
                const level_y_padding = 16;
                const metro_d = 4;
                const c = 16;
                const min_family_height = 16;
                
                filteredNodes.forEach(n => n.height = (Math.max(1, n.bundles.length)-1)*metro_d);
                
                var x_offset = padding;
                var y_offset = padding;
                filteredLevels.forEach(l => {
                    x_offset += l.bundles.length*bundle_width;
                    y_offset += level_y_padding;
                    l.forEach((n, i) => {
                        n.x = n.level*node_width + x_offset;
                        n.y = node_height + y_offset + n.height/2;                        
                        y_offset += node_height + n.height;
                    })
                })
                
                var i = 0
                filteredLevels.forEach(l => {
                    l.bundles.forEach(b => {
                        var maxParentX = 0;
                        b.parents.forEach(n => {
                            if (n.x > maxParentX) {
                                maxParentX = n.x;
                            }
                        });
                        b.x = maxParentX + node_width + (l.bundles.length-1-b.i)*bundle_width; // b.parents[0].x + node_width + (l.bundles.length-1-b.i)*bundle_width;
                        b.y = i*node_height;
                    })
                    i += l.length
                })
                    
                filteredLinks.forEach(l => {
                    l.xt = l.target.x;
                    l.yt = l.target.y + l.target.bundles_index[l.bundle.id].i*metro_d - l.target.bundles.length*metro_d/2 + metro_d/2;
                    l.xb = l.bundle.x;
                    l.xs = l.source.x;
                    l.ys = l.source.y;
                })
                
                // compress vertical space
                var y_negative_offset = 0;
                filteredLevels.forEach(l => {
                    y_negative_offset += -min_family_height + d3.min(l.bundles, b => d3.min(b.links, link => (link.ys-c)-(link.yt+c))) || 0;
                    l.forEach(n => n.y -= y_negative_offset);
                })
                
                // very ugly, I know
                filteredLinks.forEach(l => {
                    l.yt = l.target.y + l.target.bundles_index[l.bundle.id].i*metro_d - l.target.bundles.length*metro_d/2 + metro_d/2;
                    l.ys = l.source.y;
                    l.c1 = c; //l.source.level-l.target.level > 1 ? node_width+c : c;
                    l.c2 = c;
                })
                
                var layout = {
                    height: d3.max(filteredNodes, n => n.y) + node_height/2 + 2*padding,
                    node_height,
                    node_width,
                    bundle_width,
                    level_y_padding,
                    metro_d
                };
                
                //return {levels, nodes, nodes_index, links, bundles, layout}

                var clientWidth = document.getElementById(svgElementId).clientWidth;
                var clientHeight = document.getElementById(svgElementId).clientHeight;
                var width = clientWidth, height = clientHeight;
                var scale = 1.0;
                var maxX = 0;
                var maxY = 0;
                var zoom = d3.zoom()
                    //.scale(scale)
                    .scaleExtent([0.5, 5])
                    .on("zoom", zoomed);
                
                svg.call(zoom);
                var container = svg.append("g")
                    .attr("id", "container")
                    .attr("transform", "translate(0,0)scale(1,1)");
                filteredBundles.map(b => {
                    let d = b.links.map(l => `
                        M${ l.xt } ${ l.yt }
                        L${ l.xb-l.c1 } ${ l.yt }
                        A${ l.c1 } ${ l.c1 } 90 0 1 ${ l.xb } ${ l.yt+l.c1 }
                        L${ l.xb } ${ l.ys-l.c2 }
                        A${ l.c2 } ${ l.c2 } 90 0 0 ${ l.xb+l.c2 } ${ l.ys }
                        L${ l.xs } ${ l.ys }`
                    ).join("");
                    container.append("path").attrs({
                        'fill': 'none',
                        'd' : d,
                        'stroke' : "white",
                        'stroke-width':"5"
                    });
                    var rcolor =  b.i % 10 ; //Math.floor(Math.random() * 10);
                    container.append("path").attrs({
                        'fill': 'none',
                        'd' : d,
                        'stroke' :d3.schemeCategory10[rcolor], //self.getColor(b.id, self.color),
                        'stroke-width':"2"
                    });
                    /*
                    return `
                    <path class="link" d="${ d }" stroke="white" stroke-width="5"/>
                    <path class="link" d="${ d }" stroke="${ color(b.id) }" stroke-width="2"/>
                    `;
                    */
                    return;
                });
               
                filteredNodes.map(n => {
                    if (n.x > maxX) {
                        maxX = n.x;
                    }
                    if (n.y+n.height/2 > maxY) {
                        maxY = n.y+n.height/2;
                    }
                    container.append("line").attrs({
                        'stroke-linecap': 'round',
                        'stroke' : self.getColor(n.documentation, self.color),
                        'stroke-width':"8",
                        'x1':n.x,
                        'y1': n.y-n.height/2,
                        'x2': n.x,
                        'y2': n.y+n.height/2
                    });
                    container.append("line").attrs({
                        'stroke-linecap': 'round',
                        'stroke' : "white",
                        'stroke-width':"4",
                        'x1':n.x,
                        'y1': n.y-n.height/2,
                        'x2': n.x,
                        'y2': n.y+n.height/2
                    });
                    var textEl = container.append("text").attrs({
                        'font-family': 'sans-serif',
                        'font-size': '10px',
                        'stroke' : "white",
                        'stroke-width':"2",
                        'x':n.x+4,
                        'y': n.y-n.height/2-4                     
                    });
                    if (self.resourceId && n.id == self.resourceId) {
                        textEl = textEl.attrs({
                            'font-weight': 'bold',
                            'font-size': '12px',
                            'fill': '#e3342f',
                        });
                    } else {
                        if (availableNodesDict && !availableNodesDict[n.id]) {
                            textEl = textEl.attrs({
                                'font-style': 'italic',
                                'fill': 'gray',
                            });
                        }
                    }
                    var nodeTitle = n.title;
                    if (n.subtitle) {
                        nodeTitle = nodeTitle + ' (' + n.subtitle + ')';
                    }
                    textEl.text(nodeTitle)
                    .on("click", function(d){
                                //window.open(d.id, "_top");
                         if (d3.event.ctrlKey) {
                            //let routeData = self.$router.resolve({path: `/DISCOBJECT/${n.id}`});
                            //window.open(routeData.href, '_blank');
                            self.$emit('node-ctrl-click', n);
                            //if (onCtrlClick) {
                            //    onCtrlClick(n);
                            //}                           
                        } else {
                            //self.$router.push({ path: `/DISCOBJECT/${n.id}`});
                            self.$emit('node-click', n);
                            //if (onClick) {
                            //    onClick(n);
                            //}
                        }
                    }); 
                    var textEl2 = container.append("text").attrs({
                        'font-family': 'sans-serif',
                        'font-size': '10px',
                        'x':n.x+4,
                        'y': n.y-n.height/2-4                     
                    });
                    if (self.resourceId && n.id == self.resourceId) {
                        textEl2 = textEl2.attrs({
                            'font-weight': 'bold',
                            'font-size': '12px',
                            'fill': '#e3342f',
                        });
                    } else {
                        if (availableNodesDict && !availableNodesDict[n.id]) {
                            textEl2 = textEl2.attrs({
                                'font-style': 'italic',
                                'fill': 'gray',
                            });
                        }
                    }
                    textEl2.text(nodeTitle)
                    .on("click", function(d){
                                //window.open(d.id, "_top");
                        if (d3.event.ctrlKey) {
                            //let routeData = self.$router.resolve({path: `/DISCOBJECT/${n.id}`});
                            //window.open(routeData.href, '_blank');
                            self.$emit('node-ctrl-click', n);
                            //if (onCtrlClick) {
                            //    onCtrlClick(n);
                            //}                           
                        } else {
                            //self.$router.push({ path: `/DISCOBJECT/${n.id}`});
                            self.$emit('node-click', n);
                            //if (onClick) {
                            //    onClick(n);
                            //}
                        }
                    }); 
                    if (self.resourceId && nodes_index[self.resourceId]) {
                        var centerNode =nodes_index[self.resourceId];
                        svg.call(zoom.translateTo, centerNode.x, centerNode.y);
                    }
                    return ;
                }
                 
                );
                return {
                    maxX: maxX,
                    maxY: maxY
                };

                
                function dragstarted(d) {
                    if (!d3.event.active) simulation.alphaTarget(0.3).restart();
                    d.fx = d.x;
                    d.fy = d.y;
                }

                function dragged(d) {
                    d.fx = d3.event.x;
                    d.fy = d3.event.y;
                }

                function dragended(d) {
                    if (!d3.event.active) simulation.alphaTarget(0);
                    d.fx = null;
                    d.fy = null;
                }
                function zoomed() {
                    container.style("stroke-width", 1.5 / d3.event.transform.k + "px");
                    // g.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")"); // not in d3 v4
                    container.attr("transform", d3.event.transform); // updated for d3 v4
                }
            },                       
            
            exportTable() {
                /* convert from array of arrays to workbook */
                var self = this;
                
            },
            exportGraph() {
                var self = this;
                self.downloadGraph(self.id, self.maxX , self.maxY );
            },
            /*
            exportGraphDetail() {
                var self = this;
                self.downloadGraph("detailgraphsvg", self.detailMaxX , self.detailMaxY );
            },
            */
            downloadGraph(svgElementId, maxX, maxY) {
                /* convert to image */
                var self = this;
                //get svg element.
                var svg = document.getElementById(svgElementId);
                var container = svg.getElementById("container");
                var origTransform = container.getAttribute('transform');

                container.setAttribute("transform", "translate(0,0)scale(1,1)");
                svg.setAttribute("width", maxX + 200 );
                svg.setAttribute("height", maxY + 50 );
                //get svg source.
                var serializer = new XMLSerializer();
                var source = serializer.serializeToString(svg);

                //add name spaces.
                if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
                    source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
                }
                if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
                    source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
                }

                //add xml declaration
                source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

                var svgBlob = new Blob([source], {type:"image/svg+xml;charset=utf-8"});
                var svgUrl = URL.createObjectURL(svgBlob);
                var downloadLink = document.createElement("a");
                downloadLink.href = svgUrl;
                downloadLink.download = "chart.svg";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                container.setAttribute("transform", origTransform);
                svg.removeAttribute("width" );
                svg.removeAttribute("height" );
            },            
         },

        mounted() {
            //
            this.projectUrl = "/" + this.$route.params.project;            
            this.objectTypes =  this.$store.getters.objectTypes
            this.id = "tangledChart" + this._uid;
            if (this.expanded) {
                this.showChart();
            }
            
           
            
        }
    }
</script>
