<template>
  <v-app id="inspire">
    <component :is="layout">
            <router-view :key="$route.fullPath"></router-view>
    </component> 
  </v-app>      
</template>

<script>
const default_layout="application";
  export default {
    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + '-layout';
      }
    },
    mounted() {
      /*
        this.getConfiguration()
        .then(data => {
          this.configuration = data;
        })
        */
            console.log('Component mounted.')
    },
    data () {
      return {
        configuration: null,
        loading: false
      }
    },
    methods: {
        /*
        getConfiguration () {
            var self = this;
            return new Promise((resolve, reject) => {
                axios.get(discovererApiRoot + 'configuration',{
                    headers: {
                                'Content-Type': 'application/json',
                            }

                }).then(function(response) {
                    self.loading = false;
                    resolve({
                        data: response.data
                    });
                });
            
            });   
        }
        */   
    }

  }
</script>