<template>
    <div>
        <v-toolbar v-if="isShown">
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down">
                <v-tooltip bottom v-if="isShown" >
                    <template v-slot:activator="{ on }">
                        <v-btn text @click="exportTable" v-on="on">
                            <v-icon>save_alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span>{{$t('export')}}</span>
                </v-tooltip>  
            </v-toolbar-items>
        </v-toolbar>
        <div id="relations-container" style="width: 100%; overflow-x: scroll;" v-if="isShown">
            <v-container fluid grid-list-md>
                <div class="layout row wrap">
                    <v-flex
                        xs6
                        sm4
                        md3
                        lg2
                        v-for="(item, index) in legendItems" :key="'legend' + index"
                        >
                            <v-card>
                                <v-card-title>
                                    <span > 
                                        <v-checkbox v-model="item.selected" >
                                            <template v-slot:label>
                                            <div>
                                            <v-icon v-bind:style="{color: item.color}" >lens</v-icon>{{ item.title }}
                                            </div>
                                            </template>
                                        </v-checkbox> 
                                    </span>
                                </v-card-title>                            
                            </v-card>
                        </v-flex>
                </div>               
            </v-container>
            <svg id="graphsvg"  class="relations-chart-container"></svg>
        </div>
    </div>
</template>

<script>
import * as d3 from 'd3';
import * as zoom from 'd3-zoom';
import * as multi from 'd3-selection-multi';
import { Promise } from 'q';
import XLSX from 'xlsx';
import * as typeFilterCache from '../../partials/typeFilterCache.js'
import Vue from 'vue';


export default {
        props: ['chartData'],
        data () {
            return {
                isLoaded: false,
                legendItems: [],
                color: null,
                isShown: false,
                interfaceSettingsName: "interface",
                projectUrl: null,
                objectTypes:{}
            }
        },
        watch: { 
            chartData: function(newVal, oldVal) { 
                if (newVal) {
                    this.showChart();
                } else {
                    hideChart();
                }
            }
        },
        methods: {      
            showChart () {
                var self = this;
                self.isShown =true;
                //if (this.isLoaded) {
                //    // just show;
                //} else {
                    self.loadData(this.resourceId, self.color).then(function(graph) {
                        self.onRefreshed();
                    });
                    return;
                //}
            },
            hideChart () {
                this.isShown = false;
            },

            getColor(type, colorScale) {
                var x = 9;
                if (this.objectTypes && this.objectTypes[type] && this.objectTypes[type].color) {
                    x=this.objectTypes[type].color;
                } 
                //console.log(d.documentation + x);
                return colorScale(x);
            },
            loadData(uuid) {
                var self = this;
                self.color = d3.scaleOrdinal(d3.schemeCategory10 );
                return new Promise((resolve, reject) => {
                    var graph = self.chartData;
                    self.isLoaded = true;
                    self.legendItems = [];
                    var legendTempItems = [];
                    self.$options.nodes =[];
                    self.$options.links =[];
                    var typeSettings = typeFilterCache.getTypesSetting(self.interfaceSettingsName);
                    for(var i=0; i<graph.nodes.length; i++) {
                        var curNode = graph.nodes[i];
                        if (!legendTempItems[curNode.documentation]) {
                            var curType = curNode.documentation;
                            var newLegend = {
                                'title': curNode.documentation,
                                'color': self.getColor(curNode.documentation, self.color),
                                'selected': true
                            };
                            if (typeSettings[curType] && typeSettings[curType].disabled) {
                                newLegend.selected = false;
                            }
                            if (self.objectTypes && self.objectTypes[curType] && self.objectTypes[curType].hidden) {
                                newLegend.selected = false;
                            } 
                            legendTempItems[curNode.documentation]= newLegend;
                            self.legendItems.push(newLegend);
                        }
                        self.$options.nodes.push(curNode);
                    }
                    for(var i=0; i<graph.links.length; i++) {
                        var curLink = graph.links[i];                            
                        self.$options.links.push(curLink);
                    }
                    self.$watch("legendItems", function(newValue, oldValue) {self.onRefreshed()}, { deep: true });
                    resolve(graph);                       
                 });               
            },
            onSelectionChanged() {
                this.onRefreshed();
            },
            onRefreshed() {
                var self = this;
                typeFilterCache.updateTypesSettingFromLegend(self.interfaceSettingsName, self.legendItems);
                
                var filteredNodes = [];                
                var filteredLinks = [];
                var tempLinks = [];
                var selectedTypeDict = [];
                var filteredNodesDict = [];
                //collect selected types
                for (var i=0; i< self.legendItems.length; i++) {
                    var curLegend = self.legendItems[i];
                    if (curLegend.selected) {
                        selectedTypeDict[curLegend.title] = curLegend;
                    }
                }
                // filter nodes by selected types
                for (var i=0; i< self.$options.nodes.length; i++) {
                    var curNode = self.$options.nodes[i];
                    if (selectedTypeDict[curNode.documentation]) {
                        filteredNodes.push(curNode);
                        filteredNodesDict[curNode.id] = curNode;
                    }
                    
                }
                // create dict of all child links
                var childDict = [];
                for (var i=0; i< self.$options.links.length; i++) {
                    var curLink = self.$options.links[i];
                    var descItems = []
                    if (childDict[curLink.source]) {
                        descItems = childDict[curLink.source];
                    } else {
                        childDict[curLink.source] = descItems;
                    }

                    descItems.push(curLink);
                }
                // create skipped nodes links
                var newChildDict = [];
                var tempLinks = [];
                for (var i=0; i< filteredNodes.length; i++) {
                    var curNode = filteredNodes[i];
                    if (childDict[curNode.id]) {
                        var childLinks = childDict[curNode.id];
                        for (var j=0; j<childLinks.length; j++) {
                            var childLink = childLinks[j];
                            if (filteredNodesDict[childLink.target]) {
                                // target is also selected - just push to results
                                addLinkToChild(newChildDict, tempLinks, childLink);
                            } else {
                                // must traverse children until filtered
                                processChildren(childDict, filteredNodesDict, newChildDict, tempLinks, curNode.id, childLink.type, curNode.id);
                            }
                        }
                    }

                }
                function processChildren(fullChildDict, filteredNodesDict, newChildDict, linkItems, nodeId, linkType, sourceNodeId) {
                    if (fullChildDict[nodeId]) {
                        var childLinks = fullChildDict[nodeId];
                        for (var i=0; i< childLinks.length; i++) {
                            var curLink = childLinks[i]; 
                            //if (curLink.type== linkType) {
                                if (filteredNodesDict[curLink.target]) {
                                    addLinkToChild(newChildDict, linkItems, constructNewLink(sourceNodeId, curLink.target, linkType) );
                                } else {
                                    processChildren(fullChildDict, filteredNodesDict, newChildDict, linkItems, curLink.target, linkType, sourceNodeId);
                                }
                            //} else {
                            //    // ignore other types
                            //}
                        }
                    } else {
                        // no children, nothing
                    }
                }
                function constructNewLink(source, target, type) {
                    return {
                        source: source,
                        target: target,
                        type: type
                    }
                }
                function addLinkToChild(dict, linkItems, newLink) {
                    var childLinks = [];
                    if (dict[newLink.source]) {
                        childLinks = dict[newLink.source];
                        for (var i=0; i< childLinks.length; i++) {
                            var curLink = childLinks[i];
                            if (curLink.target == newLink.target && curLink.type == newLink.type) {
                                // already there - do nothing
                                return;
                            } 
                        }
                    } else {
                        dict[newLink.source] = childLinks;
                    }
                    childLinks.push(newLink);
                    linkItems.push(newLink);
                }

            
                //for (var i=0; i< self.$options.links.length; i++) {
                //    var curLink = self.$options.links[i];
                for (var i=0; i< tempLinks.length; i++) {
                    var curLink = tempLinks[i];

                    var sourceId = null;
                    var targetId = null;
                    if (typeof curLink.source == "string") {
                        sourceId = curLink.source;
                    } else {
                        sourceId = curLink.source.id;
                    }
                    if (typeof curLink.target == "string") {
                        targetId = curLink.target;
                    } else {
                        targetId = curLink.target.id;
                    }
                    if (filteredNodesDict[sourceId] && filteredNodesDict[targetId]) {
                        var linkObj = {
                            "source" : sourceId,
                            "target" : targetId,
                            "type" : curLink.type
                        }
                        filteredLinks.push(linkObj);
                    }                            
                }
                // prepare for export
                self.$options.nodesForExport = filteredNodes;
                self.$options.linksForExport = filteredLinks;

                var clientWidth = document.getElementById('graphsvg').clientWidth;
                var clientHeight = document.getElementById('graphsvg').clientHeight;
                var width = clientWidth, height = clientHeight;
                var scale = 1.0;

                var zoom = d3.zoom()
                    //.scale(scale)
                    .scaleExtent([0.5, 5])
                    .on("zoom", zoomed);
                var svg = d3.select("#graphsvg");//,
                    //width = +svg.attr("width"),
                    //height = +svg.attr("height");
                svg.selectAll("*").remove();
                svg.call(zoom);
                var container = svg.append("g")
                    .attr("id", "container")
                    .attr("transform", "translate(0,0)scale(1,1)");

                svg.append('defs').append('marker')
                    .attrs({'id':'arrowhead',
                        'viewBox':'-0 -5 10 10',
                        'refX':13,
                        'refY':0,
                        'orient':'auto',
                        'markerWidth':13,
                        'markerHeight':13,
                        'xoverflow':'visible'})
                    .append('svg:path')
                    .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
                    .attr('fill', '#999')
                    .style('stroke','none');
                self.$options.link = container.append("g")
                    .attr("class", "links")
                    .selectAll("line").data(filteredLinks)
                    .enter().append("line")
                    .attr("stroke-width", function(d) { return Math.sqrt(1); })
                    /*
                    .attr('class',function(d) {
                        if (d.type == "FlowRelationship") {
                        return '';
                        }  else {
                        return 'acquireline';
                        }
                    })                            
                    .attr('marker-end',function(d) {
                        if (d.type == "FlowRelationship") {
                        return 'url(#arrowhead)';
                        }  else {
                        return null;
                        }
                        
                    }); *///d.value;
                self.$options.node = container.append("g")
                    .attr("class", "nodes")
                    .selectAll("g")
                    .data(filteredNodes).enter().append("g");
                var circles = self.$options.node.append("circle")
                    .attr("r",  function(d) {
                            if (d.id == self.resourceId) {
                                    return 8;                      
                            } else {
                                return 5; 
                            }
                        })
                    .attr("fill", function(d) {                                                                 
                        return self.getColor(d.documentation, self.color);
                        
                        })        
                    .on("click", function(d){
                                //window.open(d.id, "_top");
                        if (d3.event.ctrlKey) {
                            let routeData = self.$router.resolve({path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                            window.open(routeData.href, '_blank');
                        } else {
                            self.$router.push({ path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                        }
                    });                            
                var labels = self.$options.node.append("text")
                    .text(function(d) {
                        return d.title;
                    })
                    .attr("font-weight",function(d) {
                            if (d.id == self.resourceId) {
                                    return 700;                      
                            } else {
                                return 100; 
                            }
                        })
                    .attr('x', 6)
                    .attr('y', -6)
                    .on("click", function(d){
                        // Determine if current line is visible
                        //alert(d.id);
                                //window.open(d.id, "_top");
                        if (d3.event.ctrlKey) {
                            let routeData = self.$router.resolve({path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                            window.open(routeData.href, '_blank');
                        } else {
                            self.$router.push({ path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                        }
                    });
                    ;      
                self.$options.node.append("title")
                            .text(function(d) { return d.title + "\n("+ d.documentation + ")"; });                                  
                self.$options.simulation = d3.forceSimulation(filteredNodes)
                    .force("charge", d3.forceManyBody().strength(-3000))
                    .force("center", d3.forceCenter(width / 2, height / 2))
                    .force("y", d3.forceY(function(d) {
                        var pos = height / 2;
                        if (d.order) {
                            pos = pos + (d.order * 100);                               
                        }
                        return pos;
                    }).strength(1))
                    .force("x", d3.forceX(function(d) {
                                            var x = 1000;
                                            if (self.objectTypes && self.objectTypes[d.documentation] && self.objectTypes[d.documentation].position) {
                                                x=self.objectTypes[d.documentation].position;
                                            } 
                                            //console.log(d.documentation + x);
                                            return x;
                                        }).strength(3))
                    .force("link", d3.forceLink(filteredLinks).id(function(d) {return d.id; }).distance(50).strength(1))
                    .on("tick", ticked);

                    function ticked() {
                        self.$options.link
                            .attr("x1", function(d) { return d.source.x; })
                            .attr("y1", function(d) { return d.source.y; })
                            .attr("x2", function(d) { return d.target.x; })
                            .attr("y2", function(d) { return d.target.y; });

                        self.$options.node
                            .attr("transform", function(d) {
                                return "translate(" + d.x + "," + d.y + ")";
                            })
                    }
                    function dragstarted(d) {
                        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
                        d.fx = d.x;
                        d.fy = d.y;
                    }

                    function dragged(d) {
                        d.fx = d3.event.x;
                        d.fy = d3.event.y;
                    }

                    function dragended(d) {
                        if (!d3.event.active) simulation.alphaTarget(0);
                        d.fx = null;
                        d.fy = null;
                    }
                    function zoomed() {
                        container.style("stroke-width", 1.5 / d3.event.transform.k + "px");
                        // g.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")"); // not in d3 v4
                        container.attr("transform", d3.event.transform); // updated for d3 v4
                    }
            },
            exportTable() {
                /* convert from array of arrays to workbook */
                var self = this;
                
                
                if (self.legendItems && self.$options.nodesForExport) {
                    var columns = [];

                    for(var i=0; i< self.legendItems.length; i++) {
                        var curLegendItem = self.legendItems[i];
                        if (curLegendItem.selected) {
                            columns.push(curLegendItem.title);
                        }
                    }
                    // single cell worksheet bug mitigation
                    var worksheet = XLSX.utils.aoa_to_sheet(["", "", ""]);
                    worksheet["!ref"] = "A1:A3";
                    var new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
                    var column = 0;                        
                    for(var i=0; i<columns.length;i++) {
                        var curType = columns[i];
                        var row = 0;
                        var columnNodes = [];

                        for(var j=0; j<self.$options.nodesForExport.length; j++) {
                            var curNode = self.$options.nodesForExport[j];
                            if (curNode.documentation == curType) {
                                columnNodes.push(curNode.title);
                            }
                        }
                        
                        if (columnNodes.length>0) {
                            columnNodes.sort();
                            var headerref = XLSX.utils.encode_cell({c:column, r:row});      
                            if(!worksheet[headerref]) {
                                worksheet[headerref] = {};
                                self.add_to_sheet(worksheet,headerref);
                            }
                            worksheet[headerref].t = "s";
                            worksheet[headerref].v = curType;                   
                            row++;
                            for(var j=0; j<columnNodes.length; j++) {
                                var curName = columnNodes[j];
                                var cellref = XLSX.utils.encode_cell({c:column, r:row});      
                                if(!worksheet[cellref]) {
                                    worksheet[cellref] = {};
                                    self.add_to_sheet(worksheet,cellref);
                                }
                                worksheet[cellref].t = "s";
                                worksheet[cellref].v = curName;                   
                                row++;
                            }
                            column++;
                        }
                    }
                    
                    /* output format determined by filename */
                    XLSX.writeFile(new_workbook, 'out.xlsx');
                    /* at this point, out.xlsx will have been downloaded */
                }
                
            },
            range_add_cell(range, cell) {
                var c = typeof cell == 'string' ? XLSX.utils.decode_cell(cell) : cell;
                var rng;
                if (range) {
                    rng = XLSX.utils.decode_range(range);
                } else {
                    var tmpCell = XLSX.utils.encode_cell(c);
                    rng = XLSX.utils.decode_range(tmpCell + ":" + tmpCell);
                }
                //console.log(rng, c);
                if(rng.s.r > c.r) rng.s.r = c.r;
                if(rng.s.c > c.c) rng.s.c = c.c;

                if(rng.e.r < c.r) rng.e.r = c.r;
                if(rng.e.c < c.c) rng.e.c = c.c;
                return XLSX.utils.encode_range(rng);
            },
             add_to_sheet(sheet, cell) {
                sheet['!ref'] = this.range_add_cell(sheet['!ref'], cell);
            
            }
        },
        mounted() {
            //
            this.projectUrl = "/" + this.$route.params.project;
            this.objectTypes =  this.$store.getters.objectTypes
           
            
        }
    }
</script>
