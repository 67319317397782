<template>
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('choose')}}</span>
        <v-container>
           <v-row dense>
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                :label="$t('DISCOBJECT.NAME')"
                v-model="searchPhrase"
                @keydown.enter="search"
                ref="searchphrase"
                dense
              ></v-text-field>
            </v-col>            
            <v-col
              cols="12"
              md="3"
              
            >
                  <v-btn color="primary" block @click="search" right :disabled="initializing || loading">{{$t('search')}}</v-btn>
            </v-col>
            </v-row>
            <v-row dense>
            <v-col
            cols="12"
            >
            <v-select
                v-model="types"
                :items="typeItems"
                :label="$t('DISCOBJECT.TYPE')"
                multiple
                chips
                deletable-chips
                dense
                :disabled="type"
              ></v-select>           
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>      
      <v-card-text>
        <v-container>
         
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="items"    
                class="elevation-1"
                :loading="loading"
                :options.sync="pagination"
                :server-items-length="totalItems"
                @click:row="select"
              >   
                <v-data-footer :items-per-page-options="rowsPerPageItems"  />          
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancel()">{{$t('cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';

export default {
    mounted () {
      var self = this;
      //this.searchPhrase = this.$route.params.searchPhrase;
      //document.title = Vue.i18n.translate('SEARCH.TITLE') + " " +  this.searchPhrase + " - " +  Vue.i18n.translate('APP.TITLE');
      /*
      if (this.searchPhrase) {   
        this.getDataFromApi()
          .then(data => {
            this.items = data.items
            this.totalItems = data.totalItems
          })
      }
      */
     this.getTypeDataFromApi()
        .then(data=> {
          self.initializing = false;
          if (self.type) {
            self.types = data.filter((it) => it==self.type);
          }
        });
      self.setFocus();
    },
    props: ["type", "isShown"],
    data () {
      return {
        headers: [
          {
            text: Vue.i18n.translate('DISCOBJECT.NAME'),
            align: 'left',
            value: 'NAME'
          },
          {
            text: Vue.i18n.translate('DISCOBJECT.TYPE'),
            align: 'left',
            value: 'TYPE'
          }          
        ],
        items: [],
        totalItems: 0,
        loading: false,
        pagination: {
          sortBy: [],
          page: 1,
          itemsPerPage: 10,
          sortDesc: [false],
          multiSort: false
        },
        rowsPerPageItems:[10,25,50],
        searchPhrase:null,
        types: [],
        typeItems: [],
        initializing: true

      }
    },
    watch: {
      pagination: {
        handler () {
          this.search();
        },
        deep: true
      },
      isShown: function(newVal, oldVal) {
        if (newVal != oldVal && newVal) {
          this.setFocus();
        }
      }
    },
    methods: {
      setFocus () {
        var self = this;
        Vue.nextTick(() => {
          setTimeout(() => {
              self.$refs.searchphrase.focus();
            }, 100)
            
          });
      },
      openDetail ($item) {
        this.project = this.$route.params.project;
        this.$router.push({ path: `/${project}/DISCOBJECT/${$item.UUID}`});
      }, 
      select(item) {
        this.$emit('select-item', item);
      },
      cancel() {
        this.$emit('select-item', null);
      },  
      search() {
        this.getDataFromApi()
            .then(data => {
              this.items = data.items
              this.totalItems = data.totalItems
            })
      }  ,
      getDataFromApi () {
        var self = this;
        this.loading = true;        
        return new Promise((resolve, reject) => {
          if (!this.searchPhrase) {
            // empty request, no data
            this.loading = false;  
            resolve({
                    items: [],
                    totalItems: 0
                });
            return;
          }
            const { sortBy, sortDesc, page, itemsPerPage } = self.pagination
            var data={
              searchPhrase: this.searchPhrase
            }
            if (self.types && self.types.length > 0) {
              data.types = self.types;
            }
            var link = "";
            if (sortBy && sortBy.length > 0) {
              link = self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTSearch?paged=1&page='  + page + "&orderby=" + sortBy[0] + "&descending=" + sortDesc[0] + "&rowsperpage=" + itemsPerPage;
            } else {
              link = self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTSearch?paged=1&page='  + page + "&rowsperpage=" + itemsPerPage;
            }
            /*
            if (self.type) {
              link = link + '&type=' + self.type;
            }
            */
            axios.post(link,data,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                resolve({
                    items: response.data.data,
                    totalItems: response.data.total
                });
            });
        
        });        
      },
      getTypeDataFromApi () {
        var self = this;
        this.initializing = true;       
        var typesPromise =  new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTType',{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                if (response && response.data ) {
                  self.typeItems = response.data;
                  resolve(response.data);
                } else {
                  resolve(null);
                }
                
            });
        
        });
        return typesPromise;
      }
    
  }
}
</script>
