<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{$t('SEARCH.RESULTS')}} "{{searchPhrase}}"</v-toolbar-title>
       <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              bottom
              absolute
          ></v-progress-linear>  
      <v-spacer></v-spacer>
       <v-toolbar-items class="hidden-sm-and-down">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn text @click="exportTable" v-on="on">
                    <v-icon>save_alt</v-icon>
                </v-btn>                        
            </template>
            <span>{{$t('export')}}</span>
        </v-tooltip>                                       
      </v-toolbar-items>       
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"    
      class="elevation-1"
      :loading="loading"
      :options.sync="pagination"        
      :server-items-length="totalItems"
    >   
    <v-data-footer :items-per-page-options="rowsPerPageItems"  />
    <template v-slot:body="{ items }">
        <tbody>
          
          <tr v-for="item in items" :key="item.UUID" >
            <td  ><router-link :to="`../DISCOBJECT/${item.UUID}`">{{ item.NAME }}</router-link></td>
            <td >{{ item.TYPE }}</td>
            <td >{{ item.DESCRIPTION }}</td>
          </tr>
        </tbody>
      </template>
  </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import XLSX from 'xlsx';
import Vue from 'vue';

export default {
    mounted () {
      this.searchPhrase = this.$route.params.searchPhrase;
      document.title = Vue.i18n.translate('SEARCH.TITLE') + " " +  this.searchPhrase + " - " +  Vue.i18n.translate('APP.TITLE');
      if (this.searchPhrase) {   
        this.getDataFromApi()
          .then(data => {
            this.items = data.items
            this.totalItems = data.totalItems
          })
      }
    },
    data () {
      return {
        headers: [
          {
            text: Vue.i18n.translate('DISCOBJECT.NAME'),
            align: 'left',
            value: 'NAME'
          },
          {
            text: Vue.i18n.translate('DISCOBJECT.TYPE'),
            align: 'left',
            value: 'TYPE'
          },
          {
            text: Vue.i18n.translate('DISCOBJECT.DESCRIPTION'),
            align: 'left',
            value: 'DESCRIPTION'
          }          
        ],
        items: [],
        totalItems: 0,
        loading: true,
        pagination: {
          sortBy: [],
          page: 1,
          itemsPerPage: 10,
          sortDesc: [false],
          multiSort: false
        },
        rowsPerPageItems:[10,25,50],
        searchPhrase:null
      }
    },
    watch: {
      pagination: {
        handler () {
          this.getDataFromApi()
            .then(data => {
              this.items = data.items
              this.totalItems = data.totalItems
            })
        },
        deep: true
      }
    },
    methods: {
      
      newItem ($item) {
        this.$router.push({ path: `DISCOBJECT/new`});
      },
      openDetail ($item) {
        this.$router.push({ path: `DISCOBJECT/${$item.UUID}`});
      },    
      openDetailNew ($item) {
        let routeData = this.$router.resolve({path: `DISCOBJECT/${$item.UUID}`});
        window.open(routeData.href, '_blank');
      },    
      getDataFromApi () {
        var self = this;
        this.loading = true;        
        return new Promise((resolve, reject) => {
            const { sortBy, sortDesc, page, itemsPerPage } = self.pagination
            var data={
              searchPhrase: this.searchPhrase
            }
            var link = "";
            if (sortBy && sortBy.length > 0) {
              link = self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTSearch?paged=1&page='  + page + "&orderby=" + sortBy[0] + "&descending=" + sortDesc[0] + "&rowsperpage=" + itemsPerPage;
            } else {
              link = self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTSearch?paged=1&page='  + page + "&rowsperpage=" + itemsPerPage;
            }
            axios.post(link,data,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                resolve({
                    items: response.data.data,
                    totalItems: response.data.total
                });
            });
        
        });        
      },
      exportTable() {
        /* convert from array of arrays to workbook */
        var  data = 
            [
              [Vue.i18n.translate('DISCOBJECT.NAME'), Vue.i18n.translate('DISCOBJECT.TYPE'), Vue.i18n.translate('DISCOBJECT.DESCRIPTION') ]
            ]
          ;
        if (this.items) {
          for(var i=0; i<this.items.length; i++) {
            var curItem = this.items[i];
            data.push([curItem.NAME,curItem.TYPE,curItem.DESCRIPTION])
          }
        }
        var worksheet = XLSX.utils.aoa_to_sheet(data);
        var new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
        /* output format determined by filename */
        XLSX.writeFile(new_workbook, 'out.xlsx');
        /* at this point, out.xlsx will have been downloaded */
      }
    
  }
}
</script>
