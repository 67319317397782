<template>
    <div v-if="hasUsage">
        <v-toolbar>
            <v-toolbar-title>{{$t('usage')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn v-if="!isShown" text @click="showUsage">{{$t('showUsage')}}</v-btn>
                <v-btn v-if="isShown" text @click="hideUsage">{{$t('hideUsage')}}</v-btn>
                <v-tooltip bottom v-if="isShown" >
                    <template v-slot:activator="{ on }">
                        <v-btn text @click="exportTable" v-on="on">
                            <v-icon>save_alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span>{{$t('export')}}</span>
                </v-tooltip>    
            </v-toolbar-items>
        </v-toolbar>
        <!--
        <column-chart-component :data="data" v-if="isShown" svgclass="usage-chart-container" />
        -->
        <dashboard-item-component :parameters="parameters" :config="config" v-if="isShown" />
    </div>
</template>

<script>
    import { Promise } from 'q';
    import XLSX from 'xlsx';
    import axios from 'axios';
    import Vue from 'vue';

    export default {
        props: ['resourceName', 'resourceType', 'resourceId', 'field'],
        data () {
            return {
                isShown: false,
                isLoaded: false,
                display: 'none',
                data: null,
                parameters: {
                  projectId: this.$store.getters.currentProject.UUID,
                  objectName: null
                },
                config: null,
                hasUsage: false
            }
        },
        watch: { 
            resourceType: function(newVal, oldVal) { 
                this.evalResourceType(newVal);
            },      
            resourceName: function(newVal, oldVal) { 
                this.parameters.objectName = newVal;
            },         
        },
        methods: {  
            evalResourceType (type) {
                var self = this;
                var objectTypes = this.$store.getters.objectTypes;
                var curObjectType = objectTypes[self.resourceType];
                if ( curObjectType && curObjectType.usageChart ) {
                    self.hasUsage = true;
                } else {
                    self.hasUsage = false;
                }
            },    
            showUsage () {
                var self = this;
                this.isShown = true; 
                this.display  = '';
                if (this.isLoaded) {
                    // just show;
                } else {
                    /*
                    self.loadData(this.resourceType, this.resourceName).then(function(graph) {
                        //self.onRefreshed(graph);
                        self.data=graph;
                    });
                    */
                    var objectTypes = this.$store.getters.objectTypes;
                    var curObjectType = objectTypes[self.resourceType];
                    if ( curObjectType && curObjectType.usageChart ) {
                        Vue.nextTick(() => {
                            self.config = curObjectType.usageChart;
                        });
                        self.isLoaded = true;
                    }
                  
                }
                
            },
            hideUsage () {
                this.isShown = false;
                this.display = 'none';
            },         
            loadData(type, name) {
                var self = this;
                return new Promise((resolve, reject) => {
                    var url=self.$store.getters.currentProjectApiRoot	 + 'msu';
                    if (type && name) {
                        url = url + "/" + type + "/" + name;
                    }
                    axios.get(url, {headers: {
                        "Content-Type":'application/json'  ,
                        'Accept':'application/json'                      
                    }})
                    .then(function(graph) {
                        self.isLoaded = true;
                        resolve(graph.data);                       
                    } , function(err) {
                        reject(err);
                    });    
                  
                 });               
            },
            exportTable() {
                /* convert from array of arrays to workbook */
                var self = this;
                if (self.data) {
                    var  data = 
                    [
                        [Vue.i18n.translate('period'), Vue.i18n.translate('msu')]
                    ];
                    for(var i=0; i<self.data.length; i++) {
                        var curItem = self.data[i];
                        data.push([curItem.name,curItem.value])
                    }
                   
                    var worksheet = XLSX.utils.aoa_to_sheet(data);
                    var new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, Vue.i18n.translate('usage'));
                    /* output format determined by filename */
                    XLSX.writeFile(new_workbook, 'usage.xlsx');
                    /* at this point, out.xlsx will have been downloaded */
                }
                
            }
         },
        mounted() {
            //
            this.evalResourceType();
            
           
            
        }
    }
</script>
