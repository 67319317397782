<template>
    <div style="width: 100%;">
            <svg :id="id" :class="svgClass"></svg>
    </div>
</template>

<script>
    import * as d3 from 'd3';
    import * as zoom from 'd3-zoom';
    import * as multi from 'd3-selection-multi';
    import Vue from 'vue';

    export default {
        props: ['data', 'svgclass'],
        data () {
            return {
                id: null,
                svgClass: "usage-chart-container"
            }
        },
        watch: { 
            data: function(newVal, oldVal) { 
                this.onRefreshed(newVal);
            },
            svgclass: function(newVal, oldVal) {
                if (newVal) {
                    this.svgClass = newVal;
                }
                
            }
        },
         methods: {                 
            onRefreshed(data) {

                var self = this;
                // set the dimensions and margins of the graph
                var clientWidth = document.getElementById(self.id).clientWidth;
                var clientHeight = document.getElementById(self.id).clientHeight;
                var margin = {top: 20, right: 20, bottom: 30, left: 60},
                    width = clientWidth - margin.left - margin.right,
                    height = clientHeight - margin.top - margin.bottom;

                // set the ranges
                var x = d3.scaleBand()
                        .range([0, width])
                        .padding(0.1);
                var y = d3.scaleLinear()
                        .range([height, 0]);
                var svg = d3.select("#" + self.id)
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                .append("g")
                    .attr("transform", 
                        "translate(" + (margin.left + width/2) + "," + (margin.top + height/2) + ")");                        
                svg.selectAll("*").remove();               
                if (!data) {
                    return ;
                }        
                
                const arcs = (d3.pie()
                .startAngle(- Math.PI / 4)
                .endAngle(2* Math.PI - Math.PI / 4)
                .padAngle(0.005)
                .sort(null)
                .value(d => d.value))(data);

                var color = d3.scaleOrdinal()
                .domain(data.map(d => d.name))
                .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), data.length).reverse())
                const radius = Math.min(width, height) / 2;
                var arc = d3.arc().innerRadius(radius * 0.5).outerRadius(radius * 0.8 );
                // Another arc that won't be drawn. Just for labels positioning
                var outerArc = d3.arc().innerRadius(radius * 0.9).outerRadius(radius * 0.9)
                svg.selectAll("path")
                    .data(arcs)
                    .join("path")
                    .attr("fill", d => color(d.data.name))
                    .attr("d", arc)
                    .append("title")
                    .text(d => `${d.data.name}: ${d.data.value}`);
/*
                svg.append("g")
                    .attr("font-family", "sans-serif")
                    .attr("font-size", 12)
                    .attr("text-anchor", "middle")
                    .selectAll("text")
                    .data(arcs)
                    .join("text")
                    .attr("transform", d => `translate(${arc.centroid(d)})`)
                    .call(text => text.append("tspan")
                        .attr("y", "-0.4em")
                        .attr("font-weight", "bold")
                        .text(d => d.data.name))
                    .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.25).append("tspan")
                        .attr("x", 0)
                        .attr("y", "0.7em")
                        .attr("fill-opacity", 0.7)
                        .text(d => d.data.value));
*/
/*
                    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
                    svg
                    .selectAll('allSlices')
                    .data(arcs)
                    .enter()
                    .append('path')
                    .attr('d', arc)
                    .attr('fill', function(d){ return(color(d.data.key)) })
                    .attr("stroke", "white")
                    .style("stroke-width", "2px")
                    .style("opacity", 0.7)
*/
                    // Add the polylines between chart and labels:
                    svg
                    .selectAll('allPolylines')
                    .data(arcs)
                    .enter()
                    .append('polyline')
                        .attr("stroke", "black")
                        .style("fill", "none")
                        .attr("stroke-width", 1)
                        .attr('points', function(d) {
                            var posA = arc.centroid(d) // line insertion in the slice
                            var posB = outerArc.centroid(d) // line break: we use the other arc generator that has been built only for that
                            var posC = outerArc.centroid(d); // Label position = almost the same as posB
                            var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // we need the angle to see if the X position will be at the extreme right or extreme left
                            posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
                            return [posA, posB, posC]
                        })

                    // Add labels
                    svg.append("g")
                    .attr("font-family", "sans-serif")
                    .attr("font-size", 12)
                    .attr("text-anchor", "left")
                    .selectAll("text")
                    .data(arcs)
                    .join("text")
                    //.attr("transform", d => `translate(${arc.centroid(d)})`)
                    .attr('transform', function(d) {
                        var pos = outerArc.centroid(d);
                        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                        if (midangle < Math.PI) {
                             pos[0] = radius * 0.90 ;
                        } else {
                             pos[0] = radius * 1.34 * -1;
                        }                        
                        return 'translate(' + pos + ')';
                    })
                    .call(text => text.append("tspan")
                        .attr("y", "-0.4em")
                        //.attr("font-weight", "bold")
                        .text(d => (d.data.label)?d.data.label : `${d.data.name}: ${d.data.value}`))                    
                    

               
                
            },                       
         },
        mounted() {
            //
            var self = this;
            this.id = "columnChart" + this._uid;
            if (this.svgclass) {
                this.svgClass = this.svgclass;
            }
            Vue.nextTick()
            .then(function () {
                // DOM updated
                self.onRefreshed(self.data);
            })
            
            
           
            
        }
    }
</script>
