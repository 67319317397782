<template>
    <div>

    <v-toolbar>
      <v-toolbar-title>{{$t('interfaces')}}</v-toolbar-title>
      <v-spacer></v-spacer>
         </v-toolbar>
    <v-form v-model="valid" ref="form" lazy-validation >        
      <v-container fluid>
        <v-row>
          <v-col
            cols="5"
            md="5"
          >
            <v-text-field
              :label="$t('DISCOBJECT.NAME')"
              v-model="NAME1" readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="1"
            md="1"
          >
            <v-dialog v-model="dialogSource"  max-width="600px" scrollable>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on">{{$t('choose')}}</v-btn>
              </template>
              <discobject-picker-component v-on:select-item="selectSource($event)" :isShown="dialogSource" />
            </v-dialog>
          </v-col>
          <v-col
            cols="5"
            md="5"
          >
            <v-text-field
              :label="$t('DISCOBJECT.NAME')"
              v-model="NAME2" readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="1"
            md="1"
          >
            <v-dialog v-model="dialogDestination"  max-width="600px" scrollable>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on">{{$t('choose')}}</v-btn>
              </template>
              <discobject-picker-component v-on:select-item="selectDestination($event)" :isShown="dialogDestination"/>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              :label="$t('DISCOBJECT.TYPE')"
              v-model="TYPE1" readonly
            ></v-text-field>
          </v-col>
        
         
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              :label="$t('DISCOBJECT.TYPE')"
              v-model="TYPE2" readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            md="6"
          >
            <v-select
              v-model="interfaceTypes"
              :items="typeItems"
              :label="$t('interfaceTypes.label')"
              multiple
              chips
              deletable-chips
              :hint="$t('interfaceTypes.hint')"
              persistent-hint
            ></v-select>  
          </v-col>
        </v-row>
        <v-row v-if="!initializing">
          <v-col
            cols="12"
          >
            <v-btn color="primary" @click="findInterfaces" >{{$t('findInterfaces')}}</v-btn>
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
            ></v-progress-linear>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <relations2-chart-component :chartData="chartData" />
          </v-col>
        </v-row>

      </v-container>
    <!--
    <interfaces-chart-component :resourceId1="UUID1" :resourceId2="UUID2" ></interfaces-chart-component>
  -->

    <!--
    <v-btn @click="deleteItem" color="error">{{$t('deleteitem')}}</v-btn>
    -->
  </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue';

  export default {
    data: () => ({
      loading: false,
      initializing : false,
      saving: false,
      dialogSource: false,
      dialogDestination: false,
      valid: true,
      NAME1: null,
      TYPE1:null,
      NAME2: null,
      TYPE2:null,
      UUID1: null,
      UUID2: null,
      chartData: null,
      typeItems: [],
      objectTypes: [],
      interfaceTypes: []
    }),
    mounted () {
      var self = this;
      document.title = Vue.i18n.translate('interfaces') + " - " +  Vue.i18n.translate('APP.TITLE');    
      self.objectTypes =  self.$store.getters.objectTypes; 
      this.getTypeDataFromApi()
      .then(data=> {
          self.initializing = false;
          for (var i=0; i<self.typeItems.length; i++) {
            var curType = self.typeItems[i];
            if (self.objectTypes && self.objectTypes[curType] && self.objectTypes[curType].interface) {
              self.interfaceTypes.push(curType);
            }
          }
          
      });
    },
    watch: {      
    },
    methods: {
      selectSource (item) {
        this.dialogSource = false;
        if(item) {
          this.NAME1 = item.NAME;
          this.TYPE1 = item.TYPE;
          this.UUID1 = item.UUID;
        }
      },
      selectDestination (item) {
        this.dialogDestination = false;
        if(item) {
          this.NAME2 = item.NAME;
          this.TYPE2 = item.TYPE;
          this.UUID2 = item.UUID;
        }
      },
      findInterfaces () {
        var self = this;
        if (this.UUID1 && this.UUID2) {        
          this.getDataFromApi()
            .then(data => {
              if (data) {
                self.chartData = data;
                //document.title = this.NAME + " - " +  Vue.i18n.translate('APP.TITLE');
              }
            });
        

        } else {
          
        }
      },
      cancel () {
        var self = this;
        self.$router.push({ path: `DISCOBJECT/`}); 
      },
      getDataFromApi () {
        var self = this;
        this.loading = true;
        

        return new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'NEOINTERFACE/' + this.UUID1 + "/" + this.UUID2 + "?interfacetypes=" + encodeURIComponent(self.interfaceTypes.join(",")),{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                if (response && response.data ) {
                  resolve(response.data);
                } else {
                  resolve(null);
                }
                
            });
        
        });
      },
      getTypeDataFromApi () {
        var self = this;
        this.initializing = true;       
        var typesPromise =  new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTType',{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                if (response && response.data ) {
                  self.typeItems = response.data;
                  resolve(response.data);
                } else {
                  resolve(null);
                }
                
            });
        
        });
        return typesPromise;
      },
      
    }
  }
</script>
