<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{$t('SEARCH.SOURCE.TITLE')}}</v-toolbar-title>
      <v-progress-linear
        :active="initializing"
        :indeterminate="initializing"
        bottom
        absolute
      ></v-progress-linear> 
    </v-toolbar>

     <v-form v-model="valid" ref="form" lazy-validation >        
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              :label="$t('DISCOBJECT.NAME')"
              v-model="searchPhrase"
              clearable
               type="text"
              @keydown.enter="search"
            ></v-text-field>           
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
           <v-select
              v-model="types"
              :items="typeItems"
              :label="$t('DISCOBJECT.TYPE')"
              multiple
              chips
              deletable-chips
            ></v-select>           
          </v-col>
          
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              :label="$t('nameFilter')"
              v-model="nameFilter" 
              clearable
              type="text"
              @keydown.enter="search"
              :rules="[rules.regex]"
            ></v-text-field>
          </v-col>    
          <v-col
            cols="4"
            md="4"
          >
            <v-select
              v-model="statusses"
              :items="statusItems"
              item-text="title"
              item-value="id"
              :label="$t('DISCOBJECT.STATUS')"
              multiple
              chips
              deletable-chips
              persistent-hint
            ></v-select>           
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-switch v-model="includeComments" :label="$t('includeComments')"></v-switch>    
          </v-col>
        </v-row> 
        <v-row>
          <v-col
            cols="12"
          >
            <v-btn color="primary" @click="search" >{{$t('search')}}</v-btn>
          </v-col>
        </v-row>
      </v-container>
     </v-form>
     <v-toolbar>
      <v-toolbar-title>{{$t('SEARCH.RESULTS')}}</v-toolbar-title>
       <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              bottom
              absolute
          ></v-progress-linear>  
      <v-spacer></v-spacer>
       <v-toolbar-items class="hidden-sm-and-down">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn text @click="exportTable" v-on="on">
                    <v-icon>save_alt</v-icon>
                </v-btn>                        
            </template>
            <span>{{$t('export')}}</span>
        </v-tooltip>                                       
      </v-toolbar-items>       
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"    
      class="elevation-1"
      :loading="loading"
      :options.sync="pagination"        
      :server-items-length="totalItems"
    >   
    <v-data-footer :items-per-page-options="rowsPerPageItems"  />
    <template v-slot:body="{ items }">
        <tbody>
          
          <tr v-for="item in items" :key="item.UUID" >
            <td  ><router-link :to="`DISCOBJECT/${item.UUID}`">{{ item.NAME }}</router-link></td>
            <td >{{ item.TYPE }}</td>
            <td >{{ item.statusName }}</td>
            <td class="highlighted" v-html="item.highlightValue"></td>
          </tr>
        </tbody>
      </template>
  </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import XLSX from 'xlsx';
import Vue from 'vue';

export default {
    mounted () {
      var self = this;
      this.searchPhrase = this.$route.params.searchPhrase;
      document.title = Vue.i18n.translate('SEARCH.SOURCE.TITLE') + ' - '+  Vue.i18n.translate('APP.TITLE');
      self.statusItems = self.$store.getters.statusses;
      this.getTypeDataFromApi()
        .then(data=> {
          self.initializing = false;
        });
      if (this.searchPhrase) {   
        this.getDataFromApi()
          .then(data => {
            this.items = data.items
            this.totalItems = data.totalItems
          })
      }
    },
    data () {
      return {
        headers: [
          {
            text: Vue.i18n.translate('DISCOBJECT.NAME'),
            align: 'left',
            value: 'NAME'
          },
          {
            text: Vue.i18n.translate('DISCOBJECT.TYPE'),
            align: 'left',
            value: 'TYPE'
          },
          {
            text: Vue.i18n.translate('DISCOBJECT.STATUS'),
            align: 'left',
            value: 'STATUS'
          },
          {
            text: Vue.i18n.translate('SEARCH.highlight'),
            align: 'left',
            value: 'highlightValue'
          }          
        ],
        items: [],
        totalItems: 0,
        loading: false,
        initializing: true,
        loaded: false,
        pagination: {
          sortBy: [],
          page: 1,
          itemsPerPage: 10,
          sortDesc: [false],
          multiSort: false
        },
        rowsPerPageItems:[10,25,50],
        searchPhrase:null,
        types: [],
        typeItems: [],
        statusses: [],
        statusItems: [],
        nameFilter: null,
        includeComments: false,
        rules: {
          regex: value => {
            try {
              var re = new RegExp(value);
              return true;
            }
            catch (ex) {
              return false;
            }
          },
        },
        valid:true
      }
    },
    watch: {
      pagination: {
        handler () {
          var self = this;
          if (self.loaded) {
            this.getDataFromApi()
              .then(data => {
                this.items = data.items
                this.totalItems = data.totalItems
              })
          }
        },
        deep: true
      }
    },
    methods: {
      search() {
        this.getDataFromApi()
            .then(data => {
              this.loaded = true;
              this.items = data.items
              this.totalItems = data.totalItems
            })
      },
      openDetail ($item) {
        this.$router.push({ path: `DISCOBJECT/${$item.UUID}`});
      },    
      openDetailNew ($item) {
        let routeData = this.$router.resolve({path: `DISCOBJECT/${$item.UUID}`});
        window.open(routeData.href, '_blank');
      },    
      getDataFromApi (nopaging) {
        var self = this;
        this.loading = true;        
        return new Promise((resolve, reject) => {
            const { sortBy, sortDesc, page, itemsPerPage } = self.pagination
            var data={
              searchPhrase: self.searchPhrase,
              nameFilter: self.nameFilter,
              rule: (self.includeComments) ? "source" : "source_no_comments",
              types: self.types,
              statusses: self.statusses
            }
            var link = "";
            var pageparams = "";
            if (!nopaging) {
              pageparams = 'paged=1&page='  + page + "&rowsperpage=" + itemsPerPage;
            }
            if (sortBy && sortBy.length > 0) {
              link = self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTSearch?' + pageparams + "&orderby=" + sortBy[0] + "&descending=" + sortDesc[0];
            } else {
              link = self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTSearch?'+ pageparams;
            }
            axios.post(link,data,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                var statusDict = self.$store.getters.statusById;
                if (nopaging) {
                  resolve({
                    items: response.data.map(it => { it.statusName = statusDict[it.STATUS] ? statusDict[it.STATUS].title : it.name; return it; }),
                    totalItems: response.data.length
                  });
                } else {
                  resolve({
                    items: response.data.data.map(it => { it.statusName = statusDict[it.STATUS] ? statusDict[it.STATUS].title : it.name; return it; }),
                    totalItems: response.data.total
                });
                }
                
            });
        
        });        
      },
      getTypeDataFromApi () {
        var self = this;
        this.initializing = true;       
        var typesPromise =  new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTType',{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                if (response && response.data ) {
                  self.typeItems = response.data;
                  resolve(response.data);
                } else {
                  resolve(null);
                }
                
            });
        
        });
        return typesPromise;
      },
      exportTable() {
        /* convert from array of arrays to workbook */
        this.getDataFromApi(true)
              .then(result => {                
                var  data = 
                    [
                      [Vue.i18n.translate('DISCOBJECT.NAME'), Vue.i18n.translate('DISCOBJECT.TYPE')
                      , Vue.i18n.translate('DISCOBJECT.STATUS'), Vue.i18n.translate('SEARCH.highlight') ]
                    ]
                  ;
                if (result.items) {
                  for(var i=0; i<result.items.length; i++) {
                    var curItem = result.items[i];                    
                    var highlight = curItem.highlightValue;
                    var formated_highlight = highlight;
                    if (highlight) {
                      formated_highlight = highlight.replace(/<em>/g,'').replace(/<\/em>/g,'').replace(/<br \/>/g,'\n');
                    }
                    data.push([curItem.NAME,curItem.TYPE,curItem.statusName,formated_highlight])
                  }
                }
                var worksheet = XLSX.utils.aoa_to_sheet(data);
                var new_workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
                /* output format determined by filename */
                XLSX.writeFile(new_workbook, 'out.xlsx');
                /* at this point, out.xlsx will have been downloaded */
              })
        
      }
    
  }
}
</script>
