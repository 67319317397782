<template>
    <div>
        <v-toolbar>
            <v-toolbar-title>{{$t('invocationsChart')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn v-if="!isShown" text @click="showChart">{{$t('showInvocations')}}</v-btn>
                <v-btn v-if="isShown" text @click="hideChart">{{$t('hideInvocations')}}</v-btn>
                <v-tooltip bottom v-if="isShown" >
                    <template v-slot:activator="{ on }">
                        <v-btn text @click="exportTable" v-on="on">
                            <v-icon>save_alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span>{{$t('export')}}</span>
                </v-tooltip>                 
            </v-toolbar-items>
        </v-toolbar>
        <column-chart-component :data="data" v-if="isShown" />   
    </div>
</template>

<script>
import { Promise } from 'q';
import XLSX from 'xlsx';
import axios from 'axios';
import Vue from 'vue';

export default {
        props: ['resourceName', 'resourceType','resourceId', 'field', 'expanded'],
        data () {
            return {
                isShown: false,
                isLoaded: false,
                display: 'none',
                data: null,
            }
        },
        methods: {      
            showChart () {
                var self = this;
                this.isShown = true; 
                this.display  = '';
                if (this.isLoaded) {
                    // just show;
                } else {
                    self.loadData(this.resourceType, this.resourceName).then(function(graph) {
                        self.data=graph;
                    });
                }
            },
            hideChart () {
                this.isShown = false;
                this.display = 'none';
            },
            loadData(type, name) {
                var self = this;
                return new Promise((resolve, reject) => {                   
                    axios.get(self.$store.getters.currentProjectApiRoot	 + 'invocation/' + type + "/" + name, {headers: {
                        "Content-Type":'application/json',
                        'Accept':'application/json',
                    }})
                    .then(function(graph) {
                        self.isLoaded = true;     
                        resolve(graph.data);                       
                    } , function(err) {
                        reject(err);
                    });                      
                 });               
            },                 
            
            exportTable() {
                /* convert from array of arrays to workbook */
                var self = this;
                if (self.data) {
                    var  data = 
                    [
                        [Vue.i18n.translate('period'), Vue.i18n.translate('callCount')]
                    ];
                    for(var i=0; i<self.data.length; i++) {
                        var curItem = self.data[i];
                        data.push([curItem.name,curItem.value])
                    }
                   
                    var worksheet = XLSX.utils.aoa_to_sheet(data);
                    var new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, Vue.i18n.translate('invocationsChart'));
                    /* output format determined by filename */
                    XLSX.writeFile(new_workbook, 'invocations.xlsx');
                    /* at this point, out.xlsx will have been downloaded */
                }
                
            }
         },

        mounted() {
            //
            if (this.expanded) {
                this.showChart();
            }
            
           
            
        }
    }
</script>
