<template>
    <v-card class="elevation-12">
        <v-toolbar dark color="primary">
        <v-toolbar-title>
             <span class="hidden-sm-and-down">
                <img src="exconma.png" style="margin-top: -20px; height: 60px;"> 
                Exconma Discoverer
            </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>        
        </v-toolbar>
        <v-card-text>
        <v-form>
            <v-text-field prepend-icon="person" name="login"  v-bind:label="$t('email')" type="text" v-model="formLogin.email" v-on:keyup="onLoginKeyUp" ></v-text-field>
            <v-text-field prepend-icon="lock" name="password" v-bind:label="$t('password')" id="password" type="password" v-model="formLogin.password" v-on:keyup="onLoginKeyUp"></v-text-field>
        </v-form>
        <v-alert type="error" v-if="error">
            {{$t(error)}}
        </v-alert>
        </v-card-text>
        <v-card-actions>
             <v-progress-linear
              :active="inprogress"
              :indeterminate="inprogress"
              bottom
              absolute
          ></v-progress-linear>  
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="authenticate" v-bind:disabled="inprogress">Login</v-btn>
        <v-btn color="accent" @click="authenticateMS" v-bind:disabled="inprogress" v-if="allowAzureAuth">Login with Microsoft Account</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import {login} from '../partials/auth';
export default {
    data() {
        return {
            formLogin: {
                email: '',
                password: ''
            },
            error: null,
            inprogress: null,
            allowAzureAuth: false
        }
    },
    mounted() {
        this.allowAzureAuth = this.$configuration.allowAzureAuth;
    },
    methods:{
        authenticate(){
            var self = this;
            this.inprogress =true;
            this.error = null;
            this.$store.dispatch('login');
            login(this.$data.formLogin)
            .then(res => {
                this.$store.commit("loginSuccess", res);
                this.error = null;
                //this.inprogress =null;
                if (self.$route.query.source) {
                    this.$router.push({path: self.$route.query.source});    
                } else {
                    this.$router.push({path: '/projects'});
                }
                
            })
            .catch(error => {
                this.$store.commit("loginFailed", {error});
                this.error = error;
                this.inprogress =null;
            })
        },
        authenticateMS(){
            var self = this;
            this.inprogress =true;
            this.error = null;
            if (sessionStorage) {
                sessionStorage.setItem("discovererAuthRedirectUrl", '');
            }
            if (self.$route.query.source) {
                if (sessionStorage) {
                    sessionStorage.setItem("discovererAuthRedirectUrl", self.$route.query.source);
                }
                window.location = 'auth/redirect?source=' + encodeURIComponent(self.$route.query.source);
            } else {
                window.location = 'auth/redirect';
            }
        },
        onLoginKeyUp: function(e) {
            if (e.keyCode === 13) {
               this.authenticate();
            }      

        }
    },
    computed:{
        authError(){
            return this.$store.getters.authError
        },
        registeredUser(){
            return this.$store.getters.registeredUser
        }
    }

}
</script>