<template>
    <div>

    <v-toolbar>
      <v-toolbar-title>{{$t('accessBrowser')}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form v-model="valid" ref="form" lazy-validation >        
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              :label="$t('DISCOBJECT.NAME')"
              v-model="NAME1" readonly
              clearable
              @click:clear="clear"
              :append-outer-icon="'folder_open'"
              @click:append-outer.stop="dialogSource=true"
            ></v-text-field>
            <v-dialog v-model="dialogSource" max-width="600px" scrollable>             
              <discobject-picker-component v-on:select-item="selectSource($event)" :type="TYPE" :isShown="dialogSource"/>
            </v-dialog>
          </v-col>
          <!--
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              :label="$t('nameFilter')"
              v-model="nameFilterTemp" 
              :append-outer-icon="(nameFilterApplied != nameFilterTemp) ? 'send' : ''"
              clearable
              type="text"
              @click:append-outer="applyNameFilter"
              @click:clear="clearNameFilter"
              @keydown.enter="applyNameFilter"
              :rules="[rules.regex]"
            ></v-text-field>
          </v-col>   
          -->     
        </v-row>               
        <v-row>
          <v-col
            cols="12"
          >
            <div class="relations-container-inner">
              <v-toolbar style="flex-grow: 0;flex-shrink: 0;">
                <v-toolbar-title>{{$t('tangledChart')}}</v-toolbar-title>
                <v-progress-linear
                  :active="loading"
                  :indeterminate="loading"
                  absolute
                  bottom
                ></v-progress-linear>
                <v-spacer></v-spacer>
                <v-toolbar-items class="hidden-sm-and-down">
                    <v-tooltip bottom v-if="!loading" >
                        <template v-slot:activator="{ on }">
                            <v-btn text @click="exportGraph" v-on="on"  >
                                <v-icon>save_alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span>{{$t('export')}}</span>
                    </v-tooltip>  
                </v-toolbar-items>
              </v-toolbar>
              <v-container fluid class="relations-container">            
                <v-row >
                  <v-col cols="12" >                    
                    <tangled-chart-component :expanded="true" :levels="filteredLevels" :resourceId="UUID1" :availableNodesDict="availableNodesDict" 
                      ref="tangledChart" v-on:node-click="changeDetail" v-on:node-ctrl-click="onCtrlClick" :style="{ display: (loading) ? 'none' : ''}"
                      />                    
                  </v-col>
                </v-row>
              </v-container>  
            </div>     
          </v-col>
        </v-row>

      </v-container>    
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import * as levelsHelper from '../../partials/levels.js'
import Vue from 'vue';

  export default {
    data: () => ({
      loading: false,
      saving: false,
      dialogSource: false,
      dialogDestination: false,
      valid: true,
      NAME1: null,
      TYPE1:null,
      NAME2: null,
      TYPE2:null,
      UUID1: null,
      UUID2: null,
      TYPE: null,
      OPTYPE: null,
      chartResourceId: null,
      nameFilterTemp: null,
      nameFilterApplied: null,
      availableNodesDict: null,
      rules: {
          regex: value => {
            //const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            //return pattern.test(value) || 'Invalid e-mail.'
            try {
              var re = new RegExp(value);
              return true;
            }
            catch (ex) {
              return false;
            }
          },
        },
      detailDialog: false,
      detailTitle: null,
      detailLoading: false, 
      levels: null, 
      filteredLevels: null,
      detailLevels: null,
    }),
    mounted () {
      document.title = Vue.i18n.translate('accessBrowser') + " - " +  Vue.i18n.translate('APP.TITLE');     
      /*
      this.TYPE = this.$route.params.TYPE;
      if (this.$route.params.OPTYPE) {
        this.OPTYPE = this.$route.params.OPTYPE;
      }
      */
     if (this.$route.query.uuid) {
        this.UUID1 = this.$route.query.uuid;
        this.showChart();
      }
    },
    watch: {      
    },
    methods: {
      selectSource (item) {
        this.dialogSource = false;
        if(item) {
          this.NAME1 = item.NAME;
          this.TYPE1 = item.TYPE;
          this.UUID1 = item.UUID;
          //this.findInterfaces();
          this.showChart();
        }
      },
      selectDestination (item) {
        this.dialogDestination = false;
        if(item) {
          this.NAME2 = item.NAME;
          this.TYPE2 = item.TYPE;
          this.UUID2 = item.UUID;
        }
      },      
      cancel () {
        var self = this;
        self.$router.push({ path: `DISCOBJECT/`}); 
      },
      clear () {
        var self = this;
        self.UUID1 = null;
        self.NAME1 = null;
        self.TYPE1 = null; 
        this.showChart();
      },      
      applyNameFilter () {
        var self = this;
        self.nameFilterApplied = self.nameFilterTemp;
        self.showChart();
      },
      clearNameFilter () {
        var self = this;
        self.nameFilterTemp = null;
        self.nameFilterApplied = self.nameFilterTemp;
        self.showChart();
      },
      exportGraph() {
          var self = this;
          self.$refs.tangledChart.exportGraph();
      },
      exportGraphDetail() {
          var self = this;
          self.$refs.detailTangledChart.exportGraph();
      },
      showChart () {                
          var self = this;          
          this.display  = 'flex';
          self.loadData().then(function(graph) {
              self.onRefreshed();
          });
      },
      loadData() {
        var self = this;
        self.loading = true;
        
        return new Promise((resolve, reject) => {
          axios.get(self.$store.getters.currentProjectApiRoot	 + 'NEO/' + self.UUID1 +"/access",{
            headers: {
                        'Content-Type': 'application/json',
                        'Accept':'application/json',
                    }
          })
          .then(function(response) {
              
              self.isLoaded = true;
              
              if (response && response.data) {
                var nodes = response.data.nodes;
                var edges = response.data.links;
                var nodeDict = [];
                var levels = [];
                levels[0] = [];
                levels[1] = [];
                levels[2] = [];
                nodes.forEach(n => {
                  nodeDict[n.id] = n;
                  n.parents = [];
                  n.linkTypeDict = [];
                                
                });
                var level0Dict = [];
                var level1Dict = [];
                var level2Dict = [];

                if (self.UUID1 && nodeDict[self.UUID1]) {
                  var selectedNode = nodeDict[self.UUID1];
                  self.NAME1 = selectedNode.title;
                  self.TYPE1 = selectedNode.documentation;
                  level1Dict[selectedNode.id] = selectedNode;
                  edges.forEach(e => {
                    if (e.source==selectedNode.id) {
                      selectedNode.accessor = true;
                    }
                    if (e.linktype == "INSERT" || e.linktype == "CREATE") {
                      if (e.source==selectedNode.id) {
                        selectedNode.creator = true;
                        var target = nodeDict[e.target];
                        if (target) {
                          target.creation = true;
                        }
                      } else {
                        selectedNode.created = true;
                        var source = nodeDict[e.source];
                        if (source) {
                          source.creation = true;
                        }
                      }
                    }                    
                  });
                  edges.forEach(e => {
                    if (e.source==selectedNode.id) {                      
                      var target = nodeDict[e.target];
                      if (target) {
                        if (target.creation) {
                          level2Dict[target.id] = target;
                        } else {
                          level0Dict[target.id] = target;
                        }
                        target.linkTypeDict[e.linktype] = e.linktype;
                      }
                    } else {
                      var source = nodeDict[e.source];
                      if (source) {
                        if (source.creation) {
                          level0Dict[source.id] = source;
                        } else {
                          level2Dict[source.id] = source;
                        }
                        source.linkTypeDict[e.linktype] = e.linktype;
                      }
                    }
                  });   
                  for(var id in level0Dict) {
                    var curNode = level0Dict[id];
                    selectedNode.parents.push(id);
                    levels[0].push(curNode);
                    var linkTypes = [];
                    for(var lt in curNode.linkTypeDict) {
                      linkTypes.push(lt);
                    }
                    linkTypes.sort((a,b) => {if (a<b) {return -1;} else if (a>b) {return 1;} else {return 0;}});
                    curNode.subtitle = linkTypes.join(",");
                  }
                  levels[0].sort((a,b) => {if (a.title<b.title) {return -1;} else if (a.title>b.title) {return 1;} else {return 0;}});                  
                  for(var id in level1Dict) {
                    var curNode = level1Dict[id];                    
                    levels[1].push(curNode);
                    
                  }  
                  for(var id in level2Dict) {
                    var curNode = level2Dict[id];
                    curNode.parents.push(selectedNode.id);
                    levels[2].push(curNode);
                    var linkTypes = [];
                    for(var lt in curNode.linkTypeDict) {
                      linkTypes.push(lt);
                    }
                    linkTypes.sort((a,b) => {if (a<b) {return -1;} else if (a>b) {return 1;} else {return 0;}});
                    curNode.subtitle = linkTypes.join(",");
                  }
                  levels[2].sort((a,b) => {if (a.title<b.title) {return -1;} else if (a.title>b.title) {return 1;} else {return 0;}});                  
                }
              }
              self.levels = levels;
              self.loading = false;
              resolve(levels);      
                              
          } , function(err) {
              self.loading = false;
              reject(self.levels);
            });    
            
          });               
      },
      loadDetailData(id) {
          var self = this;
          self.detailLoading = true;
          return new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'NEOFLOW2/' + id + "?type=" + self.TYPE + "&optype=" + self.OPTYPE , {
              headers: {
                          'Content-Type': 'application/json',
                          'Accept':'application/json',
                      }
            })
            .then(function(graph) {
                self.detailLoading = false;
                self.detailLevels = graph.data.levels;
                resolve(graph);      
                                
            } , function(err) {
                self.detailLoading = false;
                reject(err);
            });    
              
          });               
      },
      onRefreshed() {
        var self = this;
        levelsHelper.filterLevels(self.levels, self.UUID1, self.nameFilterApplied, 
            (filteredLevels, availableNodesDict, availableNodesExtendedDict) => {
                self.availableNodesDict = availableNodesDict;
                self.filteredLevels = filteredLevels;
            });
      },
      onCtrlClick (node) {
          var self = this;
          if (node) {
              var targetId = node.id;
              if (node.subid) {
                  targetId = node.subid;
              }
              let routeData = self.$router.resolve({path: `DISCOBJECT/${targetId}`});
              window.open(routeData.href, '_blank');
          }
          
      },
    
      changeDetail(node) {
          var self = this;          
          if (node ) {
              this.$router.push({ path: `access?uuid=${node.id}`});             
          }
      },      
    }
  }
</script>
