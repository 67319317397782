
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
//window.Vue = require('vue');
//window.Vuetify = require('vuetify');
import Vue from 'vue';
import Vuetify from 'vuetify';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';
import storeData from './store.js';
import axios from 'axios';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import {refreshToken} from './partials/auth';
import {getProjectByUrl} from './partials/project';

Vue.use(Vuex);
const store = new Vuex.Store(storeData);
Vue.use(vuexI18n.plugin, store);
Vue.use(Vuetify);
Vue.use(VueRouter);


const translationsEn = {
    "name": "Name",
    "email": "E-mail",
    "password": "Password",
    "required": "Required field",
    "lengthlessthan": "Must be less than {length} characters",
    "lengthequalsto": "Must be {length} characters",
    "passwordsNotTheSame": "Password values are not the same",
    "validemail": "E-mail must be valid",
    'submititem' : "Submit",
    'cancelitem' : "Cancel",
    'deleteitem' : "Delete",
    'newitem' : "New item",
    'edititem' : "Edit",
    'resetPassword' : "Reset Password",
    'back' : "Back",
    'showRelations' : "Show relations",
    'hideRelations' : "Hide relations",
    'showFlow' : "Show flow",
    'hideFlow' : "Hide flow",
    'showSource' : "Show source",
    'hideSource' : "Hide source",
    'showInNewWindow' : "Show in new window",
    'downloadSource' : "Download source",
    'openGit' : "Open Git",
    'source' : "Source",
    'search' : "Search",
    'choose' : "Choose",
    'clear' : "Clear",
    'usage' : "Usage",
    'showUsage' : "Show usage",
    'hideUsage' : "Hide usage",    
    'showInvocations' : "Show invocations",
    'hideInvocations' : "Hide invocations",    
    'relationsChart' : "Relations Chart",
    'invocationsChart' : "Invocations Chart",
    'tangledChart' : "Sequence Chart",
    'browser' : "Browser",
    'nameFilter' : "Name Filter",
    'accessBrowser' : "Access Browser",
    'links' : "Links",
    'attributes' : "Attributes",
    'loading' : "loading",
    'copyLinkToClipboard' :"Copy link to clipboard",
    'copyNameToClipboard' :"Copy name to clipboard",
    'openPreview' :"Preview",
    'objectDetails' : "Object Details",
    'includeComments' : "Include Comments",
    'DISCOBJECT.NAME' : "Name",
    'DISCOBJECT.DESCRIPTION' : "Description",
    'DISCOBJECT.TYPE' : "Type",
    'DISCOBJECT.STATUS' : "Status",
    'DISCOBJECT.LINK_TYPE' : "Link Type",
    'DISCOBJECT.LAST_INVOCATION_DATE' : "Last Invocation Date",
    'DISCOBJECT.LIST' : "Resources",
    'DISCOBJECT.typeChart' : "Object Types",
    'DISCOBJECT.typeTable' : "Type Table",
    'EDISCOBJECT.type' : "Type",
    'EDISCOBJECT.count' : "Count",
    'SEARCH.TITLE' : "Search",
    'SEARCH.RESULTS' : "Search results for",
    'SEARCH.highlight' : "Highlight",
    'SEARCH.SOURCE.TITLE' : "Search source",
    'STATUS.UPDATE.TITLE' : "Update status",
    'STATUS.UPDATE.status.label' : "Observed resource status",
    'STATUS.UPDATE.status.hint' : "What resource statusses would be selected?",
    'STATUS.UPDATE.type.label' : "Observed resource type",
    'STATUS.UPDATE.type.hint' : "What resources would be selected?",
    'STATUS.UPDATE.targetStatus.label' : "Target resource status",
    'STATUS.UPDATE.targetStatus.hint' : "What resource status would be set?",
    'STATUS.UPDATE.ignoreParentLinks.label' : "Any parent links",
    'STATUS.UPDATE.noParentLinks.label' : "Without parent links",
    'STATUS.UPDATE.hasParentLinks.label' : "With parent links",
    'STATUS.UPDATE.parentTypes.label' : "Parent resource type",
    'STATUS.UPDATE.parentTypes.hint' : "What parent resource links would be evaluated?",  
    'STATUS.UPDATE.ignoreLastInvocation.label' : "Ignore invocations",
    'STATUS.UPDATE.lowerLastInvocation.label' : "Last invocation before",
    'STATUS.UPDATE.higherLastInvocation.label' : "Last invocation after",  
    'STATUS.UPDATE.lastInvocationDate.label' : "Last invocation date",  
    'STATUS.UPDATE.noLastInvocationDate.label' : "Without invocation date",  
    'STATUS.UPDATE.process' : "Process",
    'STATUS.UPDATE.results' : "Results",
    'STATUS.UPDATE.results.title' : "Title",
    'STATUS.UPDATE.results.value' : "Value",
    'ATTRIBUTE.NAME' : "Name",
    'ATTRIBUTE.DESCRIPTION' : "Description",
    'ATTRIBUTE.TYPE' : "Type",
    'ATTRIBUTE.VALUE' : "Value",    
    'APP.TITLE' : "Exconma Discoverer",
    'interfaces' : "Interfaces",
    'interfaceTypes.label' : "Interface object types",
    'interfaceTypes.hint' : "What resources would be used as interface",
    'findInterfaces' : "Find interfaces",
    'DEADCODE.title' : "Dead code detection ",
    'DEADCODE.child.label' : "Observed resource type",
    'DEADCODE.child.hint' : "What resources would be selected?",
    'DEADCODE.linkType.label' : "Missing link types",
    'DEADCODE.linkType.hint' : "What link types would be missing for dead code?",
    'DEADCODE.parent.label' : "Parent resource type",
    'DEADCODE.parent.hint' : "What resource counterparts are evaluated?",
    'DEADCODE.status.label' : "Observed resource status",
    'DEADCODE.status.hint' : "What resource statusses would be selected?",
    'export' : "Export",
    'period' : "Period",
    'count' : "Count",
    'callCount' : "Call count",
    'msu' : "MSU per month",
    'invocationsPerMonth' : "Invocations per month",
    'userDetails' : "User Details",
    'User.name' : "Name",
    'User.email' : "Email",
    'User.password' : "Password",
    'User.passwordRepeat' : "Repeat Password",
    'User.LIST' : "Users",
    'User.roles' : "Roles",
    'STATUS.ENFORCED' : "Enforced",
    'STATUS.ACTIVE' : "Active",
    'STATUS.INACTIVE' : "Inactive",
    'STATUS.DISCONNECTED' : "Disconnected",
    'STATUS.DISABLED' : "Disabled",
    'STATUS.DELETED' : "Deleted",
    'PROJECT.NAME' : "Name",
    'PROJECT.DESCRIPTION' : "Description",
    'OTHERS' : "OTHERS",
    

    
};


// add translations directly to the application
Vue.i18n.add('en', translationsEn);

// set the start locale to use
Vue.i18n.set('en');
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
var appC = Vue.component('app-component', require('./components/App.vue').default);
Vue.component('application-layout', require('./components/layouts/ApplicationLayoutComponent.vue').default);
Vue.component('login-layout', require('./components/layouts/LoginLayoutComponent.vue').default);
Vue.component('no-menu-layout', require('./components/layouts/NoMenuLayoutComponent.vue').default);
var home = Vue.component('home-component', require('./components/HomeComponent.vue').default);
var dashboard = Vue.component('home-component', require('./components/DashboardComponent.vue').default);
var discobjectList = Vue.component('discobject-list-component', require('./components/DISCOBJECT/DISCOBJECT.list.component.vue').default);
var discobjectDetail = Vue.component('discobject-detail-component', require('./components/DISCOBJECT/DISCOBJECT.detail.component.vue').default);
var discobjectSourceDetail = Vue.component('discobject-source-component', require('./components/DISCOBJECT/DISCOBJECT.source.component.vue').default);
var discobjectChartDetail = Vue.component('discobject-chart-component', require('./components/DISCOBJECT/DISCOBJECT.chart.component.vue').default);
var search = Vue.component('search-component', require('./components/DISCOBJECT/DISCOBJECT.search.component.vue').default);
var searchSource = Vue.component('search-source-component', require('./components/DISCOBJECT/DISCOBJECT.search.source.component.vue').default);
var login = Vue.component('login-component', require('./components/LoginComponent.vue').default);
var projectSelector = Vue.component('project-selector-component', require('./components/ProjectSelectorComponent.vue').default);
var discobjectInterfaces = Vue.component('interface-component', require('./components/DISCOBJECT/DISCOBJECT.interface.component.vue').default);
var discobjectSequence = Vue.component('sequence-component', require('./components/DISCOBJECT/DISCOBJECT.sequence.component.vue').default);
var discobjectAccess = Vue.component('access-component', require('./components/DISCOBJECT/DISCOBJECT.access.component.vue').default);
var discobjectPicker = Vue.component('discobject-picker-component', require('./components/DISCOBJECT/DISCOBJECT.picker.component.vue').default);
var discobjectDeadCode = Vue.component('deadcode-component', require('./components/DISCOBJECT/DISCOBJECT.deadcode.component.vue').default);
var discobjectStatusUpdate = Vue.component('discobject-status-updatecomponent', require('./components/DISCOBJECT/DISCOBJECT.status.update.component.vue').default);
Vue.component('source-component', require('./components/DISCOBJECT/Source.component.vue').default);
Vue.component('relations-chart-component', require('./components/DISCOBJECT/RelationsChart.component.vue').default);
Vue.component('relations2-chart-component', require('./components/DISCOBJECT/RelationsChart2.component.vue').default);
Vue.component('tangled-chart-component', require('./components/DISCOBJECT/TangledChart.component.vue').default);
Vue.component('usage-component', require('./components/DISCOBJECT/Usage.component.vue').default);
Vue.component('invocations-chart-component', require('./components/DISCOBJECT/InvocationsChart.component.vue').default);
Vue.component('column-chart-component', require('./components/chart/ColumnChart.component.vue').default);
Vue.component('donut-chart-component', require('./components/chart/DonutChart.component.vue').default);
Vue.component('table-component', require('./components/chart/Table.component.vue').default);
Vue.component('dashboard-item-component', require('./components/chart/DashboardItem.component.vue').default);
var userList = Vue.component('user-list-component', require('./components/User/User.list.component.vue').default);
var userEdit = Vue.component('user-edit-component', require('./components/User/User.edit.component.vue').default);

const routes = [
    //{ path: '/', component: home, meta: {layout: "application", requiresAuth: true} },
    { path: '/', component: projectSelector, meta: {layout: "login", requiresAuth: true} },
    { path: '/projects', component: projectSelector, meta: {layout: "login", requiresAuth: true} },
    { path: '/login', component: login, meta: {layout: "login"} },
    { path: '/:project/dashboard', component: dashboard, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/dashboard/:id', component: dashboard, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project', component: home, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/DISCOBJECT', component: discobjectList, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/DISCOBJECT/:UUID', component: discobjectDetail, meta: {layout: "application", requiresAuth: true}},
    { path: '/:project/DISCOBJECT/:UUID/source', component: discobjectSourceDetail, meta: {layout: "no-menu", requiresAuth: true}},
    { path: '/:project/DISCOBJECT/:UUID/chart', component: discobjectChartDetail, meta: {layout: "no-menu", requiresAuth: true}},    
    { path: '/:project/search/:searchPhrase', component: search, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/searchsource', component: searchSource, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/interfaces', component: discobjectInterfaces, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/sequence/:TYPE', component: discobjectSequence, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/sequence/:TYPE/:OPTYPE', component: discobjectSequence, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/access', component: discobjectAccess, meta: {layout: "application", requiresAuth: true} },
    //{ path: '/:project/access/:UUID', component: discobjectAccess, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/deadcode', component: discobjectDeadCode, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/statusupdate', component: discobjectStatusUpdate, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/User', component: userList, meta: {layout: "application", requiresAuth: true} },
    { path: '/:project/User/:id', component: userEdit, meta: {layout: "application", requiresAuth: true}},

    /*{ path: '/form1', component: form1 },
    { path: '/form2', component: form2 },
    { path: '/BSATBUSRList', component: BSATBUSRList },
    { path: '/BSATBUSRList/:COMPANY', component: BSATBUSRList },
    { path: '/BSATBUSR/:COMPANY/:SUPPLIER_CODE/:DOCK/:BUSROUTE', component: BSATBUSREdit },
    { path: '/BSATBUSR/new', component: BSATBUSREdit }
    */
  ]
  const router = new VueRouter({
    routes // short for `routes: routes`
  })
  router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    store.commit("updateUserFromStorage", null);
    const currentUser =  store.getters.currentUser;    
    var refreshTokenDate = new Date();
    refreshTokenDate.setMinutes( refreshTokenDate.getMinutes() + 30 );
    if (authRequired ) {
      if (currentUser) {        
        if (currentUser.expires_at && Date.parse(currentUser.expires_at) > refreshTokenDate ) {
          // no need to refresh token
          next();
        } else {
          // refresh token first
          refreshToken()
          .then(res => {
            store.commit("refreshTokenSuccess", res);
            next();
          })
          .catch(error => {
              // nothing expired token handled by a response interceptor
              //router.push('/login')
              return next({path: '/login', query: {source: to.fullPath}});
          });
        }
  
        //next();
      } else {
        return next({path: '/login', query: {source: to.fullPath}});
      }
      
      
    } else {
      next();
    }
  
    
  });

  router.beforeEach((to, from, next) => {
    // load project
    if (to.params && to.params.project) {
      var projectDict  = store.getters.projectByURL;
      if (projectDict && projectDict[to.params.project]) {    
        to.params.projectObj = projectDict[to.params.project];    
        to.params.projectApiRoot = discovererApiRoot + projectDict[to.params.project].UUID + "/";
        store.commit("updateCurrentProject", to.params.projectObj);
        next();
      } else {
        // refresh token first
        getProjectByUrl(to.params.project)
        .then(res => {
          var projects = store.getters.projects;
          projects.push(res);
          store.commit("refreshProjects", projects);
          to.params.projectObj = res;
          to.params.projectApiRoot = discovererApiRoot + res.UUID + "/";
          store.commit("updateCurrentProject", to.params.projectObj);
          next();
        })
        .catch(error => {
            // invalid project
          return next({path: '/login', query: {source: to.fullPath}});
        });
      }
    } else {
      next();
    }
  })

  /*
  router.afterEach((to, from) => {
    // refreshes token if possible
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const currentUser =  store.getters.currentUser;
  
    if (authRequired && currentUser) {
      refreshToken()
      .then(res => {
        store.commit("refreshTokenSuccess", res);
      })
      .catch(error => {
          // nothing expired token handled by a response interceptor
          router.push('/login')
      });

    } else {
    }
    
  });
  */
  const vuetify = new Vuetify({
    icons: {
      iconfont: 'md',
    },
  })
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
/*
const app = new Vue({
    el: '#app',
    router
});
*/
function getConfiguration () {
    return new Promise((resolve, reject) => {
        axios.get(discovererApiRoot + 'configuration',{
            headers: {
                        'Content-Type': 'application/json',
                        'Accept':'application/json'
                    }

        }).then(function(response) {
            self.loading = false;
            resolve({
                data: response.data
            });
        });
    
    });   
}   
getConfiguration()
.then(data => {
    //this.configuration = data;
    Vue.prototype.$configuration = data.data;   
    if (data && data.data && data.data.SentryDSN && data.data.SentryDSN != "null") {
      Sentry.init({
        Vue,
        dsn: data.data.SentryDSN,
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        logErrors: true,
        tracesSampleRate: 0.0,
        environment: data.data.SentryEnvironment
      });
    }
    const app = new Vue({
        router,
        store,
        vuetify,
        render: h => h(appC),    
    }).$mount("#app");
})        

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error.response.data)
  if (error.response.status === 401) {
    store.dispatch('logout')
    router.push({path: '/login', query: {source: router.currentRoute.fullPath}});
  }
  return Promise.reject(error)
})

axios.interceptors.request.use(function(config) {
  store.commit("updateUserFromStorage", null);
  const currentUser =  store.getters.currentUser;
  if (currentUser) {
    const token = currentUser.token;
    if ( token != null ) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
}, function(err) {
  return Promise.reject(err);
});