<template>
  <div class="no-menu-layout">
     <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="blue darken-3"
      dark
      app
      fixed
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <span class="hidden-sm-and-down" @click="onHome" style="cursor: pointer">
          <img src="exconma.png" style="margin-top: -20px; height: 60px;"> 
          Exconma Discoverer
        </span>
      </v-toolbar-title>
      <v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="search"
        :label="$t('search')"
        v-model="searchPhrase" 
        v-on:keyup="onSearchKeyUp"
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <!--
      <v-btn icon>
        <v-icon>apps</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>notifications</v-icon>
      </v-btn>      
      <v-btn text >
        {{ currentUser.name }}
        <v-avatar size="32px" tile>
          <img
            src="https://cdn.vuetifyjs.com/images/logos/logo.svg"
            alt="Vuetify"
          >
        </v-avatar>
      </v-btn>
      -->
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on }">
          <v-btn
            text
            v-on="on"
          >
            {{ currentUser.name }}
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item avatar>
              <v-list-item-avatar>
                <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title> {{ currentUser.name }}</v-list-item-title>
                <v-list-item-subtitle> {{ currentUser.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text @click="menu = false">{{$t('cancelitem')}}</v-btn>
            <v-btn color="primary" text @click="logout">{{$t('logout')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
     </v-app-bar>
    <v-main>
      <v-container fluid fill-height>
        <v-layout justify-start align-start>
            <slot />
        </v-layout>
      </v-container>
    </v-main>
    <v-footer class="pa-3">
      <v-spacer></v-spacer>
      <div>&copy; {{ new Date().getFullYear() }} Exconma s.r.o.</div>
    </v-footer>
  </div>
</template>

<script>
import Vue from 'vue';

  export default {
   computed:{
      currentUser(){
        this.$store.commit("updateUserFromStorage", null);
        return this.$store.getters.currentUser
      }
    },
    data: () => ({
      menu: false,
      dialog: false,
      drawer: null,
      items: [      
      ],
      searchPhrase: "",
      projectUrl: null
    }),
    props: {
      source: String
    },
    methods: {
      onSearchKeyUp: function(e) {
        if (e.keyCode === 13) {
          this.submitSearch();
        }      

      },
      submitSearch: function() {
        if (this.searchPhrase) {
          this.projectUrl = "/" + this.$route.params.project;;
          this.$router.push({ path: `${this.projectUrl}/search/${this.searchPhrase}`}); 
          this.searchPhrase = "";
        }
      },
      logout: function() {
        this.menu = false;
        this.$store.dispatch('logout');
        this.$router.push({path: '/login'});
      },
      onHome: function() {
        this.$router.push({path: '/'});
      }

    }
  }
</script>