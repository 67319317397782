<template>
    <div>

    <v-toolbar>
      <v-toolbar-title>{{$t('DEADCODE.title')}}</v-toolbar-title>
      <v-progress-linear
              :active="initializing"
              :indeterminate="initializing"
              bottom
              absolute
          ></v-progress-linear> 
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
              <v-btn text @click="exportTable" v-on="on">
                  <v-icon>save_alt</v-icon>
              </v-btn>                        
          </template>
          <span>{{$t('export')}}</span>
        </v-tooltip>                                       
      </v-toolbar-items>   
    </v-toolbar>
    <v-form v-model="valid" ref="form" lazy-validation >        
      <v-container fluid>
        <v-row v-if="!initializing">
          <v-col
            cols="4"
            md="4"
          >
            <v-select
              v-model="childType"
              :items="typeItems"
              :label="$t('DEADCODE.child.label')"
              :hint="$t('DEADCODE.child.hint')"
              persistent-hint
            ></v-select>           
          </v-col>
          <v-col
            cols="4"
            md="4"
          >
            <v-select
              v-model="linkTypes"
              :items="linkTypeItems"
              :label="$t('DEADCODE.linkType.label')"
              item-value="UUID"
              item-text="NAME"
              multiple
              chips
              deletable-chips
              :hint="$t('DEADCODE.linkType.hint')"
              persistent-hint
            ></v-select>           
          </v-col>
          <v-col
            cols="4"
            md="4"
          >
            <v-select
              v-model="parentTypes"
              :items="typeItems"
              :label="$t('DEADCODE.parent.label')"
              multiple
              chips
              deletable-chips
              :hint="$t('DEADCODE.parent.hint')"
              persistent-hint
            ></v-select>           
          </v-col>
          <v-col
            cols="4"
            md="4"
          >
            <v-select
              v-model="childStatusses"
              :items="statusItems"
              item-text="title"
              item-value="id"
              :label="$t('DEADCODE.status.label')"
              multiple
              chips
              deletable-chips
              :hint="$t('DEADCODE.status.hint')"
              persistent-hint
            ></v-select>           
          </v-col>
        </v-row>        
        <v-row>
          <v-col
            cols="12"
          >
            <v-btn color="primary" @click="find" >{{$t('find')}}</v-btn>            
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >             
          </v-col>
        </v-row>
        <v-row v-if="isLoaded || loading">
          <v-col
            cols="12"
          >
              <v-toolbar style="flex-grow: 0;flex-shrink: 0;">
                <v-toolbar-title>{{$t('')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items class="hidden-sm-and-down">
                    <v-tooltip bottom v-if="!loading" >
                        <template v-slot:activator="{ on }">
                            <v-btn text @click="exportTable" v-on="on"  >
                                <v-icon>save_alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span>{{$t('export')}}</span>
                    </v-tooltip>  
                </v-toolbar-items>
              </v-toolbar>
          </v-col>
          <v-col cols="12">
            <v-data-table
                  :headers="headers"
                  :items="items"    
                  class="elevation-1"
                  :loading="loading"
                  :options.sync="pagination"        
                  :server-items-length="totalItems"
                >   
                <v-data-footer :items-per-page-options="rowsPerPageItems"  />
                <template v-slot:body="{ items }">
                    <tbody>
                      
                      <tr v-for="item in items" :key="item.UUID" >
                        <td  ><router-link :to="`DISCOBJECT/${item.UUID}`">{{ item.NAME }}</router-link></td>
                        <td >{{ item.TYPE }}</td>
                        <td >{{ item.DESCRIPTION }}</td>
                        <td >{{ item.LAST_INVOCATION_DATE }}</td>
                        <td >{{ item.statusName }}</td>
                      </tr>
                    </tbody>
                  </template>
              </v-data-table>
            </v-col>
          </v-row>
      </v-container>
  </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import XLSX from 'xlsx';
import Vue from 'vue';

  export default {
    data: () => ({
      initializing: false,
      loading: false,
      saving: false,
      isLoaded: false,
      valid: true,
      typeItems: [],
      linkTypeItems: [],
      childType: null,
      parentTypes:[],
      linkTypes: [],
      childStatusses: [],
      statusItems: [],
      headers: [
        {
          text: Vue.i18n.translate('DISCOBJECT.NAME'),
          align: 'left',
          value: 'NAME'
        },
        {
          text: Vue.i18n.translate('DISCOBJECT.TYPE'),
          align: 'left',
          value: 'TYPE'
        },
        {
          text: Vue.i18n.translate('DISCOBJECT.DESCRIPTION'),
          align: 'left',
          value: 'DESCRIPTION'
        },
        {
          text: Vue.i18n.translate('DISCOBJECT.LAST_INVOCATION_DATE'),
          align: 'left',
          value: 'LAST_INVOCATION_DATE'
        },
        {
          text: Vue.i18n.translate('DISCOBJECT.STATUS'),
          align: 'left',
          value: 'STATUS'
        }   
      ],
      items: [],
      totalItems: 0,
      pagination: {
        sortBy: [],
        page: 1,
        itemsPerPage: 10,
        sortDesc: [false],
        multiSort: false
      },
      rowsPerPageItems:[10,25,50],
    }),
    mounted () {
      var self = this;
      document.title = Vue.i18n.translate('DEADCODE.title') + " - " +  Vue.i18n.translate('APP.TITLE');
      self.statusItems = self.$store.getters.statusses;
      this.getTypeDataFromApi()
        .then(data=> {
          self.initializing = false;
        });
    },
    watch: {      
       pagination: {
        handler () {
          if (this.childType) {
            this.getDataFromApi()
              .then(data => {
                this.items = data.items
                this.totalItems = data.totalItems
              })
          }
        },
        deep: true
      
       }
    },
    methods: {
      find () {
        var self = this;
        if (this.childType && this.parentTypes && this.linkTypes) {        
          this.getDataFromApi()
            .then(data => {
              if (data) {
                //self.resultItems = data;
                this.items = data.items
                this.totalItems = data.totalItems
              }
            });
        

        } else {
          
        }
      },
      getDataFromApi (notPaged) {
        var self = this;
        this.loading = true;
        return new Promise((resolve, reject) => {
            const { sortBy, sortDesc, page, itemsPerPage } = self.pagination
            var data={
              childType: this.childType,
              linkTypes: this.linkTypes,
              parentTypes: this.parentTypes,
              childStatusses: this.childStatusses
            }
            var link = "";
            var pageParams = "";
            if (!notPaged) {
              pageParams = 'paged=1&page='  + page + "&rowsperpage=" + itemsPerPage
            }
            if (sortBy && sortBy.length > 0) {
              link = self.$store.getters.currentProjectApiRoot	 + 'deadcode?orderby=' + sortBy[0] + "&descending=" + sortDesc[0] + "&" + pageParams;
            } else {
              link = self.$store.getters.currentProjectApiRoot	 + 'deadcode?'+pageParams;
            }
            axios.post(link,data,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                self.isLoaded = true;                
                var statusDict = self.$store.getters.statusById;
                if (notPaged) {
                  resolve(response.data.map(it => { it.statusName = statusDict[it.STATUS] ? statusDict[it.STATUS].title : it.name; return it; }));
                } else {
                  resolve({
                      items: response.data.data.map(it => { it.statusName = statusDict[it.STATUS] ? statusDict[it.STATUS].title : it.name; return it; }),
                      totalItems: response.data.total
                  });
                }
                
            });
        
        });
      },
      getTypeDataFromApi () {
        var self = this;
        this.initializing = true;
        var linkTypesPromise =  new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'LINKTYPE',{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                if (response && response.data ) {
                  self.linkTypeItems = response.data;
                  resolve(response.data);
                } else {
                  resolve(null);
                }
                
            });
        });
        var typesPromise =  new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTType',{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                if (response && response.data ) {
                  self.typeItems = response.data;
                  resolve(response.data);
                } else {
                  resolve(null);
                }
                
            });
        
        });
        return Promise.all([linkTypesPromise, typesPromise]);
      },
      openDetail ($item) {
        this.$router.push({ path: `DISCOBJECT/${$item.UUID}`});
      },    
      openDetailNew ($item) {
        let routeData = this.$router.resolve({path: `DISCOBJECT/${$item.UUID}`});
        window.open(routeData.href, '_blank');
      },    
      exportTable() {
        /* convert from array of arrays to workbook */
        this.getDataFromApi(true).then(allItems => {
          if (allItems) {               
            var  data = 
                [
                  [Vue.i18n.translate('DISCOBJECT.NAME'), Vue.i18n.translate('DISCOBJECT.TYPE'), 
                   Vue.i18n.translate('DISCOBJECT.DESCRIPTION'), Vue.i18n.translate('DISCOBJECT.LAST_INVOCATION_DATE')
                   , Vue.i18n.translate('DISCOBJECT.STATUS')  ]
                ]
              ;
            if (allItems) {
              for(var i=0; i<allItems.length; i++) {
                var curItem = allItems[i];
                data.push([curItem.NAME,curItem.TYPE,curItem.DESCRIPTION,curItem.LAST_INVOCATION_DATE,curItem.statusName])
              }
            }
            var worksheet = XLSX.utils.aoa_to_sheet(data);
            var new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "Sheet");
            /* output format determined by filename */
            XLSX.writeFile(new_workbook, 'deadcode.xlsx');
            /* at this point, out.xlsx will have been downloaded */
          }
        })
        
      }
    }
  }
</script>
