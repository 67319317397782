<template>
  <v-data-table  dense
    v-if="resultSet"
    :headers="headers"
    :items="items"
    :items-per-page="maxRows ? maxRows + 1 : 10"
    :hide-default-footer="true"
    class="elevation-1"
  ></v-data-table>
</template>

<script>
//
import { ResultSet } from '@cubejs-client/core';
import Vue from 'vue';
export default {
  name: 'Table',
  props: {
    resultSet: {
      type: ResultSet,
      required: false,
    },
    maxRows: {},
    sumOthers: {}
  },
  mounted() {},
  data: () => ({}),
  computed: {
    headers() {
      const data = this.resultSet.tablePivot();
      return Object.keys(data[0]).map((key) => {
        return {
          text: Vue.i18n.translate(key),
          align: 'start',
          sortable: true,
          value: key,
        };
      });
    },
    items() {
      var self = this;
      const data = this.resultSet.tablePivot();
      var rows =  data.map((key) => {
        return key;
      });
      if (self.maxRows && rows.length > self.maxRows ) {
        var topItems = rows.slice(0,self.maxRows);
        if (self.sumOthers) {
            var restItems = rows.slice(self.maxRows);
            var restSum = 0;
            for (var i=0; i < restItems.length; i++) {
                restSum += restItems[i][self.sumOthers];
            }
            var restItem = {};
            restItem[self.sumOthers] = restSum;
            restItem[self.headers[0].value] = Vue.i18n.translate("OTHERS");
            topItems.push(restItem);
        }
        return topItems;
      } else {
          return rows;
      }
    },
  },
};
</script>