<template>
  <div class="application-layout">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      fixed
      app
    >
      <v-list dense>
        <template v-for="item in items">
          <v-layout
            v-if="item.heading"
            :key="item.heading"
            row
            align-center
          >
            <v-flex xs6>
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-flex>
            <v-flex xs6 class="text-xs-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-flex>
          </v-layout>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.icon"          
            value="true"
            no-action
            :append-icon="item.model ? item['icon-alt'] : item['icon-alt']"
          >
            <template v-slot:activator>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              @click=""
              :to="child.url"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" @click=""  :to="item.url">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="blue darken-3"
      dark
      app
      fixed
    >
      <v-toolbar-title style="width: 300px" class="ml-0">
        <v-app-bar-nav-icon  @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down" @click="onHome" style="cursor: pointer">
          <img src="exconma.png" style="margin-top: -20px; height: 60px;"> 
          Exconma Discoverer
        </span>
      </v-toolbar-title>
      <v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="search"
        :label="$t('search')"
        v-model="searchPhrase" 
        v-on:keyup="onSearchKeyUp"
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <!--
      <v-btn icon>
        <v-icon>apps</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>notifications</v-icon>
      </v-btn>      
      <v-btn text >
        {{ currentUser.name }}
        <v-avatar size="32px" tile>
          <img
            src="https://cdn.vuetifyjs.com/images/logos/logo.svg"
            alt="Vuetify"
          >
        </v-avatar>
      </v-btn>
      -->
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on }">
          <v-btn
            text
            v-on="on"
          >
            {{ currentUser.name }}
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item >
              <v-list-item-avatar>
                <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title> {{ currentUser.name }}</v-list-item-title>
                <v-list-item-subtitle> {{ currentUser.email }}</v-list-item-subtitle>
                  
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-chip-group style="padding-left: 80px;">
            <v-chip v-for="role in currentUser.roles" :key="role.ID">
              {{ role.NAME }}
            </v-chip>
          </v-chip-group>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text @click="menu = false">{{$t('cancelitem')}}</v-btn>
            <v-btn color="primary" text @click="logout">{{$t('logout')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container fluid fill-height>
       <v-layout justify-start align-start>
          <v-flex>
            <slot />
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-footer class="pa-3">
      <v-spacer></v-spacer>
      <div>&copy; {{ new Date().getFullYear() }} Exconma s.r.o.</div>
    </v-footer>
  </div>
</template>

<script>
  import {hasRoles} from '../../partials/auth';
  export default {
    computed:{
      currentUser(){
        this.$store.commit("updateUserFromStorage", null);
        return this.$store.getters.currentUser
      }
    },
    data: () => ({
      menu: false,
      dialog: false,
      drawer: null,
      items: [],
      searchPhrase: "",
      project: null,
      projectUrl: null
    }),
    props: {
      source: String
    },
    mounted () {
        this.refreshMenu();
        
    },
    methods: {
      onSearchKeyUp: function(e) {
        if (e.keyCode === 13) {
          this.submitSearch();
        }      

      },
      submitSearch: function() {
        if (this.searchPhrase) {
          var encodedSearchPhrase = encodeURIComponent(this.searchPhrase);
          this.$router.push({ path: `${this.projectUrl}/search/${encodedSearchPhrase}`}); 
          this.searchPhrase = "";
        }
      },
      logout: function() {
        this.menu = false;
        this.$store.dispatch('logout');
        this.$router.push({path: '/login'});
      },
      onHome: function() {
        this.$router.push({path: this.projectUrl + '/'});
      },
      refreshMenu() {
        var currentUser = this.currentUser;
        this.items = [];
        if (this.$store.getters.currentProject) {
          this.project = this.$store.getters.currentProject;
          var projectUrl = "/" + this.project.URL;
          this.projectUrl = projectUrl;
          var projectMenu = this.$store.getters.menuLeftItems;
          /*
          var projectMenu = [
              { 'icon': 'all_inbox', 'url': '~project/DISCOBJECT', 'text': 'Resources'},
              { 'icon': 'build', 'text': 'Technologies' },
              { 'icon': 'assessment', 'text': 'Statistics' },
              { 'icon': 'compare_arrows', 'text': 'Dependencies',
                  'children': [
                    { 'text': 'Interfaces', 'url': '~project/interfaces'},
                    { 'text': 'OPC Browser', 'url': '~project/sequence/OPC/OPERATION' },
                    { 'text': 'Access Browser', 'url': '~project/access' },
                    { 'text': 'Source Browser', 'url': '~project/searchsource' },
                  ]
              },
              { 'icon': 'timeline', 'text': 'Usage'},
              { 'icon': 'delete', 'text': 'Zombie code', 
                  'children': [
                     { 'text': 'Dead code', 'url': '~project/deadcode'},
                     { 'text': 'Status update', 'url': '~project/statusupdate', 'roles': ['AdminRole'] }
                  ]
              },
              { 'icon': 'import_export', 'text': 'Exporter' },
              { 'icon': 'open_in_new', 'text': 'External tools' },
              { 'icon': 'settings', 'text': 'Settings' },
              { 'icon': 'supervisor_account', 'text': 'Users', 'url': '~project/User', 'roles': ['AdminRole'] },
              { 'icon': 'chat_bubble', 'text': 'Send feedback' }
          ]
          */
          this.processMenuLevel(projectMenu, this.items);
          
          /*
          this.items.push({ icon: 'all_inbox', text: 'Resources', url: projectUrl + '/DISCOBJECT' });
          this.items.push({ icon: 'build', text: 'Technologies' });
          this.items.push({ icon: 'assessment', text: 'Statistics' });
          this.items.push({ icon: 'compare_arrows', text: 'Dependencies',
            children: [
              { text: 'Interfaces', url: projectUrl +'/interfaces'},
              { text: 'OPC Browser', url: projectUrl +'/sequence/OPC/OPERATION' },
              { text: 'Access Browser', url: projectUrl +'/access' },
              { text: 'Source Browser', url: projectUrl +'/searchsource' }
            ]});
          this.items.push({ icon: 'timeline', text: 'Usage'});
          var zombieCodeChildren = [
              { text: 'Dead code', url: projectUrl +'/deadcode'}
          ];
          if (hasRoles(currentUser, ['AdminRole'], this.$configuration)) {
            zombieCodeChildren.push({ text: 'Status update', url: projectUrl + '/statusupdate' });
          }
          this.items.push({ icon: 'delete', text: 'Zombie code', 
          children: zombieCodeChildren
          });                
          this.items.push({ icon: 'import_export', text: 'Exporter' });             
          this.items.push({ icon: 'open_in_new', text: 'External tools' });

          this.items.push({ icon: 'settings', text: 'Settings' });
          if (hasRoles(currentUser, ['AdminRole'], this.$configuration)) {
            this.items.push({ icon: 'supervisor_account', text: 'Users', url: projectUrl +'/User' });
          }
          this.items.push({ icon: 'chat_bubble', text: 'Send feedback' });
          */
        }
      },
      processMenuLevel(configItems, menuItems) {
        var self = this;
        if (configItems && menuItems) {
          for (var i=0; i<configItems.length; i++) {
            var curConfigItem = configItems[i];
            var newMenuItem = {
              icon: curConfigItem.icon,
              text: curConfigItem.text,
              url: (curConfigItem.url ? curConfigItem.url.replace("~project", self.projectUrl) : curConfigItem.url),
              tooltip: curConfigItem.tooltip              
            }
            if (!curConfigItem.roles || hasRoles(self.currentUser, curConfigItem.roles, self.$configuration)) {
              if (curConfigItem.children) {
                newMenuItem.children = [];
                self.processMenuLevel(curConfigItem.children, newMenuItem.children);
              }
              menuItems.push(newMenuItem);
            }            
          }
        }
      }


    },
    created() {
      var self = this;
    this.unwatch = this.$store.watch(
      (state, getters) => getters.currentProject,
      (newValue, oldValue) => {
        console.log(`Updating from ${oldValue} to ${newValue}`);
        self.refreshMenu();
      },
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  }
</script>