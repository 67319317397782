<template>
    <v-card>
      <v-container
        fluid
        grid-list-lg
      >
       <v-row>
          <v-col
            :cols="cols11"
            :md="md11"
            v-if="config11"
          >       
            <dashboard-item-component :parameters="parameters" :config="config11"  />
          </v-col>
          <v-col :cols="cols12" :md="md12" v-if="config12">            
            <dashboard-item-component :parameters="parameters" :config="config12" />
          </v-col>
          <v-col :cols="cols13" :md="md13" v-if="config13">            
            <dashboard-item-component :parameters="parameters" :config="config13" />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :cols="cols21"
            :md="md21"
            v-if="config21"
          >       
            <dashboard-item-component :parameters="parameters" :config="config21"  />
          </v-col>
          <v-col :cols="cols22" :md="md22" v-if="config22">            
            <dashboard-item-component :parameters="parameters" :config="config22" />
          </v-col>
          <v-col :cols="cols23" :md="md23" v-if="config23">            
            <dashboard-item-component :parameters="parameters" :config="config23" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
</template>

<script>
    import { Promise } from 'q';
    import XLSX from 'xlsx';
    import axios from 'axios';
    import Vue from 'vue';
import DashboardItemComponent from './chart/DashboardItem.component.vue';
    export default {
  components: { DashboardItemComponent },
        props: [
            'card',        
        ],
        data() {
            return {
                config11: null,
                config12: null,
                config13: null,
                config21: null,
                config22: null,
                config23: null,
                cols11: 1,
                cols12: 1,
                cols13: 1,
                cols21: 1,
                cols22: 1,
                cols23: 1,
                md11: 1,
                md12: 1,
                md13: 1,
                md31: 1,
                md32: 1,
                md33: 1,
                parameters: {
                  projectId: this.$store.getters.currentProject.UUID
                }
            }
        },
        mounted() {
          var self = this;
          this.project = this.$route.params.project;
          var dashboardId =  this.$route.params.id;
          if (!dashboardId) {
            dashboardId = "home";
          }

          var visualizations =  this.$store.getters.visualizations;
          var dashboards = this.$store.getters.dashboards;  
          if (visualizations && dashboards) {
            var curDashboard = dashboards.find((it) => it.id===dashboardId);
            if (curDashboard) {
              if (curDashboard.rows) {
                curDashboard.rows.forEach((row, rowIndex) => {
                  var rowPos = rowIndex + 1;
                  if (row.columns) {
                    row.columns.forEach((column, columnIndex) => {
                      var columnPos = columnIndex + 1;
                      if (column.visualizationId) {
                        var curVisualisation = visualizations.find((it) => it.id === column.visualizationId );
                        if (curVisualisation) {
                          if (column.cols) {
                            self['cols' + rowPos + columnPos] = column.cols;                            
                          }
                          if (column.md) {
                            self['md' + rowPos + columnPos] = column.md;                            
                          } else {
                            self['md' + rowPos + columnPos] = self['cols' + rowPos + columnPos];                            
                          }
                          self['config' + rowPos + columnPos] = curVisualisation;
                        }
                      }
                    })
                  }
                })
              }
            }
          }       
        },
        methods: {  
         
        }
    }
</script>
