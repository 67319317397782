<template>
    <div style="width: 100%; ">
            <svg :id="id" :class="svgClass"></svg>
    </div>
</template>

<script>
    import * as d3 from 'd3';
    import * as zoom from 'd3-zoom';
    import * as multi from 'd3-selection-multi';
    import Vue from 'vue';


    export default {
        props: ['data', 'svgclass', 'labelY'],
        data () {
            return {
                id: null,
                svgClass: "usage-chart-container"
            }
        },
        watch: { 
            data: function(newVal, oldVal) { 
                this.onRefreshed(newVal);
            },
            svgclass: function(newVal, oldVal) {
                if (newVal) {
                    this.svgClass = newVal;
                }
                
            }
        },
         methods: {                 
            onRefreshed(data) {

                var self = this;
                // set the dimensions and margins of the graph
                var clientWidth = document.getElementById(self.id).clientWidth;
                var clientHeight = document.getElementById(self.id).clientHeight;
                var margin = {top: 20, right: 20, bottom: 30, left: 60},
                    width = clientWidth - margin.left - margin.right,
                    height = clientHeight - margin.top - margin.bottom;

                // set the ranges
                var x = d3.scaleBand()
                        .range([0, width])
                        .padding(0.1);
                var y = d3.scaleLinear()
                        .range([height, 0]);
                var svg = d3.select("#" + self.id)
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                .append("g")
                    .attr("transform", 
                        "translate(" + margin.left + "," + margin.top + ")");                        
                svg.selectAll("*").remove();               
                if (!data) {
                    return ;
                }         
                // append the svg object to the body of the page
                // append a 'group' element to 'svg'
                // moves the 'group' element to the top left margin
               // Scale the range of the data in the domains
                x.domain(data.map(function(d) { return d.name; }));
                y.domain([0, d3.max(data, function(d) { return d.value; })]);

                // append the rectangles for the bar chart
                svg.selectAll(".bar")
                    .data(data)
                    .enter().append("rect")
                    .attr("class", "bar")
                    .attr("x", function(d) { return x(d.name); })
                    .attr("width", x.bandwidth())
                    .attr("y", function(d) { return y(d.value); })
                    .attr("height", function(d) { return height - y(d.value); })
                    .append("title")
                    .text(d => `${d.name}: ${d.value}`);

                // add the x Axis
                svg.append("g")
                    .attr("transform", "translate(0," + height + ")")
                    .call(d3.axisBottom(x));                    
                // add the y Axis
                svg.append("g")
                    .call(d3.axisLeft(y));
                 // text label for the y axis
                svg.append("text")
                    .attr("transform", "rotate(-90)")
                    .attr("y", 0 - margin.left)
                    .attr("x",0 - (height / 2))
                    .attr("dy", "1em")
                    .style("text-anchor", "middle")
                    .text(Vue.i18n.translate(self.labelY));  

                
            },                       
         },
        mounted() {
            //
            var self = this;
            this.id = "columnChart" + this._uid;
            if (this.svgclass) {
                this.svgClass = this.svgclass;
            }
            Vue.nextTick()
            .then(function () {
                // DOM updated
                self.onRefreshed(self.data);
            })
            
            
           
            
        }
    }
</script>
