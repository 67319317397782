<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{$t('STATUS.UPDATE.TITLE')}}</v-toolbar-title>
      <v-progress-linear
        :active="initializing"
        :indeterminate="initializing"
        bottom
        absolute
      ></v-progress-linear> 
    </v-toolbar>

     <v-form v-model="valid" ref="form" lazy-validation >        
      <v-container fluid>
        <v-row>          
          <v-col
            cols="12"
            md="4"
          >
           <v-select
              v-model="types"
              :items="typeItems"
              :label="$t('STATUS.UPDATE.type.label')"
              :hint="$t('STATUS.UPDATE.type.hint')"
              multiple
              chips
              deletable-chips
            ></v-select>           
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="statusses"
              :items="statusItems"
              item-text="title"
              item-value="id"
              :label="$t('STATUS.UPDATE.status.label')"
              :hint="$t('STATUS.UPDATE.status.hint')"
              multiple
              chips
              deletable-chips
              persistent-hint
            ></v-select>           
          </v-col>           
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="targetStatus"
              :items="statusItems"
              item-text="title"
              item-value="id"
              :label="$t('STATUS.UPDATE.targetStatus.label')"
              :hint="$t('STATUS.UPDATE.targetStatus.hint')"
              persistent-hint
            ></v-select>           
          </v-col>     
          <v-col
            cols="12"
            md="8"
          >
            <v-radio-group v-model="parentLinkChoice" row >
              <v-radio :label="$t('STATUS.UPDATE.ignoreParentLinks.label')" value="ignoreParentLinks" />
              <v-radio :label="$t('STATUS.UPDATE.noParentLinks.label')" value="noParentLinks" />
              <v-radio :label="$t('STATUS.UPDATE.hasParentLinks.label')" value="hasParentLinks" />
            </v-radio-group>
          </v-col>   
           <v-col
            cols="12"
            md="4"
          >
           <v-select
              v-model="parentTypes"
              :items="typeItems"
              :label="$t('STATUS.UPDATE.parentTypes.label')"
              :hint="$t('STATUS.UPDATE.parentTypes.hint')"
              multiple
              chips
              deletable-chips
              v-if="parentLinkChoice!='ignoreParentLinks'"
            ></v-select>           
          </v-col>           
          <v-col
            cols="12"
            md="8"
          >
            <v-radio-group v-model="lastInvocationChoice" row >
              <v-radio :label="$t('STATUS.UPDATE.ignoreLastInvocation.label')" value="ignoreLastInvocation" />
              <v-radio :label="$t('STATUS.UPDATE.lowerLastInvocation.label')" value="lowerLastInvocation" />
              <v-radio :label="$t('STATUS.UPDATE.higherLastInvocation.label')" value="higherLastInvocation" />
            </v-radio-group>
          </v-col> 
          <v-col
            cols="12"
            md="4"
          >
            <v-menu
              v-model="lastInvocationMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
              v-if="lastInvocationChoice!='ignoreLastInvocation'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="lastInvocationDate"
                  :label="$t('STATUS.UPDATE.lastInvocationDate.label')"
                  prepend-icon="calendar_today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="lastInvocationDate"
                @input="lastInvocationMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-switch v-model="noLastInvocationDate" :label="$t('STATUS.UPDATE.noLastInvocationDate.label')"></v-switch>    
          </v-col>

        </v-row> 
        <v-row>
          <v-col
            cols="12"
          >
            <v-btn color="primary" @click="process" >{{$t('STATUS.UPDATE.process')}}</v-btn>
          </v-col>
        </v-row>
      </v-container>
     </v-form>
     <v-toolbar>
      <v-toolbar-title>{{$t('STATUS.UPDATE.results')}}</v-toolbar-title>
       <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              bottom
              absolute
          ></v-progress-linear>  
      <v-spacer></v-spacer>
       <v-toolbar-items class="hidden-sm-and-down">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn text @click="exportTable" v-on="on">
                    <v-icon>save_alt</v-icon>
                </v-btn>                        
            </template>
            <span>{{$t('export')}}</span>
        </v-tooltip>                                       
      </v-toolbar-items>       
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"    
      class="elevation-1"
      :loading="loading"
    >   
    <template v-slot:body="{ items }">
        <tbody>
          
          <tr v-for="item in items" :key="item.title" >
            <td >{{ item.title }}</td>
            <td >{{ item.value }}</td>
          </tr>
        </tbody>
      </template>
  </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import XLSX from 'xlsx';
import Vue from 'vue';

export default {
    mounted () {
      var self = this;
      document.title = Vue.i18n.translate('STATUS.UPDATE.TITLE') + ' - '+  Vue.i18n.translate('APP.TITLE');
      self.statusItems = self.$store.getters.statusses;
      this.getTypeDataFromApi()
        .then(data=> {
          self.initializing = false;
        });
    },
    data () {
      return {
        headers: [
          {
            text: Vue.i18n.translate('STATUS.UPDATE.results.title'),
            align: 'left',
            value: 'title'
          },
          {
            text: Vue.i18n.translate('STATUS.UPDATE.results.value'),
            align: 'left',
            value: 'value'
          },      
        ],
        items: [],
        loading: false,
        initializing: true,
        loaded: false,
        types: [],
        typeItems: [],
        statusses: [],
        statusItems: [],
        targetStatus: null,
        parentLinkChoice: "ignoreParentLinks",
        parentTypes: [],
        lastInvocationChoice: "ignoreLastInvocation",
        lastInvocationMenu: false,
        lastInvocationDate: null,
        noLastInvocationDate: false,
        rules: {
          regex: value => {
            try {
              var re = new RegExp(value);
              return true;
            }
            catch (ex) {
              return false;
            }
          },
        },
        valid:true
      }
    },
    watch: {
    },
    methods: {
      process() {
        this.getDataFromApi()
            .then(data => {
              this.loaded = true;
              this.items = [
                {
                  'title': Vue.i18n.translate('count'),
                  'value': data.count
                }
              ];
            })
      },
      openDetail ($item) {
        this.$router.push({ path: `DISCOBJECT/${$item.UUID}`});
      },    
      openDetailNew ($item) {
        let routeData = this.$router.resolve({path: `DISCOBJECT/${$item.UUID}`});
        window.open(routeData.href, '_blank');
      },    
      getDataFromApi (nopaging) {
        var self = this;
        this.loading = true;        
        return new Promise((resolve, reject) => {
            var data={
              types: self.types,
              statusses: self.statusses,
              targetStatus: self.targetStatus,
              noParentLinks: self.parentLinkChoice=='noParentLinks',
              hasParentLinks: self.parentLinkChoice=='hasParentLinks',
              parentTypes: self.parentTypes,

              noInvocationDate: self.noLastInvocationDate,

            }
            if (self.lastInvocationChoice=='lowerLastInvocation') {
              data.invocationDateLessThan=self.lastInvocationDate;
              if (self.noLastInvocationDate) {
                data.noInvocationDate = true;
              }
            } else if (self.lastInvocationChoice=='higherLastInvocation') {
              data.invocationDateGreaterThan=self.lastInvocationDate;
              if (self.noLastInvocationDate) {
                data.noInvocationDate = true;
              }
            }
            var link = self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECT/updatestatus';
            axios.post(link,data,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }
            }).then(function(response) {
                self.loading = false;
                resolve(response.data);
            });
        
        });        
      },
      getTypeDataFromApi () {
        var self = this;
        this.initializing = true;       
        var typesPromise =  new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTType',{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                if (response && response.data ) {
                  self.typeItems = response.data;
                  resolve(response.data);
                } else {
                  resolve(null);
                }
                
            });
        
        });
        return typesPromise;
      },
      exportTable() {
        /* convert from array of arrays to workbook */
        this.getDataFromApi(true)
              .then(result => {                
                var  data = 
                    [
                      [Vue.i18n.translate('DISCOBJECT.NAME'), Vue.i18n.translate('DISCOBJECT.TYPE')
                      , Vue.i18n.translate('DISCOBJECT.STATUS'), Vue.i18n.translate('SEARCH.highlight') ]
                    ]
                  ;
                if (result.items) {
                  for(var i=0; i<result.items.length; i++) {
                    var curItem = result.items[i];                    
                    var highlight = curItem.highlightValue;
                    var formated_highlight = highlight;
                    if (highlight) {
                      formated_highlight = highlight.replace(/<em>/g,'').replace(/<\/em>/g,'').replace(/<br \/>/g,'\n');
                    }
                    data.push([curItem.NAME,curItem.TYPE,curItem.statusName,formated_highlight])
                  }
                }
                var worksheet = XLSX.utils.aoa_to_sheet(data);
                var new_workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
                /* output format determined by filename */
                XLSX.writeFile(new_workbook, 'out.xlsx');
                /* at this point, out.xlsx will have been downloaded */
              })
        
      }
    
  }
}
</script>
