/** filter levels by resourceId and resourceFilter (regex)
 * Then call callback(filteredLevels, availableNodesDict, availableNodesExtendedDict)  */ 
export function filterLevels(levels, resourceId, resourceFilter, callback) {
    var filteredNodesDict = [];
    var filteredLevels = [];
    var availableNodesDict = null;
    var availableNodesExtendedDict = null; // with immediate parents/children

    if (resourceFilter) {
        availableNodesDict = [];                    
        availableNodesExtendedDict = [];
        var filterRE = new RegExp(resourceFilter, "i" );
        // copy all
        levels.forEach((l, i) => {
            var isLevelSelected = false;
            var levelNodes = [];
            l.forEach(n => {
                var reducedNode = {
                    id: n.id,
                    title: n.title,
                    label: n.label,
                    documentation: n.documentation,  
                    subid: n.subid, 
                    subtitle: n.subtitle, 
                    subdocumentation: n.subdocumentation, 
                    parents: []                                          
                }
                var isAvailable = filterRE.test(n.title);
                if (isAvailable) {
                    availableNodesDict[n.id] = n.id;
                    availableNodesExtendedDict[n.id] = n.id;
                }
                if (n.parents) {
                    n.parents.forEach(p => {
                        if (availableNodesDict[p]) {
                            availableNodesExtendedDict[n.id] = n.id;
                        }
                        if (isAvailable) {
                            availableNodesExtendedDict[p] = p;
                        }                                    
                        reducedNode.parents.push(p);
                    });
                }
                levelNodes.push(reducedNode);
            });
            filteredLevels.push(levelNodes);
        });
        levels = filteredLevels;
    }
    filteredLevels = [];
    if (resourceId) {
        // filter by resourceId
        if (availableNodesDict) {
            availableNodesDict[resourceId] = resourceId;
        }
        if (availableNodesExtendedDict) {
            availableNodesExtendedDict[resourceId] = resourceId;
        }
        
        levels.forEach((l,i) => l.forEach(n => n.level = i));
        var nodes = levels.reduce( ((a,x) => a.concat(x)), [] );
        var nodes_index = {};
        nodes.forEach(d => nodes_index[d.id] = d);
        if (nodes_index[resourceId]) {
            var selectedNode = nodes_index[resourceId];
            processParentLevels(selectedNode, nodes_index, levels, filteredNodesDict, availableNodesDict);
            var filteredSubNodes = [];
            filteredSubNodes[selectedNode.id] = selectedNode;
            processChildLevels(selectedNode, nodes_index, levels, filteredSubNodes, availableNodesDict);
            for(var itemName in filteredSubNodes) {
                filteredNodesDict[itemName] = filteredSubNodes[itemName];
            }
            filteredLevels = filterLevels(levels, filteredNodesDict);

            function filterLevels(levels, filteredNodesDict) {
                var filteredLevels = [];
            
                levels.forEach((l, i) => {
                    var isLevelSelected = false;
                    var levelNodes = [];
                    l.forEach(n => {
                        if (filteredNodesDict[n.id]) {
                            isLevelSelected = true;
                            var reducedNode = {
                                id: n.id,
                                title: n.title,
                                label: n.label,
                                documentation: n.documentation,  
                                subid: n.subid, 
                                subtitle: n.subtitle, 
                                subdocumentation: n.subdocumentation, 
                                parents: []                                          
                            }
                            if (n.parents) {
                                n.parents.forEach(p => {
                                    if (filteredNodesDict[p]) {
                                        reducedNode.parents.push(p);
                                    }
                                });
                            }
                            levelNodes.push(reducedNode);
                        }
                    });
                    if (isLevelSelected) {
                        filteredLevels.push(levelNodes);
                    }
                    
                });
                return filteredLevels;
            }

            function processChildLevels(selectedNode, nodes_index, levels, filteredNodesDict, availableNodesDict) {                            
                if (selectedNode) {
                    levels.forEach((l, i) => {
                        if (i > selectedNode.level) {
                            l.forEach(n => {
                                if (n.parents) {
                                    n.parents.forEach(p => {
                                        var parentNode = null;
                                        if (p.id) {
                                            parentNode = p;
                                        } else {
                                            parentNode = nodes_index[p];
                                        }
                                        if (parentNode && parentNode.id && filteredNodesDict[parentNode.id]) {
                                            if (!availableNodesDict || availableNodesDict[parentNode.id]) {
                                                filteredNodesDict[n.id] = n;
                                            }
                                            
                                        }                                            
                                    });
                                }
                            });
                        }

                    })
                }
            }
            function processParentLevels(selectedNode, nodes_index, levels, filteredNodesDict, availableNodesDict) {
                if (selectedNode) {
                    filteredNodesDict[selectedNode.id] = selectedNode;
                    if (selectedNode.parents && (!availableNodesDict || availableNodesDict[selectedNode.id])) {
                        selectedNode.parents.forEach(p => {
                            var parentNode = null;
                            if (p.id) {
                                parentNode = p;
                            } else {
                                parentNode = nodes_index[p];
                            }
                            processParentLevels(parentNode, nodes_index, levels, filteredNodesDict, availableNodesDict);
                        });
                    }
                }
            }
        }

    } else {
        // copy all
        filteredNodesDict = [];
        levels.forEach((l, i) => {
            var isLevelSelected = false;
            var levelNodes = [];
            l.forEach(n => {
                var reducedNode = {
                    id: n.id,
                    title: n.title,
                    label: n.label,
                    documentation: n.documentation,  
                    subid: n.subid, 
                    subtitle: n.subtitle, 
                    subdocumentation: n.subdocumentation, 
                    parents: []                                          
                }
                if (n.parents) {
                    n.parents.forEach(p => {
                        if (!availableNodesExtendedDict || availableNodesExtendedDict[p]) {
                            reducedNode.parents.push(p);
                        }                                    
                    });
                }
                if (!availableNodesExtendedDict || availableNodesExtendedDict[n.id]) {
                    levelNodes.push(reducedNode);
                }
                
            });
            filteredLevels.push(levelNodes);
        });
    }
    if (callback) {
        callback(filteredLevels, availableNodesDict, availableNodesExtendedDict);
    }
}