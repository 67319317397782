<template>
    <div class="source-container-inner">
        <v-toolbar v-if="isToolbarShown">
            <v-toolbar-title>{{$t('source')}}</v-toolbar-title>
            <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                bottom
                absolute
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn v-if="!isShown" text @click="showSource">{{$t('showSource')}}</v-btn>
                <v-btn v-if="isShown" text @click="hideSource">{{$t('hideSource')}}</v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                       <v-btn text @click="downloadSource" v-on="on">
                           <v-icon>save_alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span>{{$t('downloadSource')}}</span>
                </v-tooltip>                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                       <v-btn text @click="openGit" v-on="on" v-if="isLinkToSource">
                           <v-icon>folder_open</v-icon>
                        </v-btn>                        
                    </template>
                    <span>{{$t('openGit')}}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                       <v-btn text @click="showInNewWindow" v-on="on">
                           <v-icon>open_in_new</v-icon>
                        </v-btn>                        
                    </template>
                    <span>{{$t('showInNewWindow')}}</span>
                </v-tooltip>                
            </v-toolbar-items>
        </v-toolbar>
        <v-container fluid style="display: flex; flex-flow: column; flex-grow:1;flex-shrink: 1;">
             <v-row dense style="display: flex; flex-flow: column; flex-grow:1;flex-shrink: 1; min-width:0px;min-height:0px;">
                <v-col cols="12" >
                    <div v-bind:style="{ display: display}" id="monaco-container" class="source-container" ></div>
                </v-col>
             </v-row>
        </v-container>
    </div>
</template>

<script>
    import * as monaco from 'monaco-editor';
    import Vue from 'vue';

    export default {
        props: ['resourceName', 'resourceId', 'field', 'expanded', 'linkToSource'],
        data () {
            return {
                isShown: false,
                isLoaded: false,
                display: 'none',
                isToolbarShown: true,
                isLinkToSource: false,
                loading: false,
                linksDict: [],
                links: [],
                projectUrl: ""
            }
        },
         methods: {      
            showSource () {
                var self = this;
                this.isShown = true; 
                this.display  = '';
                if (this.isLoaded) {
                    // just show;
                } else {
                    this.loading = true;
                    var dataPromises = [
                        axios.get(discovererApiRoot + 'SOURCE/' + this.resourceId),
                        axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECT/' + this.resourceId + '/links')
                    ];
                    Promise.all(dataPromises).then(responses => {
                        self.loading = false;
                        var response = responses[0];
                        var linksResponse = responses[1];
                        if (linksResponse && linksResponse.data) {
                            self.links = linksResponse.data;
                            for (var i=0; i < linksResponse.data.length; i++) {
                                var curLink = linksResponse.data[i];
                                var curTargets = [];
                                if (self.linksDict[curLink.NAME]) {
                                    curTargets = self.linksDict[curLink.NAME];
                                }
                                curTargets.push(curLink);
                                self.linksDict[curLink.NAME] = curTargets ;
                            }
                        }
                        // Register a new language
                        monaco.languages.register({ id: 'jcl' });
                        monaco.languages.register({ id: 'pli' });
                        monaco.languages.register({ id: 'mfs' });

                        // Register a tokens provider for the language
                        /*
                        monaco.languages.setLanguageConfiguration('jcl', {
                            "comments": {
                                "lineComment": "//*"
                            },
                            "brackets": [
                                ["[", "]"],
                                ["(", ")"]
                            ]

                        });
                        */
                        monaco.languages.setMonarchTokensProvider('jcl', {
                            tokenizer: {
                                root: [
                                    [/^\/\/\*.*$/, 'comment']
                                ],
                                comment: [
                                
                                ],
                            }
                        });
                        monaco.languages.setMonarchTokensProvider('pli', {
                            tokenizer: {
                                root: [
                                    [/\/\*/, 'comment', '@comment'],
                                ],
                                comment: [
                                    [/[^\/*]+/, 'comment'],
                                    [/\*\//, 'comment', '@pop'],
                                    [/[\/*]/, 'comment']
                                ],
                            }
                        });
                        monaco.languages.setMonarchTokensProvider('mfs', {
                            tokenizer: {
                                root: [
                                    [/^\*.*$/, 'comment']
                                ],
                                comment: [
                                
                                ],
                            }
                        });                        
                        monaco.editor.defineTheme('customTheme', {
                            base: 'vs',
                            inherit: false,
                            rules: [
                                { token: 'custom-info', foreground: '808080' },
                                { token: 'custom-error', foreground: 'ff0000', fontStyle: 'bold' },
                                { token: 'custom-notice', foreground: 'FFA500' },
                                { token: 'comment', foreground: '008800' },
                            ]
                        });
                        var contentDisp = response.headers['content-disposition'];
                        var lang = null;
                        if (contentDisp.substr(contentDisp.length-3,3) == 'pli') {
                            lang = 'pli';
                        } else if  (contentDisp.substr(contentDisp.length-3,3) == 'inc') {
                            lang = 'pli';
                        } else if  (contentDisp.substr(contentDisp.length-3,3) == 'jcl') {
                            lang = 'jcl';
                        } else if  (contentDisp.substr(contentDisp.length-4,4) == 'proc') {
                            lang = 'jcl';
                        } else if  (contentDisp.substr(contentDisp.length-3,3) == 'mfs') {
                            lang = 'mfs';
                        } else {
                            lang = 'pli'
                        }
                        //var lang = contentDisp.substr(contentDisp.length-3,3);
                        monaco.languages.registerHoverProvider(lang, {
                            provideHover: function (model, position) {
                                
                                if (model.getWordAtPosition(position) && model.getWordAtPosition(position).word) {
                                    var word = model.getWordAtPosition(position);
                                    if (self.linksDict[word.word]) {
                                        var contents =[
                                            { value: '**' + word.word + '**' }
                                            //,{ value: '[test](http://google.com)'}
                                            
                                        ];
                                        var curLinks = self.linksDict[word.word];
                                        var loc = window.location;
                                        var curLinksDict = [];
                                        for (var i = 0; i < curLinks.length; i++) {
                                            var curLink = curLinks[i];
                                            if (!curLinksDict[curLink.UUID]) {
                                                curLinksDict[curLink.UUID] = curLink;
                                                let routeData = self.$router.resolve({path: `${self.projectUrl}/DISCOBJECT/${curLink.UUID}`});
                                                //window.open(routeData.href, '_blank');
                                                contents.push({ value: '[' + curLink.TYPE + '](' + loc.origin + loc.pathname + loc.search +  routeData.href + ')'})
                                                //contents.push(curLink.TYPE)
                                            }
                                            
                                        } 
                                        return {
                                            range: new monaco.Range(position.lineNumber, word.startColumn, position.lineNumber, word.endColumn),
                                            contents: contents
                                        };
                                        /*
                                        [
                                                { value: '**SOURCE**' },
                                                { value: '```html\n' + word.word + '\n```' }
                                            ] */
                                    } else {
                                        return null;
                                    }
                                    
                                    
                                } else {
                                    return null;
                                }
                            }    
                        });
                        monaco.editor.create(document.getElementById('monaco-container'), {
                            theme: 'customTheme',
                            value: response.data,
                            language: lang,
                            readOnly: true,
                            automaticLayout: true
                        });
                        self.isLoaded = true;
                    })


                }
            },
            hideSource () {
                this.isShown = false;
                this.display = 'none';
            },
            downloadSource () {
                var self = this;
                window.open(discovererApiRoot + 'SOURCE/' + this.resourceId, "_blank");
            },
            showInNewWindow () {
                var self = this;
                let routeData = this.$router.resolve({path: `${self.projectUrl}/DISCOBJECT/${this.resourceId}/source`});
                window.open(routeData.href, '_blank');
            },
            openGit () {
                var project = this.$store.getters.currentProject;
                if (project && project.CONFIGURATION) {
                    var confObj = JSON.parse(project.CONFIGURATION);
                    var gitSourceRoot = confObj.gitSourceRoot;
                    window.open( gitSourceRoot + this.linkToSource, "_blank");
                }
                
            },
         },
        mounted() {
            //
            this.projectUrl = "/" + this.$route.params.project;
            if (this.expanded) {
                this.isToolbarShown = false;
                this.showSource();
            }
            if (this.linkToSource) {
                this.isLinkToSource = true;
            } else {
                this.isLinkToSource = false;
            }
            
           
            
        }
    }
</script>
