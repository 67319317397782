<template>
  <div style="display: flex;flex-flow: column;width: 100%;flex-grow: 1;flex-shrink: 1;height: 100%;">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
    ></v-progress-linear>
    <relations-chart-component :resourceId="UUID" :expanded="true"></relations-chart-component>
    <v-btn @click="cancel" color="info">{{$t('cancelitem')}}</v-btn>
  </div>
</template>

<script>
import axios from 'axios'

  export default {
    data: () => ({
      loading: false,
      saving: false,
      valid: true,
      NAME: null,
      TYPE:null,
      DESCRIPTION: null,
      UUID: null,
      SOURCE: null,
      PREVIEW: null,


    }),
    created () {
      this.UUID = this.$route.params.UUID;
      /*
      if (this.UUID) {        
        this.getDataFromApi()
          .then(data => {
            if (data) {
              this.NAME = data.NAME;
              this.TYPE = data.TYPE;
              this.DESCRIPTION = data.DESCRIPTION;   
              this.SOURCE=data.SOURCE;         
              this.PREVIEW=data.PREVIEW;   
              document.title = this.NAME + " - " +  Vue.i18n.translate('APP.TITLE');
            }
          });
      } else {
        
      }
      */
    },
    methods: {
      cancel () {
        var self = this;
        self.$router.push({ path: `../`}); 
      },
      getDataFromApi () {
        var self = this;
        this.loading = true;
        

        return new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECT/' + this.UUID,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                if (response.data && response.data.length) {
                  resolve(response.data[0]);
                } else {
                  resolve(null);
                }
                
            });
        
        });
      },     

    }
  }
</script>
