export function registerUser(credentials){
    return new Promise((res,rej)=>{
        axios.post(discovererApiRoot + 'auth/register', credentials)
        .then(response => {
            res(response.data);
        })
        .catch(err => {
            rej('An error occured.. try again later.')
        })
    })
}

export function login(credentials){
    return new Promise((res,rej)=>{
        axios.post(discovererApiRoot + 'auth/login', credentials)
        .then(response => {
            var data = response.data;
            if (data && data.user) {
                var user = data.user;
                if (!user.name && user.cn) {
                    if (Array.isArray(user.cn)) {
                        user.name = user.cn[0];
                    } else {
                        user.name = user.cn;
                    }
                }
                if (!user.email && user.mail) {
                    if (Array.isArray(user.mail)) {
                        user.email = user.mail[0];
                    } else {
                        user.email = user.mail;
                    }
                }
            }
            
            res(data);
        })
        .catch(err => {
            rej('Wrong Email/Password combination.')
        })
    })
}

export function refreshToken(){
    return new Promise((res,rej)=>{
        axios.post(discovererApiRoot + 'auth/refresh', {})
        .then(response => {
            var data = response.data;
            if (data && !data.name && data.cn) {
                if (Array.isArray(data.cn)) {
                    data.name = data.cn[0];
                } else {
                    data.name = data.cn;
                }
            }
            if (data && !data.email && data.mail) {
                if (Array.isArray(data.mail)) {
                    data.email = data.mail[0];
                } else {
                    data.email = data.mail;
                }
            }
            res(data);
        })
        .catch(err => {
            rej('Token refresh error.')
        })
    })
}

export function getLoggedinUser(){
    const userStr = localStorage.getItem('user');

    if(!userStr){
        return null
    }

    return JSON.parse(userStr);
}

export function hasRole(user, role, $configuration) {
    if (!role) {
        return true;
    }
    if (user && user.roles) {
        if ($configuration.Roles && $configuration.Roles[role]) {
            var roleId = $configuration.Roles[role];
            for (var i=0; i<user.roles.length; i++) {
                var curRole = user.roles[i];
                if (curRole['UUID'] == roleId) {
                    return true;
                }
            }
        }
    }
    return false;
}

export function hasRoles(user, roles, $configuration) {
   if (roles) {
       for (var i=0; i<roles.length;i++) {
           var res = hasRole(user, roles[i], $configuration );
           if (res) {
               return true;
           }
       }
       return false;
   } else {
       return true;
   }
   
}