export function getProjectByUrl(url){
    return new Promise((res,rej)=>{
        axios.get(discovererApiRoot + 'PROJECT?url=' + encodeURIComponent(url))
        .then(response => {
            if (response && response.data) {
                res(response.data[0]);

            } else {
                rej('Invalid project');
            }
        })
        .catch(err => {
            Sentry.captureException(err);
            rej('An error occured.. try again later.')
        })
    })
}

export function getProjects(){
    return new Promise((res,rej)=>{
        axios.get(discovererApiRoot + 'PROJECT?orderby=name')
        .then(response => {
            if (response ) {
                res(response.data);

            } else {
                res([]);
            }
        })
        .catch(err => {
            Sentry.captureException(err);
            rej('An error occured.. try again later.')
        })
    })
}

