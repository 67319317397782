<template>
  <div>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <slot />              
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>    
    <v-footer app fixed>
      <span>&copy; {{ new Date().getFullYear() }} Exconma s.r.o.</span>
    </v-footer>
  </div>
</template>

<script>
import Vue from 'vue';

  export default {
    data: () => ({
      drawer: true,
      locale: ''
    }),
    props: {
      source: String
    },
    mounted () {
      this.locale = Vue.i18n.locale();
    },
     methods: {
      changeLocale () {
          if (this.locale == 'en') {
            Vue.i18n.set('cz');
          } else {
            Vue.i18n.set('en');
          }
          this.locale = Vue.i18n.locale();
      }
    }
  }
</script>