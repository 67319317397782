<template>
     <v-card  height="100%" width="100%" style="display: flex; flex-flow: column;">
        <v-toolbar style="flex-grow: 0;flex-shrink: 0;">
          <v-toolbar-title>{{NAME}}</v-toolbar-title>
          <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              bottom
              absolute
          ></v-progress-linear>          
        </v-toolbar>
        <source-component :resourceId="UUID" :linkToSource="LINK_TO_SOURCE" v-if="SOURCE" :expanded="true" ></source-component>
        <v-btn @click="cancel" color="info">{{$t('cancelitem')}}</v-btn>
     </v-card>
</template>

<script>
import axios from 'axios'
import Vue from 'vue';

  export default {
    data: () => ({
      loading: false,
      saving: false,
      valid: true,
      NAME: null,
      TYPE:null,
      DESCRIPTION: null,
      UUID: null,
      SOURCE: null,
      PREVIEW: null,
      LINK_TO_SOURCE: null
    }),
    mounted () {
      this.UUID = this.$route.params.UUID;
      if (this.UUID) {        
        this.getDataFromApi()
          .then(data => {
            if (data) {
              this.NAME = data.NAME;
              this.TYPE = data.TYPE;
              this.DESCRIPTION = data.DESCRIPTION;   
              this.SOURCE=data.SOURCE;         
              this.PREVIEW=data.PREVIEW;   
              this.LINK_TO_SOURCE = data.LINK_TO_SOURCE;
              document.title = this.NAME + " - " +  Vue.i18n.translate('APP.TITLE');
            }
          });
      } else {
        
      }
    },
    methods: {
      cancel () {
        var self = this;
        self.$router.push({ path: `..`}); 
      },
      getDataFromApi () {
        var self = this;
        this.loading = true;
        

        return new Promise((resolve, reject) => {
            axios.get(self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECT/' + this.UUID,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                if (response.data && response.data.length) {
                  resolve(response.data[0]);
                } else {
                  resolve(null);
                }
                
            });
        
        });
      }

    }
  }
</script>
