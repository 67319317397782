export function getTypesSetting(name){
    if (!name) {
        return;
    }
    const typeStr = localStorage.getItem('type_' + name);

    if(!typeStr){
        return {};
    }

    return JSON.parse(typeStr);
}

export function setTypesSetting(name, settings){
    if (!name) {
        return;
    }
    const typeStr = localStorage.setItem('type_' + name, JSON.stringify(settings));
}

export function updateTypesSettingFromLegend(name, legendItems) {
    if (!name) {
        return;
    }
    var settings = getTypesSetting(name);
    if (legendItems) {
        for (var i=0; i<legendItems.length; i++) {
            var curLegend = legendItems[i];
            if (curLegend) {
                var curSetting = settings[curLegend.title];
                if (!curSetting) {
                    curSetting = {};
                    settings[curLegend.title] = curSetting;
                }
                curSetting.disabled = !curLegend.selected;
            }
        }
        setTypesSetting(name, settings);
    }
}
