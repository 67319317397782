<template>
    <v-card class="elevation-12">
        <v-toolbar dark color="primary">
        <v-toolbar-title>
             <span class="hidden-sm-and-down">
                <img src="exconma.png" style="margin-top: -20px; height: 60px;"> 
                Exconma Discoverer
            </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>        
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :items="items"    
            class="elevation-1"
            :loading="loading"
            >   
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.UUID" >
                        <td  ><router-link :to="`/${item.URL}/dashboard`">{{ item.NAME }}</router-link></td>
                        <td >{{ item.DESCRIPTION }}</td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>        
    </v-card>
</template>
<script>
    import {getProjects} from '../partials/project';
    import Vue from 'vue';
export default {
    data() {
        return {
            headers: [
                {
                    text: Vue.i18n.translate('PROJECT.NAME'),
                    align: 'left',
                    value: 'NAME'
                },
                {
                    text: Vue.i18n.translate('DISCOBJECT.DESCRIPTION'),
                    align: 'left',
                    value: 'DESCRIPTION'
                }          
            ],
            items: [],
            totalItems: 0,
            loading: true,           
            inprogress: null
        }
    },
    mounted () {
        var self = this
        this.getDataFromApi()
        .then(data => {
            self.items = data.items;
            self.totalItems = data.totalItems;
            if (self.totalItems == 1) {
                self.$router.push({path: `/${self.items[0].URL}/dashboard`});
            }
        });
    },
    methods:{
       getDataFromApi () {
        var self = this;
        this.loading = true;        
        return new Promise((resolve, reject) => {
            var link = "";
            link = discovererApiRoot + 'PROJECT?orderby=NAME';
            axios.get(link,{
                headers: {
                            'Content-Type': 'application/json',
                            'Accept':'application/json',
                        }

            }).then(function(response) {
                self.loading = false;
                resolve({
                    items: response.data,
                    totalItems: response.data.length
                });
            });
        
        });        
      },
    },
    computed:{
      
    }

}
</script>