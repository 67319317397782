import {getLoggedinUser} from './partials/auth';
import Vue from 'vue';
const user = getLoggedinUser();

export default {
    state: {
        currentUser: user,
        isLoggedIn: !!user,
        loading: false,
        auth_error: null,
        reg_error:null,
        registeredUser: null,
        statusItems: [],
        projectItems: [],
        currentProject: null,
        objectTypes: {},       
        menuLeftItems: [],
        visualizations:[],
        dashboards: []
    },
    getters: {
        isLoading(state){
            return state.loading;
        },
        isLoggedin(state){
            return state.isLoggedin;
        },
        currentUser(state){            
            return state.currentUser;
        },
        authError(state){
            return state.auth_error;
        },
        regError(state){
            return state.reg_error; 
        },
        registeredUser(state){
            return state.registeredUser; 
        },
        statusses(state){
            return state.statusItems;
        },
        statusById(state){
            var ret = [];
            if (state.statusItems) {
                for (var i=0; i<state.statusItems.length; i++) {
                    var curItem = state.statusItems[i];
                    ret[curItem.id] = curItem;
                }
            }
            return ret;
        },
        statusByName(state){
            var ret = [];
            if (state.statusItems) {
                for (var i=0; i<state.statusItems.length; i++) {
                    var curItem = state.statusItems[i];
                    ret[curItem.name] = curItem;
                }
            }
            return ret;
        },  
        projects(state){
            return state.projectItems;
        },
        projectByUUID(state){
            var ret = [];
            if (state.projectItems) {
                for (var i=0; i<state.projectItems.length; i++) {
                    var curItem = state.projectItems[i];
                    ret[curItem.UUID] = curItem;
                }
            }
            return ret;
        },
        projectByURL(state){
            var ret = [];
            if (state.projectItems) {
                for (var i=0; i<state.projectItems.length; i++) {
                    var curItem = state.projectItems[i];
                    ret[curItem.URL] = curItem;
                }
            }
            return ret;
        },  
        currentProject(state){
            return state.currentProject;
        }, 
        currentProjectApiRoot(state){
            if (state.currentProject) {
                return discovererApiRoot + state.currentProject.UUID + "/";
            } else {
                return discovererApiRoot;
            }
            
        }, 
        objectTypes(state){
            return state.objectTypes;
        }, 
        menuLeftItems(state){
            return state.menuLeftItems;
        },
        visualizations(state){
            return state.visualizations;
        },
        dashboards(state){
            return state.dashboards;
        }      

    },
    mutations: {
        login(state){
            state.loading = true;
            state.auth_error = null;
        },
        loginSuccess(state, payload){
            state.auth_error = null;
            state.isLoggedin = true;
            state.loading = false;
            state.currentUser = Object.assign({}, payload.user, {token: payload.access_token, expires_at: payload.expires_at});
            localStorage.setItem("user", JSON.stringify(state.currentUser));
        },
        refreshTokenSuccess(state, payload){
            if (state.currentUser) {
                state.currentUser = Object.assign(state.currentUser, {token: payload.access_token, expires_at: payload.expires_at});
                localStorage.setItem("user", JSON.stringify(state.currentUser));
            }
        },
        updateUserFromStorage(state, payload){
            if (state.currentUser) {
                state.currentUser = getLoggedinUser();
            }
        },
        loginFailed(state, payload){
            state.loading = false; 
            state.auth_error = payload.error;
        },
        logout(state){
            localStorage.removeItem("user");
            state.isLoggedin = false;
            state.currentUser = null;
        },
        registerSuccess(state, payload){
            state.reg_error = null;
            state.registeredUser = payload.user;
        },
        registerFailed(state, payload){
            state.reg_error = payload.error;
        },
        refreshStatusses(state, statusItems) {
            for (var i=0; i<statusItems.length; i++) {
                var curItem = statusItems[i];
                curItem.title = Vue.i18n.translate('STATUS.' + curItem.name);
            }
            state.statusItems = statusItems;
        },
        refreshProjects(state, projectItems) {
            state.projectItems = projectItems;
        },
        updateCurrentProject(state, project) {
            if (!state.currentProject || (project && state.currentProject.UUID !== project.UUID)) {
                state.currentProject = project;
                const config = project.CONFIGURATION;
                if (config) {
                    const configObj = JSON.parse(config);
                    state.objectTypes = configObj.objectTypes;
                    state.menuLeftItems = configObj.menuLeft;
                    if (configObj.statusses) {
                        for (var i=0; i<configObj.statusses.length; i++) {
                            var curItem = configObj.statusses[i];
                            curItem.title = Vue.i18n.translate('STATUS.' + curItem.name);
                        }
                        state.statusItems = configObj.statusses;

                    } else {
                        state.statusItems = [];
                    }
                    state.visualizations = configObj.visualizations;
                    state.dashboards = configObj.dashboards;
                   
                }
                
            }
            state.currentProject = project;
        },
        refreshObjectTypes(state, objectTypes) {
            state.objectTypes = objectTypes;
        },
    },
    actions: {
        login(context){
            context.commit("login");
        },
        logout(context){
            context.commit("logout");
        }
    }
};