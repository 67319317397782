<template>
    <div class="relations-container-inner">
        <v-toolbar style="flex-grow: 0;flex-shrink: 0;">
            <v-toolbar-title>{{$t('relationsChart')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn v-if="!isShown" text @click="showChart">{{$t('showRelations')}}</v-btn>
                <v-btn v-if="isShown" text @click="hideChart">{{$t('hideRelations')}}</v-btn>
                <v-btn v-if="!isShownFlow" text @click="showChartFlow">{{$t('showFlow')}}</v-btn>
                <v-btn v-if="isShownFlow" text @click="hideChartFlow">{{$t('hideFlow')}}</v-btn>
                <v-tooltip bottom v-if="isShown" >
                    <template v-slot:activator="{ on }">
                        <v-btn text @click="exportChildHierarchy" v-on="on">
                            <v-icon>save_alt</v-icon>
                        </v-btn>                        
                    </template>
                    <span>{{$t('export')}}</span>
                </v-tooltip>  
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                       <v-btn text @click="showInNewWindow" v-on="on">
                           <v-icon>open_in_new</v-icon>
                        </v-btn>                        
                    </template>
                    <span>{{$t('showInNewWindow')}}</span>
                </v-tooltip>   
            </v-toolbar-items>
        </v-toolbar>
        <v-container :style="{ display: display}" fluid class="relations-container">
            <v-row style="flex-grow: 0;flex-shrink: 0;">
                <v-col cols="12" >
                    <div class="layout row wrap">
                        <v-flex
                            xs6
                            sm4
                            md3
                            lg2
                            v-for="(item, index) in legendItems" :key="'legend' + index"
                            >
                                <v-card>
                                    <v-card-title>
                                        <span > 
                                            <v-checkbox v-model="item.selected" >
                                            <template v-slot:label>
                                                <div>
                                                <v-icon v-bind:style="{color: item.color}" >lens</v-icon>{{ item.title }}
                                                </div>
                                            </template>
                                            </v-checkbox> 
                                        </span>
                                    </v-card-title>                            
                                </v-card>
                            </v-flex>
                    </div>        
                </v-col>
            </v-row>
            <v-row >
                <v-col cols="12" >
                    <svg id="graphsvg"  class="relations-chart-container"></svg>
                </v-col>
            </v-row>
        </v-container>       
        <v-container fluid grid-list-md v-bind:style="{ display: displayFlow}" class="relations-container">
            <v-row style="flex-grow: 0;flex-shrink: 0;">
                <v-col cols="12" >
                    <div class="layout row wrap" >
                        <v-flex
                            xs6
                            sm4
                            md3
                            lg2
                            v-for="(item, index) in legendFlowItems" :key="'legend' + index"
                            >
                                <v-card>
                                    <v-card-title>
                                        <span > 
                                            <v-checkbox v-model="item.selected">
                                            <template v-slot:label>
                                                <div>
                                                <v-icon v-bind:style="{color: item.color}" >lens</v-icon>{{ item.title }}
                                                </div>
                                            </template>
                                            </v-checkbox> 
                                        </span>
                                    </v-card-title>                            
                                </v-card>
                            </v-flex>
                    </div>        
                </v-col>
            </v-row>
            <v-row >
                <v-col cols="12" >
                    <svg id="graphsvgflow" class="relations-chart-container"></svg>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import * as d3 from 'd3';
import * as zoom from 'd3-zoom';
import * as multi from 'd3-selection-multi';
import { Promise } from 'q';
import XLSX from 'xlsx';
import * as typeFilterCache from '../../partials/typeFilterCache.js'
import Vue from 'vue';

export default {
        props: ['resourceName', 'resourceId', 'field', 'expanded'],
        data () {
            return {
                isShown: false,
                isLoaded: false,
                display: 'none',
                isShownFlow: false,
                isLoadedFlow: false,
                displayFlow: 'none',
                legendItems: [],
                legendFlowItems: [],
                /*
                nodesFlow: [],
                linksFlow: [],
                simulationFlow: null,
                nodeFlow: null,
                linkFlow: null,
                */
                colorFlow: null,
                relationsSettingsName: "relations",
                flowSettingsName: "flow",
                projectUrl:"",
                objectTypes: {}
            }
        },
        nodeFlow: null,
        linkFlow: null,
        simulationFlow: null,
        nodesFlow: [],
        linksFlow: [],
        methods: {      
            showChart () {
                var self = this;
                self.hideChartFlow();
                this.isShown = true; 
                this.display  = 'flex';
                if (this.isLoaded) {
                    // just show;
                } else {
                    self.loadData(this.resourceId, self.color).then(function(graph) {
                        self.onRefreshed();
                    });
                    return;
                    function dragstarted(d) {
                        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
                        d.fx = d.x;
                        d.fy = d.y;
                    }

                    function dragged(d) {
                        d.fx = d3.event.x;
                        d.fy = d3.event.y;
                    }

                    function dragended(d) {
                        if (!d3.event.active) simulation.alphaTarget(0);
                        d.fx = null;
                        d.fy = null;
                    }
                    function zoomed() {
                        container.style("stroke-width", 1.5 / d3.event.transform.k + "px");
                        // g.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")"); // not in d3 v4
                        container.attr("transform", d3.event.transform); // updated for d3 v4
                    }

                }
            },
            hideChart () {
                this.isShown = false;
                this.display = 'none';
            },
            showChartFlow () {
                var self = this;
                self.hideChart();
                this.isShownFlow = true; 
                this.displayFlow  = 'flex';
                if (this.isLoadedFlow) {
                    // just show;
                } else {
                    
                    self.loadFlowData(this.resourceId, self.colorFlow).then(function(graph) {
                        self.onFlowRefreshed();
                    });
                    return;
                }
            },
            hideChartFlow () {
                this.isShownFlow = false;
                this.displayFlow = 'none';
            },
            getColor(type, colorScale) {
                var x = 9;
                if (this.objectTypes && this.objectTypes[type] && this.objectTypes[type].color) {
                    x=this.objectTypes[type].color;
                } 
                //console.log(d.documentation + x);
                return colorScale(x);
            },
            loadData(uuid) {
                var self = this;
                self.color = d3.scaleOrdinal(d3.schemeCategory10 );
                return new Promise((resolve, reject) => {
                    self.$store.commit("updateUserFromStorage", null);
                    const currentUser =  self.$store.getters.currentUser;
                    if (currentUser && currentUser.token) {
                        d3.json(self.$store.getters.currentProjectApiRoot	 + 'NEO/' +uuid, {headers: {
                            "Content-Type":'application/json',
                            "Accept":'application/json',
                            "Authorization": `Bearer ${currentUser.token}`
                        }})
                        .then(function(graph) {
                            self.isLoaded = true;
                            self.legendItems = [];
                            var legendTempItems = [];
                            self.$options.nodes =[];
                            self.$options.links =[];
                            var typeSettings = typeFilterCache.getTypesSetting(self.relationsSettingsName);
                            for(var i=0; i<graph.nodes.length; i++) {
                                var curNode = graph.nodes[i];
                                if (!legendTempItems[curNode.documentation]) {
                                    var curType = curNode.documentation;
                                    var newLegend = {
                                        'title': curType,
                                        'color': self.getColor(curNode.documentation, self.color),
                                        'selected': true
                                    };
                                    if (typeSettings[curType] && typeSettings[curType].disabled) {
                                        newLegend.selected = false;
                                    }
                                    if (self.objectTypes && self.objectTypes[curType] && self.objectTypes[curType].hidden) {
                                          newLegend.selected = false;
                                    } 
                                    legendTempItems[curType]= newLegend;
                                    self.legendItems.push(newLegend);
                                }
                                self.$options.nodes.push(curNode);
                            }
                            for(var i=0; i<graph.links.length; i++) {
                                var curLink = graph.links[i];                            
                                self.$options.links.push(curLink);
                            }
                            self.$watch("legendItems", function(newValue, oldValue) {self.onRefreshed()}, { deep: true });
                            resolve(graph);                       
                        } , function(err) {
                            reject(err);
                        });    
                    } else {
                        reject(new Error("Not Authenticated"));
                    }
                 });               
            },
            loadFlowData(id, color) {
                var self = this;
                self.colorFlow = d3.scaleOrdinal(d3.schemeCategory10 );
                return new Promise((resolve, reject) => {
                    self.$store.commit("updateUserFromStorage", null);
                    const currentUser =  self.$store.getters.currentUser;
                    if (currentUser && currentUser.token) {
                        d3.json(self.$store.getters.currentProjectApiRoot	 + 'NEOFLOW/' +id, {headers: {
                                "Content-Type":'application/json',
                                'Accept':'application/json',
                                "Authorization": `Bearer ${currentUser.token}`
                            }}).then(function(graph) {
                            self.isLoadedFlow = true;
                            self.legendFlowItems = [];
                            var legendTempItems = [];
                            self.$options.nodesFlow =[];
                            self.$options.linksFlow =[];
                            var typeSettings = typeFilterCache.getTypesSetting(self.flowSettingsName);
                            for(var i=0; i<graph.nodes.length; i++) {
                                var curNode = graph.nodes[i];
                                if (!legendTempItems[curNode.documentation]) {
                                    var curType = curNode.documentation;
                                    var newLegend = {
                                        'title': curType,
                                        'color': self.getColor(curNode.documentation, self.colorFlow),
                                        'selected': true
                                    };
                                    if (typeSettings[curType] && typeSettings[curType].disabled) {
                                        newLegend.selected = false;
                                    }
                                    if (self.objectTypes && self.objectTypes[curType] && self.objectTypes[curType].hidden) {
                                          newLegend.selected = false;
                                    } 

                                    legendTempItems[curType]= newLegend;
                                    self.legendFlowItems.push(newLegend);
                                }
                                self.$options.nodesFlow.push(curNode);
                            }
                            for(var i=0; i<graph.links.length; i++) {
                                var curLink = graph.links[i];                            
                                self.$options.linksFlow.push(curLink);
                            }
                            self.$watch("legendFlowItems", function(newValue, oldValue) {self.onFlowRefreshed()}, { deep: true });
                            resolve(graph);                       
                        } , function(err) {
                            reject(err);
                        });                    
                    } else {
                        reject(new Error("Not Authenticated"));
                    }
                });
            },
            onFlowSelectionChanged() {
                this.onFlowRefreshed();
            },
            onFlowRefreshed() {
                var self = this;
                typeFilterCache.updateTypesSettingFromLegend(self.flowSettingsName, self.legendFlowItems);                
                var filteredNodes = [];                
                var filteredLinks = [];
                var tempLinks = [];
                var selectedTypeDict = [];
                var filteredNodesDict = [];
                //collect selected types
                for (var i=0; i< self.legendFlowItems.length; i++) {
                    var curLegend = self.legendFlowItems[i];
                    if (curLegend.selected) {
                        selectedTypeDict[curLegend.title] = curLegend;
                    }
                }
                // filter nodes by selected types
                for (var i=0; i< self.$options.nodesFlow.length; i++) {
                    var curNode = self.$options.nodesFlow[i];
                    if (selectedTypeDict[curNode.documentation]) {
                        filteredNodes.push(curNode);
                        filteredNodesDict[curNode.id] = curNode;
                    }
                    
                }
                // create dict of all child links
                var childDict = [];
                for (var i=0; i< self.$options.linksFlow.length; i++) {
                    var curLink = self.$options.linksFlow[i];
                    var descItems = []
                    if (childDict[curLink.source]) {
                        descItems = childDict[curLink.source];
                    } else {
                        childDict[curLink.source] = descItems;
                    }

                    descItems.push(curLink);
                }
                // create skipped nodes links
                var newChildDict = [];
                var tempLinks = [];
                for (var i=0; i< filteredNodes.length; i++) {
                    var curNode = filteredNodes[i];
                    if (childDict[curNode.id]) {
                        var childLinks = childDict[curNode.id];
                        for (var j=0; j<childLinks.length; j++) {
                            var childLink = childLinks[j];
                            if (filteredNodesDict[childLink.target]) {
                                // target is also selected - just push to results
                                addLinkToChild(newChildDict, tempLinks, childLink);
                            } else {
                                // must traverse children until filtered
                                processChildren(childDict, filteredNodesDict, newChildDict, tempLinks, curNode.id, childLink.type, curNode.id);
                            }
                        }
                    }

                }
                function processChildren(fullChildDict, filteredNodesDict, newChildDict, linkItems, nodeId, linkType, sourceNodeId) {
                    if (fullChildDict[nodeId]) {
                        var childLinks = fullChildDict[nodeId];
                        for (var i=0; i< childLinks.length; i++) {
                            var curLink = childLinks[i]; 
                            if (curLink.type== linkType) {
                                if (filteredNodesDict[curLink.target]) {
                                    addLinkToChild(newChildDict, linkItems, constructNewLink(sourceNodeId, curLink.target, linkType) );
                                } else {
                                    processChildren(fullChildDict, filteredNodesDict, newChildDict, linkItems, curLink.target, linkType, sourceNodeId);
                                }
                            } else {
                                // ignore other types
                            }
                        }
                    } else {
                        // no children, nothing
                    }
                }
                function constructNewLink(source, target, type) {
                    return {
                        source: source,
                        target: target,
                        type: type
                    }
                }
                function addLinkToChild(dict, linkItems, newLink) {
                    var childLinks = [];
                    if (dict[newLink.source]) {
                        childLinks = dict[newLink.source];
                        for (var i=0; i< childLinks.length; i++) {
                            var curLink = childLinks[i];
                            if (curLink.target == newLink.target && curLink.type == newLink.type) {
                                // already there - do nothing
                                return;
                            } 
                        }
                    } else {
                        dict[newLink.source] = childLinks;
                    }
                    childLinks.push(newLink);
                    linkItems.push(newLink);
                }

            
                //for (var i=0; i< self.$options.linksFlow.length; i++) {
                //    var curLink = self.$options.linksFlow[i];
                for (var i=0; i< tempLinks.length; i++) {
                    var curLink = tempLinks[i];

                    var sourceId = null;
                    var targetId = null;
                    if (typeof curLink.source == "string") {
                        sourceId = curLink.source;
                    } else {
                        sourceId = curLink.source.id;
                    }
                    if (typeof curLink.target == "string") {
                        targetId = curLink.target;
                    } else {
                        targetId = curLink.target.id;
                    }
                    if (filteredNodesDict[sourceId] && filteredNodesDict[targetId]) {
                        var linkObj = {
                            "source" : sourceId,
                            "target" : targetId,
                            "type" : curLink.type
                        }
                        filteredLinks.push(linkObj);
                    }                            
                }
                var clientWidth = document.getElementById('graphsvgflow').clientWidth;
                var clientHeight = document.getElementById('graphsvgflow').clientHeight;
                var width = clientWidth, height = clientHeight;
                var scale = 1.0;

                var zoom = d3.zoom()
                    //.scale(scale)
                    .scaleExtent([0.5, 5])
                    .on("zoom", zoomed);
                var svg = d3.select("#graphsvgflow");//,
                    //width = +svg.attr("width"),
                    //height = +svg.attr("height");
                svg.selectAll("*").remove();
                svg.call(zoom);
                var container = svg.append("g")
                    .attr("id", "container")
                    .attr("transform", "translate(0,0)scale(1,1)");

                svg.append('defs').append('marker')
                    .attrs({'id':'arrowhead',
                        'viewBox':'-0 -5 10 10',
                        'refX':13,
                        'refY':0,
                        'orient':'auto',
                        'markerWidth':13,
                        'markerHeight':13,
                        'xoverflow':'visible'})
                    .append('svg:path')
                    .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
                    .attr('fill', '#999')
                    .style('stroke','none');
                self.$options.linkFlow = container.append("g")
                    .attr("class", "links")
                    .selectAll("line").data(filteredLinks)
                    .enter().append("line")
                    .attr("stroke-width", function(d) { return Math.sqrt(1); })
                    .attr('class',function(d) {
                        if (d.type == "FlowRelationship") {
                        return '';
                        }  else {
                        return 'acquireline';
                        }
                    })                            
                    .attr('marker-end',function(d) {
                        if (d.type == "FlowRelationship") {
                        return 'url(#arrowhead)';
                        }  else {
                        return null;
                        }
                    }); //d.value;
                self.$options.nodeFlow = container.append("g")
                    .attr("class", "nodes")
                    .selectAll("g")
                    .data(filteredNodes).enter().append("g");
                var circles = self.$options.nodeFlow.append("circle")
                    .attr("r",  function(d) {
                            if (d.id == self.resourceId) {
                                    return 8;                      
                            } else {
                                return 5; 
                            }
                        })
                    .attr("fill", function(d) {                                                                 
                        return self.getColor(d.documentation, self.colorFlow);
                        
                        })        
                    .on("click", function(d){
                        if (d3.event.ctrlKey) {
                            let routeData = self.$router.resolve({path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                            window.open(routeData.href, '_blank');
                        } else {
                            self.$router.push({ path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                        }
                    });                            
                var labels = self.$options.nodeFlow.append("text")
                    .text(function(d) {
                        return d.title;
                    })
                    .attr("font-weight",function(d) {
                            if (d.id == self.resourceId) {
                                    return 700;                      
                            } else {
                                return 100; 
                            }
                        })
                    .attr('x', 6)
                    .attr('y', -6)
                    .on("click", function(d){
                        // Determine if current line is visible
                        //alert(d.id);
                                //window.open(d.id, "_top");
                        if (d3.event.ctrlKey) {
                            let routeData = self.$router.resolve({path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                            window.open(routeData.href, '_blank');
                        } else {
                            self.$router.push({ path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                        }
                    });
                    ;      
                self.$options.nodeFlow.append("title")
                            .text(function(d) { return d.title + "\n("+ d.documentation + ")"; });                                  
                self.$options.simulationFlow = d3.forceSimulation(filteredNodes)
                    .force("charge", d3.forceManyBody().strength(-3000))
                    .force("center", d3.forceCenter(width / 2, height / 2))
                    .force("y", d3.forceY(function(d) {
                        var pos = height / 2;
                        if (d.order) {
                            pos = pos + (d.order * 100);                               
                        }
                        return pos;
                    }).strength(1))
                    .force("x", d3.forceX(function(d) {
                                            var x = 1000;
                                            if (self.objectTypes && self.objectTypes[d.documentation] && self.objectTypes[d.documentation].position) {
                                                x=self.objectTypes[d.documentation].position;
                                            } 
                                            //console.log(d.documentation + x);
                                            return x;
                                        }).strength(3))
                    .force("link", d3.forceLink(filteredLinks).id(function(d) {return d.id; }).distance(50).strength(1))
                    .on("tick", ticked);

                    function ticked() {
                        self.$options.linkFlow
                            .attr("x1", function(d) { return d.source.x; })
                            .attr("y1", function(d) { return d.source.y; })
                            .attr("x2", function(d) { return d.target.x; })
                            .attr("y2", function(d) { return d.target.y; });

                        self.$options.nodeFlow
                            .attr("transform", function(d) {
                                return "translate(" + d.x + "," + d.y + ")";
                            })
                    }
                    function dragstarted(d) {
                        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
                        d.fx = d.x;
                        d.fy = d.y;
                    }

                    function dragged(d) {
                        d.fx = d3.event.x;
                        d.fy = d3.event.y;
                    }

                    function dragended(d) {
                        if (!d3.event.active) simulation.alphaTarget(0);
                        d.fx = null;
                        d.fy = null;
                    }
                    function zoomed() {
                        container.style("stroke-width", 1.5 / d3.event.transform.k + "px");
                        // g.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")"); // not in d3 v4
                        container.attr("transform", d3.event.transform); // updated for d3 v4
                    }
            },
            onSelectionChanged() {
                this.onRefreshed();
            },
            onRefreshed() {
                var self = this;
                //save legend settings
                typeFilterCache.updateTypesSettingFromLegend(self.relationsSettingsName, self.legendItems);
                
                var filteredNodes = [];                
                var filteredLinks = [];
                var tempLinks = [];
                var selectedTypeDict = [];
                var filteredNodesDict = [];
                //collect selected types
                for (var i=0; i< self.legendItems.length; i++) {
                    var curLegend = self.legendItems[i];
                    if (curLegend.selected) {
                        selectedTypeDict[curLegend.title] = curLegend;
                    }
                }
                // filter nodes by selected types
                for (var i=0; i< self.$options.nodes.length; i++) {
                    var curNode = self.$options.nodes[i];
                    if (selectedTypeDict[curNode.documentation]) {
                        filteredNodes.push(curNode);
                        filteredNodesDict[curNode.id] = curNode;
                    }
                    
                }
                // create dict of all child links
                var childDict = [];
                for (var i=0; i< self.$options.links.length; i++) {
                    var curLink = self.$options.links[i];
                    var descItems = []
                    if (childDict[curLink.source]) {
                        descItems = childDict[curLink.source];
                    } else {
                        childDict[curLink.source] = descItems;
                    }

                    descItems.push(curLink);
                }
                // create skipped nodes links
                var newChildDict = [];
                var tempLinks = [];
                for (var i=0; i< filteredNodes.length; i++) {
                    var curNode = filteredNodes[i];
                    if (childDict[curNode.id]) {
                        var childLinks = childDict[curNode.id];
                        for (var j=0; j<childLinks.length; j++) {
                            var childLink = childLinks[j];
                            if (filteredNodesDict[childLink.target]) {
                                // target is also selected - just push to results
                                addLinkToChild(newChildDict, tempLinks, childLink);
                            } else {
                                // must traverse children until filtered
                                processChildren(childDict, filteredNodesDict, newChildDict, tempLinks, curNode.id, childLink.type, curNode.id);
                            }
                        }
                    }

                }
                function processChildren(fullChildDict, filteredNodesDict, newChildDict, linkItems, nodeId, linkType, sourceNodeId) {
                    if (fullChildDict[nodeId]) {
                        var childLinks = fullChildDict[nodeId];
                        for (var i=0; i< childLinks.length; i++) {
                            var curLink = childLinks[i]; 
                            //if (curLink.type== linkType) {
                                if (filteredNodesDict[curLink.target]) {
                                    addLinkToChild(newChildDict, linkItems, constructNewLink(sourceNodeId, curLink.target, linkType) );
                                } else {
                                    processChildren(fullChildDict, filteredNodesDict, newChildDict, linkItems, curLink.target, linkType, sourceNodeId);
                                }
                            //} else {
                            //    // ignore other types
                            //}
                        }
                    } else {
                        // no children, nothing
                    }
                }
                function constructNewLink(source, target, type) {
                    return {
                        source: source,
                        target: target,
                        type: type
                    }
                }
                function addLinkToChild(dict, linkItems, newLink) {
                    var childLinks = [];
                    if (dict[newLink.source]) {
                        childLinks = dict[newLink.source];
                        for (var i=0; i< childLinks.length; i++) {
                            var curLink = childLinks[i];
                            if (curLink.target == newLink.target && curLink.type == newLink.type) {
                                // already there - do nothing
                                return;
                            } 
                        }
                    } else {
                        dict[newLink.source] = childLinks;
                    }
                    childLinks.push(newLink);
                    linkItems.push(newLink);
                }

            
                //for (var i=0; i< self.$options.links.length; i++) {
                //    var curLink = self.$options.links[i];
                for (var i=0; i< tempLinks.length; i++) {
                    var curLink = tempLinks[i];

                    var sourceId = null;
                    var targetId = null;
                    if (typeof curLink.source == "string") {
                        sourceId = curLink.source;
                    } else {
                        sourceId = curLink.source.id;
                    }
                    if (typeof curLink.target == "string") {
                        targetId = curLink.target;
                    } else {
                        targetId = curLink.target.id;
                    }
                    if (filteredNodesDict[sourceId] && filteredNodesDict[targetId]) {
                        var linkObj = {
                            "source" : sourceId,
                            "target" : targetId,
                            "type" : curLink.type
                        }
                        filteredLinks.push(linkObj);
                    }                            
                }
                // prepare for export
                self.$options.nodesForExport = filteredNodes;
                self.$options.linksForExport = filteredLinks;

                var clientWidth = document.getElementById('graphsvg').clientWidth;
                var clientHeight = document.getElementById('graphsvg').clientHeight;
                var width = clientWidth, height = clientHeight;
                var scale = 1.0;

                var zoom = d3.zoom()
                    //.scale(scale)
                    .scaleExtent([0.5, 5])
                    .on("zoom", zoomed);
                var svg = d3.select("#graphsvg");//,
                    //width = +svg.attr("width"),
                    //height = +svg.attr("height");
                svg.selectAll("*").remove();
                svg.call(zoom);
                var container = svg.append("g")
                    .attr("id", "container")
                    .attr("transform", "translate(0,0)scale(1,1)");

                svg.append('defs').append('marker')
                    .attrs({'id':'arrowhead',
                        'viewBox':'-0 -5 10 10',
                        'refX':13,
                        'refY':0,
                        'orient':'auto',
                        'markerWidth':13,
                        'markerHeight':13,
                        'xoverflow':'visible'})
                    .append('svg:path')
                    .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
                    .attr('fill', '#999')
                    .style('stroke','none');
                self.$options.link = container.append("g")
                    .attr("class", "links")
                    .selectAll("line").data(filteredLinks)
                    .enter().append("line")
                    .attr("stroke-width", function(d) { return Math.sqrt(1); })
                    /*
                    .attr('class',function(d) {
                        if (d.type == "FlowRelationship") {
                        return '';
                        }  else {
                        return 'acquireline';
                        }
                    })                            
                    .attr('marker-end',function(d) {
                        if (d.type == "FlowRelationship") {
                        return 'url(#arrowhead)';
                        }  else {
                        return null;
                        }
                        
                    }); *///d.value;
                self.$options.node = container.append("g")
                    .attr("class", "nodes")
                    .selectAll("g")
                    .data(filteredNodes).enter().append("g");
                var circles = self.$options.node.append("circle")
                    .attr("r",  function(d) {
                            if (d.id == self.resourceId) {
                                    return 8;                      
                            } else {
                                return 5; 
                            }
                        })
                    .attr("fill", function(d) {                                                                 
                        return self.getColor(d.documentation, self.color);
                        
                        })        
                    .on("click", function(d){
                                //window.open(d.id, "_top");
                        if (d3.event.ctrlKey) {
                            let routeData = self.$router.resolve({path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                            window.open(routeData.href, '_blank');
                        } else {
                            self.$router.push({ path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                        }
                    });                            
                var labels = self.$options.node.append("text")
                    .text(function(d) {
                        return d.title;
                    })
                    .attr("font-weight",function(d) {
                            if (d.id == self.resourceId) {
                                    return 700;                      
                            } else {
                                return 100; 
                            }
                        })
                    .attr('x', 6)
                    .attr('y', -6)
                    .on("click", function(d){
                        // Determine if current line is visible
                        //alert(d.id);
                                //window.open(d.id, "_top");
                        if (d3.event.ctrlKey) {
                            let routeData = self.$router.resolve({path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                            window.open(routeData.href, '_blank');
                        } else {
                            self.$router.push({ path: `${self.projectUrl}/DISCOBJECT/${d.id}`});
                        }
                    });
                    ;      
                self.$options.node.append("title")
                            .text(function(d) { return d.title + "\n("+ d.documentation + ")"; });                                  
                self.$options.simulation = d3.forceSimulation(filteredNodes)
                    .force("charge", d3.forceManyBody().strength(-3000))
                    .force("center", d3.forceCenter(width / 2, height / 2))
                    .force("y", d3.forceY(function(d) {
                        var pos = height / 2;
                        if (d.order) {
                            pos = pos + (d.order * 100);                               
                        }
                        return pos;
                    }).strength(1))
                    .force("x", d3.forceX(function(d) {
                                            var x = 1000;
                                            if (self.objectTypes && self.objectTypes[d.documentation] && self.objectTypes[d.documentation].position) {
                                                x=self.objectTypes[d.documentation].position;
                                            } 
                                            //console.log(d.documentation + x);
                                            return x;
                                        }).strength(3))
                    .force("link", d3.forceLink(filteredLinks).id(function(d) {return d.id; }).distance(50).strength(1))
                    .on("tick", ticked);

                    function ticked() {
                        self.$options.link
                            .attr("x1", function(d) { return d.source.x; })
                            .attr("y1", function(d) { return d.source.y; })
                            .attr("x2", function(d) { return d.target.x; })
                            .attr("y2", function(d) { return d.target.y; });

                        self.$options.node
                            .attr("transform", function(d) {
                                return "translate(" + d.x + "," + d.y + ")";
                            })
                    }
                    function dragstarted(d) {
                        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
                        d.fx = d.x;
                        d.fy = d.y;
                    }

                    function dragged(d) {
                        d.fx = d3.event.x;
                        d.fy = d3.event.y;
                    }

                    function dragended(d) {
                        if (!d3.event.active) simulation.alphaTarget(0);
                        d.fx = null;
                        d.fy = null;
                    }
                    function zoomed() {
                        container.style("stroke-width", 1.5 / d3.event.transform.k + "px");
                        // g.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")"); // not in d3 v4
                        container.attr("transform", d3.event.transform); // updated for d3 v4
                    }
            },
            showInNewWindow () {
                var self = this;
                let routeData = this.$router.resolve({path: `${self.projectUrl}/DISCOBJECT/${this.resourceId}/chart`});
                window.open(routeData.href, '_blank');
            },
            
            exportTable() {
                /* convert from array of arrays to workbook */
                var self = this;
                
                
                if (self.legendItems && self.$options.nodesForExport) {
                    var columns = [];

                    for(var i=0; i< self.legendItems.length; i++) {
                        var curLegendItem = self.legendItems[i];
                        if (curLegendItem.selected) {
                            columns.push(curLegendItem.title);
                        }
                    }
                    // single cell worksheet bug mitigation
                    var worksheet = XLSX.utils.aoa_to_sheet(["", "", ""]);
                    worksheet["!ref"] = "A1:A3";
                    var new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
                    var column = 0;                        
                    for(var i=0; i<columns.length;i++) {
                        var curType = columns[i];
                        var row = 0;
                        var columnNodes = [];

                        for(var j=0; j<self.$options.nodesForExport.length; j++) {
                            var curNode = self.$options.nodesForExport[j];
                            if (curNode.documentation == curType) {
                                columnNodes.push(curNode.title);
                            }
                        }
                        
                        if (columnNodes.length>0) {
                            columnNodes.sort();
                            var headerref = XLSX.utils.encode_cell({c:column, r:row});      
                            if(!worksheet[headerref]) {
                                worksheet[headerref] = {};
                                self.add_to_sheet(worksheet,headerref);
                            }
                            worksheet[headerref].t = "s";
                            worksheet[headerref].v = curType;                   
                            row++;
                            for(var j=0; j<columnNodes.length; j++) {
                                var curName = columnNodes[j];
                                var cellref = XLSX.utils.encode_cell({c:column, r:row});      
                                if(!worksheet[cellref]) {
                                    worksheet[cellref] = {};
                                    self.add_to_sheet(worksheet,cellref);
                                }
                                worksheet[cellref].t = "s";
                                worksheet[cellref].v = curName;                   
                                row++;
                            }
                            column++;
                        }
                    }
                    
                    /* output format determined by filename */
                    XLSX.writeFile(new_workbook, 'out.xlsx');
                    /* at this point, out.xlsx will have been downloaded */
                }
                
            },
            exportChildHierarchy() {
                /* convert from array of arrays to workbook */
                var self = this;
                
                
                if (self.legendItems && self.$options.nodesForExport) {
                    var columns = [];
                    var typeRelations = [];
                    var nodeDict = [];
                    var linksDict = [];
                    var colPos = 1;
                    for(var i=0; i< self.legendItems.length; i++) {
                        var curLegendItem = self.legendItems[i];
                        if (curLegendItem.selected) {
                            columns[curLegendItem.title] = colPos;
                            colPos+=1;
                            typeRelations[curLegendItem.title] = [];
                        }
                    }

                    for(var i=0; i<  self.$options.nodesForExport.length; i++) {                        
                        var curNode = self.$options.nodesForExport[i];
                        nodeDict[curNode.id] = curNode;
                    }
                    for(var i=0; i<  self.$options.linksForExport.length; i++) {                        
                        // create object type relations for creating hierarchy
                        var curLink = self.$options.linksForExport[i];
                        var sourceNode = curLink.source;
                        var targetNode = curLink.target;
                        if (sourceNode && targetNode) {
                            // add child type to dict
                            var sourceTypeRelations = typeRelations[sourceNode.documentation];
                            if (sourceTypeRelations) {
                                sourceTypeRelations[targetNode.documentation] = targetNode.documentation;
                            }
                            if (!linksDict[sourceNode.id]) {
                                linksDict[sourceNode.id] = [];
                            }
                            linksDict[sourceNode.id][targetNode.id] = targetNode;
                        }
                    }
                    // sort columns
                    var iterationsLimit = 10000;
                    var iteration = 0
                    var finishSort = false;             

                    while(!finishSort && iteration < iterationsLimit) {
                        finishSort =true;
                        for (var typeName in typeRelations) {
                            var curTypeRelations = typeRelations[typeName];
                            for (var targetTypeName in curTypeRelations) {
                                if(columns[typeName] > columns[targetTypeName]) {
                                    var tmpPos = columns[typeName];
                                    columns[typeName] = columns[targetTypeName];
                                    columns[targetTypeName] = tmpPos;
                                    finishSort = false;
                                }
                            }

                        }
                        iteration+=1;
                    }
                    // single cell worksheet bug mitigation
                    var worksheet = XLSX.utils.aoa_to_sheet(["", "", ""]);
                    worksheet["!ref"] = "A1:A3";
                    var new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
                    var row = 1;                    
                    var firstColumnType ;
                    for (var typeName in columns) {
                        var column = columns[typeName];
                        var headerref = XLSX.utils.encode_cell({c:column, r:row});      
                        if(!worksheet[headerref]) {
                            worksheet[headerref] = {};
                            self.add_to_sheet(worksheet,headerref);
                        }
                        worksheet[headerref].t = "s";
                        worksheet[headerref].v = typeName;  
                        if (column==1) {
                            firstColumnType  = typeName;
                        }
                    }
                    row +=1;
                    if (firstColumnType){
                        var firstColumnNodes = [];
                        for(var i=0; i<  self.$options.nodesForExport.length; i++) {                        
                            var curNode = self.$options.nodesForExport[i];
                            curNode.column = column[curNode.documentation];
                            if (curNode.documentation == firstColumnType) {
                                firstColumnNodes.push(curNode);
                            }
                            
                        }
                        firstColumnNodes.sort(self.compareNodes);
                        for(var i=0; i<  firstColumnNodes.length; i++) {                        
                            var curNode = firstColumnNodes[i];
                            processSubTree(curNode);
                        }
                    }
                    function processSubTree(node) {
                        var column = columns[node.documentation];
                        var cellref = XLSX.utils.encode_cell({c:column, r:row});      
                        if(!worksheet[cellref]) {
                            worksheet[cellref] = {};
                            self.add_to_sheet(worksheet,cellref);
                        }
                        worksheet[cellref].t = "s";
                        worksheet[cellref].v = node.title;                   
                        var childrenDict = linksDict[node.id];
                        if (childrenDict) {
                            var children = [];
                            for (var childId in childrenDict) {
                                children.push(childrenDict[childId]);
                            }
                            children.sort(self.compareNodes);
                            for (var i=0; i<children.length; i++) {
                                var curChild = children[i];
                                processSubTree(curChild);
                            }
                            if (children.length==0) {
                                row+=1;
                            }
                        } else {
                            row+=1;
                        }
                    }
                    /* output format determined by filename */
                    XLSX.writeFile(new_workbook, 'out.xlsx');
                    /* at this point, out.xlsx will have been downloaded */
                }
                
            },
            compareNodes(a,b) 
            {
                // sort by column order and name 
                if (a && b) {
                    if (a.column < b.column) {
                        return -1;
                    } else if (a.column > b.column) {
                        return 1;
                    } else {
                        if (a.title < b.title) {
                            return -1;
                        } else if (a.title > b.title){
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                } else {
                    return 0;
                }
            },
            range_add_cell(range, cell) {
                var c = typeof cell == 'string' ? XLSX.utils.decode_cell(cell) : cell;
                var rng;
                if (range) {
                    rng = XLSX.utils.decode_range(range);
                } else {
                    var tmpCell = XLSX.utils.encode_cell(c);
                    rng = XLSX.utils.decode_range(tmpCell + ":" + tmpCell);
                }
                console.log(rng, c);
                if(rng.s.r > c.r) rng.s.r = c.r;
                if(rng.s.c > c.c) rng.s.c = c.c;

                if(rng.e.r < c.r) rng.e.r = c.r;
                if(rng.e.c < c.c) rng.e.c = c.c;
                return XLSX.utils.encode_range(rng);
            },
             add_to_sheet(sheet, cell) {
                sheet['!ref'] = this.range_add_cell(sheet['!ref'], cell);
            
            },
         },

        mounted() {
            //
            this.projectUrl = "/" + this.$route.params.project;
            this.objectTypes =  this.$store.getters.objectTypes;
            if (this.expanded) {
                this.showChart();
            }
            
           
            
        }
    }
</script>
