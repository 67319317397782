<template>
    <v-card>
      <v-container
        fluid
        grid-list-lg
      >
       <v-row>
          <v-col
            cols="12"
            md="6"
          >
          <!--
            <v-card height="100%">
              <v-card-title>
                {{$t('DISCOBJECT.typeChart')}}
                <v-progress-linear
                  :active="typeLoading"
                  :indeterminate="typeLoading"
                ></v-progress-linear>
              </v-card-title>
            
              <donut-chart-component :data="typeData" svgclass="dashboard-msu-chart-container" />
            </v-card>
            -->
            <dashboard-item-component :parameters="parameters" :config="typeDonutConfig"  />
          </v-col>
          <v-col cols="12" md="6">
            <!--
            <v-card height="100%">
              <v-card-title>
                {{$t('DISCOBJECT.typeTable')}}
                <v-progress-linear
                  :active="typeLoading"
                  :indeterminate="typeLoading"
                ></v-progress-linear>
              </v-card-title>
               
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{$t('DISCOBJECT.NAME')}}</th>
                      <th class="text-left">{{$t('count')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in typeData" :key="item.name">
                      <td>{{ item.name }}</td>
                      <td>{{ item.value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
            -->
            <dashboard-item-component :parameters="parameters" :config="typeTableConfig" />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
          <!--
            <v-card>
              <v-card-title>
                {{$t('msu')}}
                <v-progress-linear
                  :active="msuLoading"
                  :indeterminate="msuLoading"
                ></v-progress-linear>
              </v-card-title>
              <column-chart-component :data="msuData" svgclass="dashboard-msu-chart-container" />
            </v-card>
            -->
            <dashboard-item-component :parameters="parameters" :config="msuConfig" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
</template>

<script>
    import { Promise } from 'q';
    import XLSX from 'xlsx';
    import axios from 'axios';
    import Vue from 'vue';
import DashboardItemComponent from './chart/DashboardItem.component.vue';
    export default {
  components: { DashboardItemComponent },
        props: [
            'card',        
        ],
        data() {
            return {
                chartUrl:null,
                msuData: null,
                msuLoading: false,
                typeData: null,
                typeLoading: false,
                project: null,
                msuConfig: {
                  appId: "elastic",
                  title: 'msu',
                  svgClass: "dashboard-msu-chart-container",
                  query: {
                    "measures": [
                        "EMSU.count"
                    ],
                    "timeDimensions": [
                        {
                        "dimension": "EMSU.timestamp",
                        "granularity": "month",
                        "dateRange": "Last 12 months"
                        }
                    ],
                    "filters": [
                      {
                        "member": "EMSU.project",
                        "operator": "equals",
                        "values": [
                          "~projectId"
                        ]
                      }
                    ],
                    "order": {},
                    "dimensions": []
                  },
                  chartType: 'column',
                  labelY: 'msu'
                },
                typeTableConfig: {
                  appId: "elastic",
                  title: 'DISCOBJECT.typeTable',
                  query: {
                    "measures": [
                      "EDISCOBJECT.count"
                    ],
                    "timeDimensions": [],
                    "order": {
                      "EDISCOBJECT.count": "desc"
                    },
                    "dimensions": [
                      "EDISCOBJECT.type"
                    ],
                    "filters": [
                      {
                        "member": "EDISCOBJECT.project",
                        "operator": "equals",
                        "values": [
                          "~projectId"
                        ]
                      }
                    ],
                  },                  
                  chartType: 'table',
                  maxRows: 10,
                  sumOthers: 'EDISCOBJECT.count'
                },
                typeDonutConfig: {
                  appId: "elastic",
                  title: 'DISCOBJECT.typeChart',
                  svgClass: "dashboard-msu-chart-container",
                  query: {
                    "measures": [
                      "EDISCOBJECT.count"
                    ],
                    "timeDimensions": [],
                    "order": {
                      "EDISCOBJECT.count": "desc"
                    },
                    "dimensions": [
                      "EDISCOBJECT.type"
                    ],
                    "filters": [
                      {
                        "member": "EDISCOBJECT.project",
                        "operator": "equals",
                        "values": [
                          "~projectId"
                        ]
                      }
                    ],
                  },                  
                  chartType: 'donut',
                  maxRows: 10,
                  sumOthers: 'EDISCOBJECT.count',
                  percentages : true
                },
                parameters: {
                  projectId: this.$store.getters.currentProject.UUID
                }
            }
        },
        mounted() {
          var self = this;
          this.project = this.$route.params.project;
          /*
          self.loadMSUData().then(function(graph) {
              self.msuData=graph;
          });
          */
          /*
          self.loadTypeData().then(function(graph) {
              if (graph) {
                // compute percent
                var totalCount = 0;
                graph.forEach(element => {
                  if (element.value) {
                    totalCount = totalCount + element.value;
                  }
                });
                if (totalCount) {
                  graph.forEach(element => {
                      var percent = element.value / totalCount * 100;
                      element.label = `${element.name} (${Math.round(percent * 100) / 100}%)`;
                  });
                
                }
                
              }
              self.typeData=graph;
          });
          */
        },
        methods: {  
          /*  
          loadMSUData() {
            var self = this;
            self.msuLoading = true;
            return new Promise((resolve, reject) => {
                var url=self.$store.getters.currentProjectApiRoot	 + 'msu';              
                axios.get(url, {headers: {
                    "Content-Type":'application/json'                        
                }})
                .then(function(graph) {
                    self.msuLoading = false;
                    resolve(graph.data);                       
                } , function(err) {
                    self.msuLoading = false;
                    reject(err);
                });    
              
              });               
          },
          */
         /*
          loadTypeData() {
            var self = this;
            self.typeLoading = true;
            return new Promise((resolve, reject) => {
                var url=self.$store.getters.currentProjectApiRoot	 + 'DISCOBJECTType/counts' ;              
                axios.get(url, {headers: {
                    "Content-Type":'application/json'                        
                }})
                .then(function(graph) {
                    self.typeLoading = false;
                    resolve(graph.data);                       
                } , function(err) {
                    self.typeLoading = false;
                    reject(err);
                });    
              
              });               
          }
          */
        }
    }
</script>
